const PredictionIcon = () => (
    <>
        <svg
            style={{
                width: "22px",
                height: "22px",
            }}
            viewBox="0 0 24 24"
            width="24px"
            color="text"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.38 5.88L7.5 6.84L9.62 5.88L8.66 8L9.62 10.12L7.5 9.16L5.38 10.12L6.34 8L5.38 5.88ZM12.5 0L13.59 2.91L16.5 4L13.59 5.09L12.5 8L11.41 5.09L8.5 4L11.41 2.91L12.5 0ZM2 16.5H3V15.5C3 15.2348 3.10536 14.9804 3.29289 14.7929C3.48043 14.6054 3.73478 14.5 4 14.5H4.26C2 13.2 0.5 10.78 0.5 8C0.5 6.01088 1.29018 4.10322 2.6967 2.6967C4.10322 1.29018 6.01088 0.5 8 0.5C9.05 0.5 10.05 0.72 10.96 1.11L10.59 2.09L9.17 2.62C8.79 2.54 8.4 2.5 8 2.5C6.54131 2.5 5.14236 3.07946 4.11091 4.11091C3.07946 5.14236 2.5 6.54131 2.5 8C2.5 9.45869 3.07946 10.8576 4.11091 11.8891C5.14236 12.9205 6.54131 13.5 8 13.5C10.91 13.5 13.3 11.23 13.5 8.37L14.41 5.91L15.12 5.64C15.37 6.38 15.5 7.18 15.5 8C15.5 10.78 14 13.2 11.74 14.5H12C12.2652 14.5 12.5196 14.6054 12.7071 14.7929C12.8946 14.9804 13 15.2348 13 15.5V16.5H14C14.5304 16.5 15.0391 16.7107 15.4142 17.0858C15.7893 17.4609 16 17.9696 16 18.5V19.5H0V18.5C0 17.9696 0.210714 17.4609 0.585786 17.0858C0.960859 16.7107 1.46957 16.5 2 16.5V16.5Z"
                fill="white"
            />
        </svg>
    </>
);

export default PredictionIcon;
