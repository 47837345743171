import { ethers } from "ethers";
import { getContract } from "../Addresses";

const TOKENS = {
  321: [
    {
      name: "Kucoin Shares",
      symbol: "KCS",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isNative: true,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/1047/small/sa9z79.png?1610678720",
    },
    {
      name: "Wrapped KCS",
      symbol: "WKCS",
      decimals: 18,
      address: "0x4446Fc4eb47f2f6586f9fAAb68B3498F86C07521",
      isWrapped: true,
      baseSymbol: "KCS",
      imageUrl: "https://assets.coingecko.com/coins/images/1047/small/sa9z79.png?1610678720",
    },
    {
      name: "Tether",
      symbol: "USDT",
      decimals: 18,
      address: "0x0039f574ee5cc39bdd162e9a88e3eb1f111baf48",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/325/thumb/Tether-logo.png?1598003707",
    },
    {
      name: "testKCS",
      symbol: "xKCS",
      decimals: 18,
      address: "0x9eE206eC9F0CA7DC649423a54d29281BE1A3d19a",
      isStable: false,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/12504/thumb/uniswap-uni.png?1600306604",
    },
    {
      name: "testUSDT",
      symbol: "xUSDT",
      decimals: 18,
      address: "0xF06dAF056607C879b0b5CBaD03Da44d219c9BeeB",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/325/thumb/Tether-logo.png?1598003707",
    },
  ],
  322: [
    {
      name: "Kucoin Shares",
      symbol: "KCS",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isNative: true,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/1047/small/sa9z79.png?1610678720",
    },
    {
      name: "Wrapped KCS",
      symbol: "WKCS",
      decimals: 18,
      address: "0x6551358EDC7fee9ADAB1E2E49560E68a12E82d9e",
      isWrapped: true,
      baseSymbol: "KCS",
      imageUrl: "https://assets.coingecko.com/coins/images/1047/small/sa9z79.png?1610678720",
    },
    {
      name: "Tether",
      symbol: "USDT",
      decimals: 18,
      address: "0x67f6a7BbE0da067A747C6b2bEdF8aBBF7D6f60dc",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/325/thumb/Tether-logo.png?1598003707",
    },
  ],
};

const ADDITIONAL_TOKENS = {
  321: [
    {
      name: "KMX",
      symbol: "KMX",
      address: getContract(321, "KMX"),
      decimals: 18,
    },
    {
      name: "Escrowed KMX",
      symbol: "esKMX",
      address: getContract(321, "ES_KMX"),
      decimals: 18,
    },
    {
      name: "KMX LP",
      symbol: "KLP",
      address: getContract(321, "KLP"),
      decimals: 18,
    },
  ],
  322: [
    {
      name: "KMX",
      symbol: "KMX",
      address: getContract(322, "KMX"),
      decimals: 18,
    },
    {
      name: "Escrowed KMX",
      symbol: "esKMX",
      address: getContract(322, "ES_KMX"),
      decimals: 18,
    },
    {
      name: "KMX LP",
      symbol: "KLP",
      address: getContract(322, "KLP"),
      decimals: 18,
    },
  ],
};

const CHAIN_IDS = [321, 322];

const TOKENS_MAP = {};
const TOKENS_BY_SYMBOL_MAP = {};

for (let j = 0; j < CHAIN_IDS.length; j++) {
  const chainId = CHAIN_IDS[j];
  TOKENS_MAP[chainId] = {};
  TOKENS_BY_SYMBOL_MAP[chainId] = {};
  let tokens = TOKENS[chainId];
  if (ADDITIONAL_TOKENS[chainId]) {
    tokens = tokens.concat(ADDITIONAL_TOKENS[chainId]);
  }

  for (let i = 0; i < tokens.length; i++) {
    const token = tokens[i];
    TOKENS_MAP[chainId][token.address] = token;
    TOKENS_BY_SYMBOL_MAP[chainId][token.symbol] = token;
  }
}

const WRAPPED_TOKENS_MAP = {};
const NATIVE_TOKENS_MAP = {};
for (const chainId of CHAIN_IDS) {
  for (const token of TOKENS[chainId]) {
    if (token.isWrapped) {
      WRAPPED_TOKENS_MAP[chainId] = token;
    } else if (token.isNative) {
      NATIVE_TOKENS_MAP[chainId] = token;
    }
  }
}

export function getWrappedToken(chainId) {
  return WRAPPED_TOKENS_MAP[chainId];
}

export function getNativeToken(chainId) {
  return NATIVE_TOKENS_MAP[chainId];
}

export function getTokens(chainId) {
  return TOKENS[chainId];
}

export function isValidToken(chainId, address) {
  if (!TOKENS_MAP[chainId]) {
    throw new Error(`Incorrect chainId ${chainId}`);
  }
  return address in TOKENS_MAP[chainId];
}

export function getToken(chainId, address) {
  if (!TOKENS_MAP[chainId]) {
    throw new Error(`Incorrect chainId ${chainId}`);
  }
  if (!TOKENS_MAP[chainId][address]) {
    throw new Error(`Incorrect address "${address}" for chainId ${chainId}`);
  }
  return TOKENS_MAP[chainId][address];
}

export function getTokenBySymbol(chainId, symbol) {
  const token = TOKENS_BY_SYMBOL_MAP[chainId][symbol];
  if (!token) {
    throw new Error(`Incorrect symbol "${symbol}" for chainId ${chainId}`);
  }
  return token;
}

export function getWhitelistedTokens(chainId) {
  return TOKENS[chainId].filter((token) => token.symbol !== "USDK");
}
