const CONTRACTS = {
  321: {
    Vault: "0x3A7E04a9f954e372B68ebECC35340d652Ad21308",
    Router: "0x23B9d82A0E2864D26391B770BF285dC97D4DCCf7",
    VaultReader: "0xA107c81257F4d2Ad9B85942Ca04A8d3b3693737D",
    Reader: "0xcC6825E9415AE0Face8Db3Ef1b73053030808E86",
    KlpManager: "0xd16430A2f000FA87E23fc98b160e8416DD01A57C",
    RewardRouter: "0xD942E98B0eDF0d343b1a57458FDF27858a1E750E",
    RewardReader: "0x87d053dab1c32b1914d568f32A1B1baA0141a981",
    NATIVE_TOKEN: "0x4446Fc4eb47f2f6586f9fAAb68B3498F86C07521",
    KLP: "0xfa0627036d2A32cfF32fe398fA02De48639Dc946",
    KMX: "0x76c90271915c875fbC63990D20308fEeEAE19238",
    ES_KMX: "0x8e425024AC3125Bb8B6d01ba861ea90f48F5b1Fc",
    BN_KMX: "0x19c1823D181FB8e7788F5da15fD515847d8E7Ff8",
    USDK: "0xF33724765be4b9D42B8dC04DD21C6aBefF4d2d1D",
    StakedKmxTracker: "0x499A654aFB4C8e7ab0970007971b912215b93897",
    BonusKmxTracker: "0x3277a470649C0cb0096A39Aaa1f51f8f1b58C266",
    FeeKmxTracker: "0xE762fff51dCB6b68159b930Cd6467960E7627610",
    StakedKlpTracker: "0x1B60F5e5FBD93ba65054aD3BCe36C5148cbDA208",
    FeeKlpTracker: "0x46CF85718744d361B5bE18273CaEF57BaBa67f52",
    StakedKmxDistributor: "0x167e92266985e4c39D8eb64274Bc0fa2E1499afb",
    StakedKlpDistributor: "0xaB1123BFeE04471EA8d7160c5f0C63FEd72a077d",
    KmxVester: "0x75A817fEd43Dd1f1077f1AC0BAE12a2C92B930a9",
    KlpVester: "0x753E81b3dB6C26ED7727bfFEbb2D55D00e352BEF",
    OrderBook: "0x705ae1a69D22b789d089815545F5c8Cf8a1b2778",
    OrderExecutor: "0x0000000000000000000000000000000000000000",
    OrderBookReader: "0x9cb57dF66E2330E62c39d4aABDa5b89933D0B0e2",
    PositionRouter: "0x3CcFc06DD0F73a1Ea1737cD961e57678EC656486",
    PositionManager: "0xFb35Bb80a32cA27C2390A55D604C8d9178b4C9B2",
    UniswapKmxEthPool: "0x0000000000000000000000000000000000000000",
    BalancerVault: "0x0000000000000000000000000000000000000000",
    UniswapKmxTcrPool: "0x0000000000000000000000000000000000000000",
    ReferralStorage: "0x0000000000000000000000000000000000000000",
    ReferralReader: "0x0000000000000000000000000000000000000000",
    FeeDistributor: "0x0000000000000000000000000000000000000000",
    FeeDistributorReader: "0x0000000000000000000000000000000000000000",
    LentKMX: "0x0000000000000000000000000000000000000000",
  },
  322: {
    Vault: "0x3A7E04a9f954e372B68ebECC35340d652Ad21308",
    Router: "0x23B9d82A0E2864D26391B770BF285dC97D4DCCf7",
    VaultReader: "0xA107c81257F4d2Ad9B85942Ca04A8d3b3693737D",
    Reader: "0xcC6825E9415AE0Face8Db3Ef1b73053030808E86",
    KlpManager: "0xd16430A2f000FA87E23fc98b160e8416DD01A57C",
    RewardRouter: "0xD942E98B0eDF0d343b1a57458FDF27858a1E750E",
    RewardReader: "0x87d053dab1c32b1914d568f32A1B1baA0141a981",
    NATIVE_TOKEN: "0x6551358EDC7fee9ADAB1E2E49560E68a12E82d9e",
    KLP: "0xfa0627036d2A32cfF32fe398fA02De48639Dc946",
    KMX: "0x76c90271915c875fbC63990D20308fEeEAE19238",
    ES_KMX: "0x8e425024AC3125Bb8B6d01ba861ea90f48F5b1Fc",
    BN_KMX: "0x19c1823D181FB8e7788F5da15fD515847d8E7Ff8",
    USDK: "0xF33724765be4b9D42B8dC04DD21C6aBefF4d2d1D",
    StakedKmxTracker: "0x499A654aFB4C8e7ab0970007971b912215b93897",
    BonusKmxTracker: "0x3277a470649C0cb0096A39Aaa1f51f8f1b58C266",
    FeeKmxTracker: "0xE762fff51dCB6b68159b930Cd6467960E7627610",
    StakedKlpTracker: "0x1B60F5e5FBD93ba65054aD3BCe36C5148cbDA208",
    FeeKlpTracker: "0x46CF85718744d361B5bE18273CaEF57BaBa67f52",
    StakedKmxDistributor: "0x167e92266985e4c39D8eb64274Bc0fa2E1499afb",
    StakedKlpDistributor: "0xaB1123BFeE04471EA8d7160c5f0C63FEd72a077d",
    KmxVester: "0x75A817fEd43Dd1f1077f1AC0BAE12a2C92B930a9",
    KlpVester: "0x753E81b3dB6C26ED7727bfFEbb2D55D00e352BEF",
    OrderBook: "0x705ae1a69D22b789d089815545F5c8Cf8a1b2778",
    OrderExecutor: "0x0000000000000000000000000000000000000000",
    OrderBookReader: "0x9cb57dF66E2330E62c39d4aABDa5b89933D0B0e2",
    PositionRouter: "0x3CcFc06DD0F73a1Ea1737cD961e57678EC656486",
    PositionManager: "0xFb35Bb80a32cA27C2390A55D604C8d9178b4C9B2",
    UniswapKmxEthPool: "0x0000000000000000000000000000000000000000",
    BalancerVault: "0x0000000000000000000000000000000000000000",
    UniswapKmxTcrPool: "0x0000000000000000000000000000000000000000",
    ReferralStorage: "0x0000000000000000000000000000000000000000",
    ReferralReader: "0x0000000000000000000000000000000000000000",
    FeeDistributor: "0x0000000000000000000000000000000000000000",
    FeeDistributorReader: "0x0000000000000000000000000000000000000000",
    LentKMX: "0x0000000000000000000000000000000000000000",
  },
  // Vault: "0x3A7E04a9f954e372B68ebECC35340d652Ad21308",
  // USDK: "0xF33724765be4b9D42B8dC04DD21C6aBefF4d2d1D",
  // Router: "0x23B9d82A0E2864D26391B770BF285dC97D4DCCf7",
  // VaultPriceFeed: "0x454A25057B95B9F36DcFfa8ec4BbD178E2B1EedB",
  // KLP: "0xfa0627036d2A32cfF32fe398fA02De48639Dc946",
  // KlpManager: "0xd16430A2f000FA87E23fc98b160e8416DD01A57C",
  // ShortsTracker: "0x01E2F6e4812D6279A9B0614c1396EEa52e682092",
  // VaultErrorController: "0x502F63Df2b5Ce36754c2772D585cDb2794a7c4e3",
  // VaultUtils: "0xAD90D51Ae4b92601169C6834945acc53d8733439",

  // OrderBook: "0x705ae1a69D22b789d089815545F5c8Cf8a1b2778",

  // PositionManager: "0xFb35Bb80a32cA27C2390A55D604C8d9178b4C9B2",
  // PositionRouter: "0x3CcFc06DD0F73a1Ea1737cD961e57678EC656486",

  // //Peripherals

  // BalanceUpdater: "0x9abf808f392323a96212a5Fd97C8A1399d144422",
  // BatchSender: "0xcf799C64E31ddAC06f94F8b9c3CeE729764B78C0",
  // OrderBookReader: "0x9cb57dF66E2330E62c39d4aABDa5b89933D0B0e2",
  // Reader: "0xcC6825E9415AE0Face8Db3Ef1b73053030808E86",
  // RewardReader: "0x87d053dab1c32b1914d568f32A1B1baA0141a981",
  // VaultReader: "0xA107c81257F4d2Ad9B85942Ca04A8d3b3693737D",

  // //staking

  // KMX: "0x76c90271915c875fbC63990D20308fEeEAE19238",
  // EsKMX: "0x8e425024AC3125Bb8B6d01ba861ea90f48F5b1Fc",
  // bnKMX: "0x19c1823D181FB8e7788F5da15fD515847d8E7Ff8",
  // stakedKmxTracker: "0x499A654aFB4C8e7ab0970007971b912215b93897",
  // stakedKmxDistributor: "0x167e92266985e4c39D8eb64274Bc0fa2E1499afb",
  // bonusKmxTracker: "0x3277a470649C0cb0096A39Aaa1f51f8f1b58C266",
  // bonusKmxDistributor: "0x06F6D0551d3eCD1D305e6d07B37bba78FdfC8e8B",
  // feeKmxTracker: "0xE762fff51dCB6b68159b930Cd6467960E7627610",
  // feeKmxDistributor: "0xeEB775BBbd0b0D2c1B8E7B003739Ae4AdbE1cD7b",
  // feeKlpTracker: "0x46CF85718744d361B5bE18273CaEF57BaBa67f52",
  // feeKlpDistributor: "0xEA321B3a54c368361a638b9b7C2bf502d188298f",
  // stakedKlpTracker: "0x1B60F5e5FBD93ba65054aD3BCe36C5148cbDA208",
  // stakedKlpDistributor: "0xaB1123BFeE04471EA8d7160c5f0C63FEd72a077d",
  // kmxVester: "0x75A817fEd43Dd1f1077f1AC0BAE12a2C92B930a9",
  // klpVester: "0x753E81b3dB6C26ED7727bfFEbb2D55D00e352BEF",
  // RewardRouterV2: "0xD942E98B0eDF0d343b1a57458FDF27858a1E750E",
  // 42161: {
  //   // arbitrum mainnet
  //   Vault: "0xDfbA8AD57d2c62F61F0a60B2C508bCdeb182f855",
  //   Router: "0x3cd2F02B9e39ccC781d0C07fc0286F654e53A76D",
  //   VaultReader: "0x261f953172ed61155a3f18fF807139Abe8234196",
  //   Reader: "0x77AE0F7128C6AC9f0efdb8A6F0Aabd5b979Ea80e",
  //   KlpManager: "0x2DE28AB4827112Cd3F89E5353Ca5A8D80dB7018f",
  //   RewardRouter: "0xd98d8e458F7aD22DD3C1d7A8B35C74005eb52b0b",
  //   RewardReader: "0x676CF948e84560808EEfb7Af9D3a46e0d6A6F988",
  //   NATIVE_TOKEN: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
  //   KLP: "0x752b746426b6D0c3188bb530660374f92FD9cf7c",
  //   TCR: "0xA72159FC390f0E3C6D415e658264c7c4051E9b87",
  //   KMX: "0xc74fe4c715510ec2f8c61d70d397b32043f55abe",
  //   ES_KMX: "0x7CEC785fba5ee648B48FBffc378d74C8671BB3cb",
  //   BN_KMX: "0xb97f91bd8f7fb6ac761Fb84cB953e4F52f049486",
  //   USDK: "0xe61a61B9Ce1Bd12e17a53AeeeE1005Ef6C1b2E80",
  //   StakedKmxTracker: "0x2BC8E28f5d41a4b112BC62EB7Db1B757c85f37Ff",
  //   BonusKmxTracker: "0xbF86a15fa2604dbA2ad7E843c589FAAAd78bF318",
  //   FeeKmxTracker: "0x0cA0147c21F9DB9D4627e6a996342A11D25972C5",
  //   StakedKlpTracker: "0xF7Bd2ed13BEf9C27a2188f541Dc5ED85C5325306",
  //   FeeKlpTracker: "0xF0BFB95087E611897096982c33B6934C8aBfA083",
  //   StakedKmxDistributor: "0x3169b5719B8a7b40580965E8557379Fe15209aAD",
  //   StakedKlpDistributor: "0xB000f7dF69d74a1F3b3cdb8ddEB3317a0823af2b",
  //   KmxVester: "0x67fF0614F6E4E04362B3b919Adc7371884895Ae8",
  //   KlpVester: "0xCB6ac1EacF498118f4609462c0979fFB3eBD1821",
  //   OrderBook: "0x74F28832cA4337bFC6035400eAB80AFD61cdf35b",
  //   // OrderBookV1: "0x2EeEf7852a306a580acF94F7c18C3FF124A59d4f",
  //   OrderExecutor: "0xC4e695a2ae9fd060E24Cdb87874b684dB7447a18",
  //   OrderBookReader: "0x1044345924277FD2e9bdF14Edad8C1d1a67a4B8e",
  //   PositionRouter: "0xE510571cAc76279DADf6c4b6eAcE5370F86e3dC2",
  //   PositionManager: "0x3892b8cF646b17709C91E653319bA5504a5FEafe",
  //   UniswapKmxTcrPool: "0xc9f423c406e690b7f7715f9e048489caafb2266b",
  //   // TODO this uniswap pool has low liquidity so cant use for pricing
  //   UniswapKmxEthPool: "0x6a034ac28064ffa8375e4668f4ecebdf3aafcba0",
  //   UniswapTcrEthPool: "0xe8bfb2918853576f0965e29bb86001ea93019003",
  //   BalancerVault: "0xBA12222222228d8Ba445958a75a0704d566BF2C8",
  //   ReferralStorage: "0x3Cd316df040f8A76eb2b496B92b358d3b2EFB9F5",
  //   ReferralReader: "0xb220724b6b68a84392eC22e841eDe5519863A21F",
  //   FeeDistributor: "0xc136c6a977af230B4F288dCE7f9432b0e91e4657",
  //   FeeDistributorReader: "0xe6d60D6A7d39d9435403e0993890D6B50B4B03BC",
  //   LentKMX: "0x9B225FF56C48671d4D04786De068Ed8b88b672d6",
  // },
};

export function getContract(chainId, name) {
  if (!CONTRACTS[chainId]) {
    throw new Error(`Unknown chainId ${chainId}`);
  }
  if (!CONTRACTS[chainId][name]) {
    throw new Error(`Unknown contract "${name}" for chainId ${chainId}`);
  }
  return CONTRACTS[chainId][name];
}
