import walletConnectImg from "./img/walletconnect-circle-blue.svg";

const WalletConnectModal = ({ activateMetaMask, activateWalletConnect }) => {
    return (
        <>
            <div className="flex flex-col justify-between md:!max-w-[700px] rounded-3xl">
                <div className="flex">
                    <div className="flex flex-col">
                        <div className="flex flex-col flex-start items-center z-10 pb-4 rounded-l-3xl px-8" style={{
                            height: 'auto',
                            width: '50vw',
                            maxHeight: 'min(38.75em, 75vh)',
                            maxWidth: '31.25em',
                        }}>
                            <div className="flex flex-col items-center p-2 w-[90%] md:p-4 md:w-[100%] md:gap-4 box-content">
                                <div className="flex items-center" style={{ flex: "1 1 0%" }}>
                                    <span style={{
                                        display: 'block',
                                        borderRadius: '50%',
                                        width: '30px',
                                        height: '30px',
                                        textAlign: 'center',
                                        lineHeight: '30px',
                                        color: 'rgb(220 222 225)',
                                        background: '#2b2f36',
                                        marginRight: 12
                                    }}>
                                        1
                                    </span>
                                    <div className="text-h4 normal-case font-primary">Select Network</div>
                                </div>
                            </div>
                            <div className="flex flex-wrap justify-center w-[80%] md:w-36" style={{
                                marginLeft: '1rem',
                            }}>
                                <div className="flex items-center justify-center relative px-2 py-3 cursor-pointer">
                                    <div>
                                        <div className="flex rounded-full p-[3px] border-transparent">
                                            <div className="flex items-center justify-center">
                                                <div className="relative" style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    padding: '8px 0',
                                                    borderRadius: '16px',
                                                }}>
                                                    <div
                                                        className="flex items-center justify-center relative box-border overflow-hidden relative transition-all z-10 "
                                                        style={{ width: 42, height: 42 }}
                                                    >
                                                        <img
                                                            alt="KCC"
                                                            className="absolute inset-0 transition-all rounded-full"
                                                            src="/network-kcc.svg"
                                                            style={{
                                                                borderRadius: '50%',
                                                                margin: 'auto',
                                                                height: '60px',
                                                                width: '60px',
                                                                verticalAlign: 'middle',
                                                                borderStyle: 'none',
                                                            }}
                                                        />
                                                        <div style={{
                                                            position: 'absolute',
                                                            padding: '2px',
                                                            borderRadius: '50%',
                                                            bottom: '-2px',
                                                            right: '-2px',
                                                            background: '#131824',
                                                            color: 'hsl(158deg 71% 44%)',
                                                        }}>
                                                            <svg id="circle_done" width={20} height={20} style={{
                                                                verticalAlign: 'middle',
                                                                borderStyle: 'none',
                                                            }} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx={10} cy={10} r={10} fill="#23C68B" />
                                                                <path d="M6 10L8.66667 12.6667L13.3333 8" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>

                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        paddingTop: '7px',
                                                    }}>
                                                        <span style={{
                                                            fontSize: '12px',
                                                            lineHeight: '16px',
                                                        }}>
                                                            KCC Testnet
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col flex-start items-center z-10 dark:drop-shadow-4xl pb-4 rounded-l-3xl px-8">
                            <div className="flex items-center" style={{ flex: "1 1 0%" }}>
                                <span style={{
                                    display: 'block',
                                    borderRadius: '50%',
                                    width: '30px',
                                    height: '30px',
                                    textAlign: 'center',
                                    lineHeight: '30px',
                                    color: 'rgb(220 222 225)',
                                    background: '#2b2f36',
                                    marginRight: 12
                                }}>
                                    2
                                </span>
                                <div className="text-h4 normal-case font-primary">Select Wallet</div>
                            </div>
                            <div className="flex pl-6 pr-4 flex-wrap w-100">
                                <div className="flex flex-col md:py-1 w-100">
                                    <div className="flex flex-wrap justify-center mt-3" style={{
                                        flexDirection: 'column',
                                    }}>
                                        <div className="flex items-center justify-between cursor-pointer relative w-fit row-reverse m-1 gap-x-2 nt-btn"
                                            onClick={() => {
                                                activateMetaMask()
                                            }
                                            }
                                        >
                                            <svg
                                                width="1em"
                                                height="1em"
                                                viewBox="0 0 24 22"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="transition-all box-content  animate-none"
                                                style={{ width: 24, height: 24 }}
                                            >
                                                <path d="M23.4447 0L13.1465 7.45289L15.0615 3.06065L23.4447 0Z" fill="#E17726" />
                                                <path
                                                    d="M0.580078 0.00927734L8.94091 3.06168L10.7593 7.5114L0.580078 0.00927734Z"
                                                    fill="#E27625"
                                                />
                                                <path
                                                    d="M19.3002 15.8501L23.8517 15.9348L22.2611 21.2202L16.707 19.7246L19.3002 15.8501Z"
                                                    fill="#E27625"
                                                />
                                                <path
                                                    d="M4.70059 15.8501L7.28397 19.7246L1.7393 21.2203L0.158203 15.9348L4.70059 15.8501Z"
                                                    fill="#E27625"
                                                />
                                                <path
                                                    d="M10.5127 6.37793L10.6988 12.254L5.13281 12.0063L6.71597 9.67011L6.73604 9.64764L10.5127 6.37793Z"
                                                    fill="#E27625"
                                                />
                                                <path
                                                    d="M13.43 6.31104L17.2644 9.64649L17.2843 9.66887L18.8676 12.005L13.3027 12.2526L13.43 6.31104Z"
                                                    fill="#E27625"
                                                />
                                                <path d="M7.44633 15.8667L10.4855 18.1828L6.95508 19.8499L7.44633 15.8667Z" fill="#E27625" />
                                                <path d="M16.5531 15.8667L17.0343 19.8503L13.5137 18.183L16.5531 15.8667Z" fill="#E27625" />
                                                <path
                                                    d="M13.5918 17.9648L17.1643 19.6568L13.8412 21.2015L13.8757 20.1805L13.5918 17.9648Z"
                                                    fill="#D5BFB2"
                                                />
                                                <path
                                                    d="M10.4074 17.9668L10.1347 20.1651L10.1571 21.2015L6.82617 19.658L10.4074 17.9668Z"
                                                    fill="#D5BFB2"
                                                />
                                                <path d="M9.37571 13.0195L10.3093 14.9385L7.13086 14.0278L9.37571 13.0195Z" fill="#233447" />
                                                <path d="M14.6248 13.0195L16.8802 14.0277L13.6914 14.9381L14.6248 13.0195Z" fill="#233447" />
                                                <path d="M7.68935 15.8481L7.1756 19.978L4.42188 15.9385L7.68935 15.8481Z" fill="#CC6228" />
                                                <path d="M16.3105 15.8481L19.5781 15.9385L16.8141 19.9782L16.3105 15.8481Z" fill="#CC6228" />
                                                <path
                                                    d="M18.9479 11.7734L16.5699 14.1438L14.7364 13.3243L13.8586 15.1293L13.2832 12.0255L18.9479 11.7734Z"
                                                    fill="#CC6228"
                                                />
                                                <path
                                                    d="M5.05078 11.7734L10.7166 12.0256L10.141 15.1293L9.26307 13.3246L7.43935 14.1439L5.05078 11.7734Z"
                                                    fill="#CC6228"
                                                />
                                                <path d="M4.89062 11.2861L7.58107 13.9564L7.67426 16.5926L4.89062 11.2861Z" fill="#E27525" />
                                                <path d="M19.1129 11.2803L16.3242 16.596L16.4292 13.9553L19.1129 11.2803Z" fill="#E27525" />
                                                <path
                                                    d="M10.5844 11.4487L10.6926 12.1153L10.9602 13.7759L10.7882 18.8765L9.97489 14.7791L9.97461 14.7368L10.5844 11.4487Z"
                                                    fill="#E27525"
                                                />
                                                <path
                                                    d="M13.4126 11.4395L14.0239 14.7368L14.0236 14.7791L13.2083 18.8867L13.176 17.8593L13.0488 13.7457L13.4126 11.4395Z"
                                                    fill="#E27525"
                                                />
                                                <path
                                                    d="M16.667 13.8491L16.5761 16.1395L13.7378 18.3022L13.1641 17.9058L13.8072 14.6657L16.667 13.8491Z"
                                                    fill="#F5841F"
                                                />
                                                <path
                                                    d="M7.3418 13.8491L10.1918 14.6657L10.8349 17.9058L10.2612 18.3022L7.4228 16.1392L7.3418 13.8491Z"
                                                    fill="#F5841F"
                                                />
                                                <path
                                                    d="M6.2832 19.1514L9.91434 20.8341L9.89896 20.1155L10.2027 19.8547H13.7966L14.1114 20.1146L14.0882 20.8327L17.6964 19.1556L15.9406 20.5746L13.8176 22.0008H10.1737L8.05208 20.5688L6.2832 19.1514Z"
                                                    fill="#C0AC9D"
                                                />
                                                <path
                                                    d="M13.3312 17.7407L13.8446 18.0954L14.1454 20.4431L13.71 20.0836H10.2904L9.86328 20.4504L10.1543 18.0956L10.6678 17.7407H13.3312Z"
                                                    fill="#161616"
                                                />
                                                <path
                                                    d="M22.7629 0.205566L23.9991 3.8327L23.2271 7.50019L23.7768 7.91492L23.0329 8.47003L23.592 8.89238L22.8517 9.55175L23.3062 9.87369L22.1 11.2515L17.1528 9.8426L17.11 9.82014L13.5449 6.87878L22.7629 0.205566Z"
                                                    fill="#763E1A"
                                                />
                                                <path
                                                    d="M1.23619 0.205566L10.4543 6.87878L6.88914 9.82014L6.8463 9.8426L1.8991 11.2515L0.692814 9.87369L1.14694 9.55202L0.407064 8.89238L0.965065 8.47058L0.210001 7.91382L0.780471 7.49881L0 3.83289L1.23619 0.205566Z"
                                                    fill="#763E1A"
                                                />
                                                <path
                                                    d="M16.9113 9.53564L22.1531 11.0284L23.8561 16.1619H19.3632L16.2676 16.2001L18.5189 11.908L16.9113 9.53564Z"
                                                    fill="#F5841F"
                                                />
                                                <path
                                                    d="M7.08771 9.53564L5.4798 11.908L7.7314 16.2001L4.63717 16.1619H0.152344L1.84575 11.0284L7.08771 9.53564Z"
                                                    fill="#F5841F"
                                                />
                                                <path
                                                    d="M15.3182 3.03574L13.852 6.9088L13.5409 12.1409L13.4218 13.7808L13.4125 17.9701H10.5894L10.5803 13.7887L10.4608 12.1395L10.1496 6.9088L8.68359 3.03564L15.3182 3.03574Z"
                                                    fill="#F5841F"
                                                />
                                            </svg>
                                            <div className="text-caption normal-case text-center font-primary">MetaMask</div>
                                        </div>
                                        <div className="flex items-center justify-between cursor-pointer relative w-fit row-reverse m-1 gap-x-2 nt-btn"
                                            onClick={() => {
                                                activateMetaMask()
                                            }
                                            }>
                                            <svg
                                                width="1em"
                                                height="1em"
                                                viewBox="0 0 65 64"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="transition-all box-content  animate-none"
                                                style={{ width: 24, height: 24 }}
                                            >
                                                <g clipPath="url(#clip0)">
                                                    <path
                                                        d="M32.3999 64C50.073 64 64.3999 49.6731 64.3999 32C64.3999 14.3269 50.073 0 32.3999 0C14.7268 0 0.399902 14.3269 0.399902 32C0.399902 49.6731 14.7268 64 32.3999 64Z"
                                                        fill="#3375BB"
                                                    />
                                                    <path
                                                        d="M32.6267 14C38.9508 19.2816 46.2029 18.9559 48.275 18.9559C47.8217 48.9937 44.3683 43.0373 32.6267 51.46C20.8851 43.0373 17.4533 48.9937 17 18.9559C19.0505 18.9559 26.3026 19.2816 32.6267 14Z"
                                                        stroke="white"
                                                        strokeWidth={4}
                                                        strokeMiterlimit={10}
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0">
                                                        <rect width={64} height={64} fill="white" transform="translate(0.399902)" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            <div className=" text-caption normal-case text-center font-primary">TrustMobile</div>
                                        </div>
                                        <div className="flex items-center justify-between cursor-pointer relative w-fit row-reverse m-1 gap-x-2 nt-btn"
                                            onClick={() => {
                                                activateWalletConnect()
                                            }
                                            }
                                        >
                                            <svg
                                                viewBox="0 0 12 12"
                                                width="1em"
                                                height="1em"
                                                style={{ width: 24, height: 24 }}
                                                fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip0_32_18238)">
                                                    <path d="M12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12C9.31371 12 12 9.31371 12 6Z" fill="url(#paint0_radial_32_18238)" />
                                                    <path d="M3.96596 4.32495C5.08939 3.22502 6.91083 3.22502 8.03426 4.32495L8.16947 4.45733C8.22564 4.51232 8.22564 4.60149 8.16947 4.65649L7.70695 5.10933C7.67887 5.13683 7.63333 5.13683 7.60525 5.10933L7.41919 4.92716C6.63545 4.15982 5.36477 4.15982 4.58104 4.92716L4.38178 5.12225C4.3537 5.14974 4.30816 5.14974 4.28008 5.12225L3.81756 4.66941C3.76139 4.61441 3.76139 4.52524 3.81756 4.47025L3.96596 4.32495ZM8.99079 5.26147L9.40242 5.6645C9.4586 5.7195 9.4586 5.80866 9.40243 5.86366L7.54632 7.68097C7.49015 7.73597 7.39907 7.73597 7.3429 7.68097C7.3429 7.68098 7.3429 7.68097 7.3429 7.68097L6.02555 6.39117C6.01151 6.37742 5.98874 6.37742 5.97469 6.39117C5.97469 6.39117 5.97469 6.39117 5.97469 6.39117L4.65737 7.68097C4.6012 7.73597 4.51013 7.73597 4.45395 7.68098C4.45395 7.68098 4.45395 7.68098 4.45395 7.68098L2.59779 5.86364C2.54162 5.80864 2.54162 5.71947 2.59779 5.66448L3.00943 5.26145C3.0656 5.20645 3.15668 5.20645 3.21285 5.26145L4.53022 6.55126C4.54426 6.56501 4.56703 6.56501 4.58107 6.55126C4.58107 6.55127 4.58107 6.55126 4.58107 6.55126L5.89838 5.26145C5.95455 5.20645 6.04562 5.20645 6.1018 5.26144C6.10179 5.26144 6.1018 5.26144 6.1018 5.26144L7.41917 6.55126C7.43321 6.56501 7.45598 6.56501 7.47002 6.55126L8.78737 5.26147C8.84354 5.20647 8.93461 5.20647 8.99079 5.26147Z" fill="white" />
                                                </g>
                                                <defs>
                                                    <radialGradient id="paint0_radial_32_18238" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(0 6) scale(12)">
                                                        <stop stopColor="#5D9DF6" />
                                                        <stop offset={1} stopColor="#006FFF" />
                                                    </radialGradient>
                                                    <clipPath id="clip0_32_18238">
                                                        <rect width={12} height={12} fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>


                                            <div className=" text-caption normal-case text-center font-primary">WalletConnect</div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default WalletConnectModal;
