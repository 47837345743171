import { Link } from "react-router-dom";
import SEO from "../../components/Common/SEO";

import { getPageTitle } from "../../Helpers";
import PageNotFoundImg from "../../img/page-not-found.svg";
import "./PageNotFound.css";

function PageNotFound() {
  return (
    <>
      <SEO title={getPageTitle("404 - Page not found")} />
      <div className="page-layout">
        <div className="page-not-found">
          <img src={PageNotFoundImg} alt="404!" />
          <br />
          <h2>Uh oh, Try double checking the link or refreshing the page.</h2>
          <p className="go-back">
            <span>Return to </span>
            <Link to="/">Home</Link>
          </p>
        </div>
      </div>
    </>
  );
}

export default PageNotFound;
