const KUSSvg = () => (
    <>
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.9421 19.9468C15.5039 19.9468 18.3914 17.1569 18.3914 13.7154C18.3914 10.2739 15.5039 7.48402 11.9421 7.48402C8.38031 7.48402 5.49289 10.2739 5.49289 13.7154C5.49289 17.1569 8.38031 19.9468 11.9421 19.9468Z" fill="url(#paint0_radial_39_18480-526777)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M13.0181 7.51111C11.7969 6.5994 9.83518 6.29296 9.5487 4.54238C9.0089 5.04866 8.69517 5.65773 8.803 6.45767C5.77399 6.78264 3.59984 8.39915 2.59883 10.8571C3.29242 10.4934 4.04313 10.2465 4.81975 10.1268C2.93597 12.2463 2.57869 15.3855 4.30009 18.5847C4.39519 17.8171 4.65561 17.0782 5.064 16.4172C5.6837 19.0131 7.88232 20.8575 10.2079 21.4002C6.94387 19.8977 5.93445 17.4828 7.15953 15.4915C7.32455 14.3219 7.84228 13.3661 8.77375 12.6549C8.02112 12.4963 7.23914 12.5364 6.50736 12.7711C7.69586 11.7895 9.22672 11.3018 10.7751 11.4113C10.3249 10.9707 9.7755 10.6404 9.17133 10.4472C12.5017 10.0731 14.9415 12.802 16.9208 11.2098C14.4946 10.4085 14.418 8.55235 13.0181 7.51111Z" fill="url(#paint1_radial_39_18480-946824)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M4.76833 8.97057C3.8981 9.40832 3.14544 10.0512 2.57422 10.8447C3.26096 10.4749 4.00435 10.2236 4.77348 10.1016C5.50321 9.27763 6.4068 8.629 7.41787 8.20344C6.25999 8.51278 5.29515 8.98484 4.49559 9.59316L4.76833 8.97057Z" fill="url(#paint2_radial_39_18480-096745)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M12.7008 8.57125C12.2655 8.4303 11.7949 8.78943 11.4352 8.71477C11.7565 8.84024 12.1182 9.34288 12.5007 9.41367C12.8689 9.48257 13.1367 9.31651 13.5004 9.41367C13.1941 9.25796 13.1367 8.71283 12.7008 8.57125Z" fill="url(#paint3_radial_39_18480-217217)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M18.8078 7.60389C18.8299 8.46378 18.7061 9.2057 18.3968 9.80362C17.4142 6.01356 13.123 4.48949 13.4522 1.21191C11.2743 2.29552 10.2669 4.41747 11.4655 6.64143C11.984 6.90973 12.5208 7.1596 12.9603 7.48791C14.3571 8.52751 14.5042 10.4098 16.9208 11.2098C15.3222 14.6257 9.49233 11.5737 7.11273 15.4556C5.89019 17.4456 6.74841 20.2018 10.1652 21.3956C13.3829 22.1478 17.2884 20.8857 19.3627 18.3596C18.3472 18.6023 17.287 18.5988 16.2733 18.3494C20.8334 16.5678 21.7526 11.0115 18.8078 7.60389Z" fill="url(#paint4_radial_39_18480-370876)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M6.87022 15.4275C5.6175 17.4068 6.4969 20.1483 9.998 21.3357C7.43082 20.166 6.96584 18.039 8.00202 16.3963C9.91302 13.3727 15.6273 15.9727 17.0965 12.9251C16.6704 12.7911 16.2663 12.6003 15.8963 12.3583C13.259 13.6785 8.93525 12.1554 6.87022 15.4275Z" fill="url(#paint5_radial_39_18480-558344)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M11.451 6.63739C12.0359 6.93047 12.0859 6.95191 12.5498 7.21804C11.5847 5.02792 12.1498 3.21762 13.4276 1.22381C11.2657 2.30676 10.2614 4.41475 11.451 6.63739Z" fill="url(#paint6_radial_39_18480-257682)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M18.3877 9.79238C17.8561 7.72804 16.5791 6.58916 15.3581 5.23432C17.341 7.76958 18.2242 10.4911 17.3297 13.5177C17.9102 13.3452 18.4381 13.032 18.8659 12.6065C18.9405 15.5342 16.8631 18.0885 13.1575 18.9175C14.339 18.9079 15.379 18.7037 16.2774 18.3489C20.8069 16.5654 21.7194 11.0029 18.7957 7.59149C18.8176 8.45104 18.6947 9.19383 18.3877 9.79238Z" fill="url(#paint7_radial_39_18480-509276)" />
            <defs>
                <radialGradient id="paint0_radial_39_18480-526777" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(4.37561 1.15979) rotate(53.4336) scale(26.6137 25.6594)">
                    <stop stopColor="#2853EC" />
                    <stop offset={1} stopColor="#41ABE6" />
                </radialGradient>
                <radialGradient id="paint1_radial_39_18480-946824" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(4.37561 1.15979) rotate(53.4336) scale(26.6137 25.6594)">
                    <stop stopColor="#2853EC" />
                    <stop offset={1} stopColor="#41ABE6" />
                </radialGradient>
                <radialGradient id="paint2_radial_39_18480-096745" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(4.37561 1.15979) rotate(53.4336) scale(26.6137 25.6594)">
                    <stop stopColor="#2853EC" />
                    <stop offset={1} stopColor="#41ABE6" />
                </radialGradient>
                <radialGradient id="paint3_radial_39_18480-217217" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(4.37561 1.15979) rotate(53.4336) scale(26.6137 25.6594)">
                    <stop stopColor="#2853EC" />
                    <stop offset={1} stopColor="#41ABE6" />
                </radialGradient>
                <radialGradient id="paint4_radial_39_18480-370876" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(4.37561 1.15979) rotate(53.4336) scale(26.6137 25.6594)">
                    <stop stopColor="#2853EC" />
                    <stop offset={1} stopColor="#41ABE6" />
                </radialGradient>
                <radialGradient id="paint5_radial_39_18480-558344" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(4.37561 1.15979) rotate(53.4336) scale(26.6137 25.6594)">
                    <stop stopColor="#2853EC" />
                    <stop offset={1} stopColor="#41ABE6" />
                </radialGradient>
                <radialGradient id="paint6_radial_39_18480-257682" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(4.37561 1.15979) rotate(53.4336) scale(26.6137 25.6594)">
                    <stop stopColor="#2853EC" />
                    <stop offset={1} stopColor="#41ABE6" />
                </radialGradient>
                <radialGradient id="paint7_radial_39_18480-509276" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(4.37561 1.15979) rotate(53.4336) scale(26.6137 25.6594)">
                    <stop stopColor="#2853EC" />
                    <stop offset={1} stopColor="#41ABE6" />
                </radialGradient>
            </defs>
        </svg>

    </>
);

export default KUSSvg;
