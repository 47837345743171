import React, { FunctionComponent, ReactElement, useState } from "react";
import { Popover, ArrowContainer } from "react-tiny-popover";
import DropdownMenu, { DropdownItem, DropdownItemGroup } from "@atlaskit/dropdown-menu";
import AppSwitcherIcon from "@atlaskit/icon/glyph/app-switcher";
import Button from "@atlaskit/button/standard-button";

interface Items {
    id: string;
    title: string;
    description: string;
    href: string;
    icon: ReactElement;
    isNew: boolean;
}

interface AllMenuNavProps {
    items: Items[];
    isPopoverOpen: boolean;
    setIsPopoverOpen: Function;
}



const AllMenuNav: React.FC<AllMenuNavProps> = ({ items, isPopoverOpen, setIsPopoverOpen }) => {
    return (
        <>
            <>
                <DropdownMenu
                    // isLoading
                    isOpen={isPopoverOpen}
                    onOpenChange={(attrs) => {
                        setIsPopoverOpen(attrs.isOpen);
                    }}
                    appearance="default"
                    trigger={({ triggerRef, ...props }) => (
                        <Button {...props} iconBefore={<AppSwitcherIcon label="more" primaryColor="#fff" />} ref={triggerRef} />
                    )}
                >
                    <div
                        onMouseLeave={() => {
                            setIsPopoverOpen(false);
                        }}
                    >
                        <DropdownItemGroup
                        >
                            {items.map((item) => (
                                <DropdownItem key={item.id}>  <a
                                    href={item.href}
                                    className="relative m-0 flex min-w-0 cursor-pointer items-center rounded-lg p-4 text-base hover:bg-primary"
                                    style={{ outline: "none", textDecoration: "none", width: "100%" }}
                                >
                                    <div className="m-0 flex h-10 w-10 min-w-0 items-center justify-center rounded">
                                        {item.icon}
                                    </div>
                                    <div className="my-0 mx-4 min-w-0 flex-1">
                                        <div className="m-0 flex min-w-0 items-center">
                                            <div className="m-0 min-w-0">
                                                {item.title}
                                            </div>
                                            {/* //!! new label */}
                                            {/* {item.isNew && (
                                                <div
                                                    
                                                    className="relative my-0 mr-0 ml-2 flex min-w-0 rounded bg-primary px-1 text-xs leading-4 text-neutral-800"
                                                >
                                                    new
                                                </div>
                                            )} */}
                                        </div>
                                        <div className="mx-0 mt-1 mb-0 min-w-0 text-xs text-gray-400">
                                            {item.description}
                                        </div>
                                    </div>
                                    {/* arrow */}
                                    {/* <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor"
                                        className="relative m-0 h-5 w-5 min-w-0 text-xl text-yellow-300 opacity-0"
                                        style={{ fill: "currentcolor", left: "-4px", transition: "all 0.1s linear 0s" }}
                                    >
                                        <use xlinkHref="#arrow-right-f" />
                                    </svg> */}
                                </a>
                                </DropdownItem>))}
                        </DropdownItemGroup>
                    </div>
                </DropdownMenu>
                {/* <Popover
                isOpen={isPopoverOpen}
                positions={["bottom"]}  
                containerClassName="pop-menu"
                content={
                    <div
                        onMouseLeave={() => {
                            // setIsPopoverOpen(false);
                        }}
                    >
                        <MenuContent items={items} />
                    </div>
                }
                onClickOutside={() => setIsPopoverOpen(false)}
            >
                {/* @ts-ignore */}
                {/* {React.cloneElement(children, {
                    onMouseEnter: () => setIsPopoverOpen(true),
                })} */}
                {/* </Popover> */}
            </>
        </>
    );
};

export default AllMenuNav;
