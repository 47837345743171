import { useState, useEffect } from 'react';
import { useLocalStorageSerializeKey } from '../Helpers';
import { atom } from 'recoil';

export const leverageOptionState = atom({
    key: 'leverageOptionState',
    default: <LeverageOptionRetriever />,
});

export const appTheme = atom({
    key: 'appTheme',
    default: true,
});

export const availableLiquidityState = atom({
    key: 'availableLiquidityState',
    default: 0,
});

function LeverageOptionRetriever() {
    const [leverageOption, setLeverageOption] = useLocalStorageSerializeKey(
        [321, "Exchange-swap-leverage-option"],
        "2"
    );
    const [state, setState] = useState(leverageOption);

    useEffect(() => {
        setState(leverageOption);
    }, [leverageOption]);

    return state;
}


