import React from "react";
import { Footer as TracerFooter } from "@tracer-protocol/tracer-ui";

import "./Footer.css";

export default function Footer() {
  return (
    <>
      <footer className="pt-6 m-0 min-w-0 leading-5 text-black bg-theme md:pt-12 relative z-10">
        <div className="px-0 my-0 mx-auto min-w-0 text-black lg:px-6 md:px-0" style={{ maxWidth: 1248 }}>
          <div className="flex flex-col px-4 pb-6 m-0 min-w-0 lg:flex-row lg:px-0 md:flex-col md:px-6 md:pb-4">
            <div className="flex flex-col flex-1 m-0 min-w-0 lg:flex-shrink md:flex-1 md:flex-row">
              <div className="block flex-1 m-0 min-w-0 md:flex md:flex-shrink">
                <div data-expand="false" className="flex-1 pr-0 m-0 min-w-0 text-gray-200 md:pr-4">
                  <div className="flex py-3 m-0 min-w-0 pointer-events-auto md:pointer-events-none md:py-4">
                    <div className="flex-1 m-0 min-w-0 text-base font-bold leading-6 md:text-xl">About</div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      className="block flex-none m-0 w-4 min-w-0 h-4 text-lg md:hidden"
                      style={{ color: "currentcolor", fill: "currentcolor" }}
                    >
                      <use xlinkHref="#nav-expand" />
                    </svg>
                  </div>
                  <div className="overflow-hidden m-0 min-w-0 max-h-0" style={{ transition: "all 0.3s ease 0s" }}>
                    <a
                      href="https://docs.kuswap.finance/"
                      className="flex mx-0 mt-0 mb-3 min-w-0 text-sm leading-4 text-gray-400 bg-transparent cursor-pointer hover:text-gray-200 md:mb-3"
                      style={{ textDecoration: "none" }}
                    >
                      Documentation
                    </a>
                    <a
                      href="https://t.me/kuswapannouncements"
                      className="flex mx-0 mt-0 mb-3 min-w-0 text-sm leading-4 text-gray-400 bg-transparent cursor-pointer hover:text-gray-200 md:mb-3"
                      style={{ textDecoration: "none" }}
                    >
                      Annoucements
                    </a>
                    <a
                      href="https://kuswap.medium.com/"
                      className="flex mx-0 mt-0 mb-3 min-w-0 text-sm leading-4 text-gray-400 bg-transparent cursor-pointer hover:text-gray-200 md:mb-3"
                      style={{ textDecoration: "none" }}
                    >
                      KuSwap Blog
                    </a>
                    <a
                      href="https://docs.kuswap.finance/protocol/contact-us"
                      className="flex mx-0 mt-0 mb-3 min-w-0 text-sm leading-4 text-gray-400 bg-transparent cursor-pointer hover:text-gray-200 md:mb-3"
                      style={{ textDecoration: "none" }}
                    >
                      Contact
                    </a>
                    <a
                      href="https://t.me/KuSwapSupport"
                      className="flex mx-0 mt-0 mb-3 min-w-0 text-sm leading-4 text-gray-400 bg-transparent cursor-pointer hover:text-gray-200 md:mb-3"
                      style={{ textDecoration: "none" }}
                    >
                      Support
                    </a>
                    <a
                      href="https://twitter.com/kuswapfinance"
                      className="flex mx-0 mt-0 mb-3 min-w-0 text-sm leading-4 text-gray-400 bg-transparent cursor-pointer hover:text-gray-200 md:mb-3"
                      style={{ textDecoration: "none" }}
                    >
                      Twitter
                    </a>
                  </div>
                </div>
                <div data-expand="false" className="flex-1 pr-0 m-0 min-w-0 text-gray-200 md:pr-4">
                  <div className="flex py-3 m-0 min-w-0 pointer-events-auto md:pointer-events-none md:py-4">
                    <div className="flex-1 m-0 min-w-0 text-base font-bold leading-6 md:text-xl">Products</div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      className="block flex-none m-0 w-4 min-w-0 h-4 text-lg md:hidden"
                      style={{ color: "currentcolor", fill: "currentcolor" }}
                    >
                      <use xlinkHref="#nav-expand" />
                    </svg>
                  </div>
                  <div className="overflow-hidden m-0 min-w-0 max-h-0" style={{ transition: "all 0.3s ease 0s" }}>
                    <a
                      href="https://kuswap.finance/#/swap"
                      className="flex mx-0 mt-0 mb-3 min-w-0 text-sm leading-4 text-gray-400 bg-transparent cursor-pointer hover:text-gray-200 md:mb-3"
                      style={{ textDecoration: "none" }}
                    >
                      Decentralized Exchange
                    </a>
                    <a
                      href="/trade"
                      className="flex mx-0 mt-0 mb-3 min-w-0 text-sm leading-4 text-gray-400 bg-transparent cursor-pointer hover:text-gray-200 md:mb-3"
                      style={{ textDecoration: "none" }}
                    >
                      Perpetual Swaps
                    </a>
                    <a
                      href="https://nft.kuswap.finance/#/nfts"
                      className="flex mx-0 mt-0 mb-3 min-w-0 text-sm leading-4 text-gray-400 bg-transparent cursor-pointer hover:text-gray-200 md:mb-3"
                      style={{ textDecoration: "none" }}
                    >
                      NFT Marketplace
                    </a>
                    <a
                      href="https://app.kuswap.finance/#/farms"
                      className="flex mx-0 mt-0 mb-3 min-w-0 text-sm leading-4 text-gray-400 bg-transparent cursor-pointer hover:text-gray-200 md:mb-3"
                      style={{ textDecoration: "none" }}
                    >
                      Yield Farming
                    </a>
                    <a
                      href="https://play.kuswap.finance/prediction"
                      className="flex mx-0 mt-0 mb-3 min-w-0 text-sm leading-4 text-gray-400 bg-transparent cursor-pointer hover:text-gray-200 md:mb-3"
                      style={{ textDecoration: "none" }}
                    >
                      Predictions
                    </a>
                    <a
                      href="https://dao.kuswap.finance/#/kus-dao"
                      className="flex mx-0 mt-0 mb-3 min-w-0 text-sm leading-4 text-gray-400 bg-transparent cursor-pointer hover:text-gray-200 md:mb-3"
                      style={{ textDecoration: "none" }}
                    >
                      Governance
                    </a>
                  </div>
                </div>
                <div data-expand="false" className="flex-1 pr-0 m-0 min-w-0 text-gray-200 md:pr-4">
                  <div className="flex py-3 m-0 min-w-0 pointer-events-auto md:pointer-events-none md:py-4">
                    <div className="flex-1 m-0 min-w-0 text-base font-bold leading-6 md:text-xl">Developers</div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      className="block flex-none m-0 w-4 min-w-0 h-4 text-lg md:hidden"
                      style={{ color: "currentcolor", fill: "currentcolor" }}
                    >
                      <use xlinkHref="#nav-expand" />
                    </svg>
                  </div>
                  <div className="overflow-hidden m-0 min-w-0 max-h-0" style={{ transition: "all 0.3s ease 0s" }}>
                    <a
                      href="https://kuswap.finance/#/apply-launchpad"
                      className="flex mx-0 mt-0 mb-3 min-w-0 text-sm leading-4 text-gray-400 bg-transparent cursor-pointer hover:text-gray-200 md:mb-3"
                      style={{ textDecoration: "none" }}
                    >
                      Apply for IDO
                    </a>
                    <a
                      href="https://github.com/kuswap"
                      className="flex mx-0 mt-0 mb-3 min-w-0 text-sm leading-4 text-gray-400 bg-transparent cursor-pointer hover:text-gray-200 md:mb-3"
                      style={{ textDecoration: "none" }}
                    >
                      Github
                    </a>
                    <a
                      href="https://snapshot.org/#/kusgov.eth/o"
                      className="flex mx-0 mt-0 mb-3 min-w-0 text-sm leading-4 text-gray-400 bg-transparent cursor-pointer hover:text-gray-200 md:mb-3"
                      style={{ textDecoration: "none" }}
                    >
                      Voting
                    </a>
                    <a
                      href="https://gov.kuswap.finance/"
                      className="flex mx-0 mt-0 mb-3 min-w-0 text-sm leading-4 text-gray-400 bg-transparent cursor-pointer hover:text-gray-200 md:mb-3"
                      style={{ textDecoration: "none" }}
                    >
                      Forum
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center py-6 my-0 mx-4 min-w-0 text-sm text-gray-400 border-t border-solid border-zinc-800 lg:mx-0 md:mx-6 md:py-8">
            <div className="m-0 min-w-0">KuSwap © 2023</div>
          </div>
        </div>
      </footer>
    </>
  );
}
