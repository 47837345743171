const Farm = () => (
    <>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            color="text"
            style={{
                width: "22px",
                height: "22px",
                fill: "white",
            }}
        >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M3.897 17.86l3.91-3.91 2.829 2.828 4.571-4.57L17 14V9h-5l1.793 1.793-3.157 3.157-2.828-2.829-4.946 4.946A9.965 9.965 0 0 1 2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10a9.987 9.987 0 0 1-8.103-4.14z" />
        </svg>
    </>
);

export default Farm;
