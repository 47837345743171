let eventsData = [
  // leave one for example
  // {
  // id: "referral-program-launch",
  // title: "Referral Program Launch",
  // isActive: true,
  // validTill: "5/04/2022 12:00:00 PM",
  // bodyText: "The KMX referral program is now live! Get fee discounts and refer traders to earn rewards.",
  // buttons: [
  // {
  // text: "Read More",
  // link: "https://gmx.io/referrals",
  // },
  // {
  // text: "Join Now",
  // link: "https://gmx.io/referrals",
  // },
  // ],
  // },
];
export default eventsData;
