import React, { FunctionComponent, ReactElement, useState } from 'react';
import { Popover, ArrowContainer } from "react-tiny-popover";
import styled from "styled-components";

const TimeContainer = styled.div`
  display: flex;
  width: 100%;
  width: 20rem;
  height: 100%;
  -webkit-tap-highlight-color: var(--tooltip-bg);
  box-sizing: border-box;
  margin: 4px 0px 0px;
  min-width: 0px;
  display: flex;
  flex-wrap: wrap;
  padding: 8px 24px;
  background-color: var(--tooltip-bg);
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 8px;
  position: absolute;
  z-index: 333;
  height: 4rem;
  left: 0rem;
  top: 32px;
  .t-c {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding-top: 4px;
    padding-bottom: 4px;
    color: rgb(234, 236, 239);
    text-align: center;
    cursor: pointer;
    width: 20%;
  }
.t-tx {
  transition: color 0.1s ease 0s;
  }  
  .t-c .t-tx {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    font-size: 14px;
  }  
.t-tx:hover {
  color:#23D1B0 !important;
  }
`;

interface CustomComponentProps extends React.ComponentPropsWithoutRef<"div"> {
    onClick(): void;
}
// const TimePopover = ({
//     isPopoverOpen = false,
//     setIsPopoverOpen,
//     content,
//     chartPeriods = ["5m", "15m", "1h", "4h", "1d"],
//     setOption,
//     option,
// }) => {
//     const CustomComponent = React.forwardRef<HTMLDivElement, CustomComponentProps>((props, ref) => (
//         <div ref={ref} onClick={props.onClick}>
//             {props.children}
//         </div>
//     ));
//     const onClick = (opt) => {
//         if (setOption) {
//             setOption(opt);
//         }
//     };

//     return (
//         <div>
//             <Popover isOpen={isPopoverOpen} content={<div id="red">hey from popover content</div>}>
//                 <CustomComponent onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
//                     hey from a custom target component
//                 </CustomComponent>
//             </Popover>
//         </div>
//     );
// };



interface IStyledPopover {
    children: ReactElement;
    isPopoverOpen: boolean,
    setIsPopoverOpen: Function,
    content: ReactElement,
    chartPeriods: string[],
    setOption: Function,
    option: string,
}

const TimePopover: FunctionComponent<IStyledPopover> = ({
    children,
    isPopoverOpen,
    setIsPopoverOpen,
    chartPeriods = ["5m", "15m", "1h", "4h", "1d"],
    setOption,
    option,
}) => {
    if (!children) {
        return <div></div>;
    }

    return (
        <Popover
            isOpen={isPopoverOpen}
            positions={['top', 'bottom', 'left', 'right']} // preferred positions by priority
            content={<>
                <div
                    onMouseLeave={() => setIsPopoverOpen(false)}
                >
                    <TimeContainer
                    >
                        {chartPeriods?.map((p, index) => (
                            <div className="t-c" key={index}>
                                <div className="t-tx" style={{
                                    color: p === option ? "#23D1B0" : "#fff"
                                }}
                                    onClick={() => {
                                        setOption(p);
                                        setIsPopoverOpen(false);
                                    }}
                                >{p}</div>
                            </div>
                        ))}
                    </TimeContainer>
                </div>
            </>}
            onClickOutside={() => setIsPopoverOpen(false)}
        >
            {/* @ts-ignore */}
            {React.cloneElement(children, {
                onMouseEnter: () => setIsPopoverOpen(true),
            })}
        </Popover>
    );
};

export default TimePopover;
