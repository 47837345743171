import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import KlpSwap from "../../components/Klp/KlpSwap";

import "./BuyKlp.css";

import { getPageTitle, useChainId } from "../../Helpers";
import { getNativeToken } from "../../data/Tokens";
import SEO from "../../components/Common/SEO";

export default function BuyKlp(props) {
  const { chainId } = useChainId();
  const history = useHistory();
  const [isBuying, setIsBuying] = useState(true);
  const nativeTokenSymbol = getNativeToken(chainId).symbol;

  useEffect(() => {
    const hash = history.location.hash.replace("#", "");
    const buying = hash === "redeem" ? false : true;
    setIsBuying(buying);
  }, [history.location.hash]);

  return (
    <>
      <SEO
        title={getPageTitle("Buy")}
        description="Buy KLP tokens to provide liquidity to KuSwap Perpetual Swaps. KLP tokens represent a share in a yield bearing diversified pool of blue-chip crypto assets."
      />
      <div className="default-container buy-tlp-content page-layout">
        <div className="section-title-block">
          {/*
            <div className="section-title-icon">
              <img src={buyKLPIcon} alt="buyKLPIcon" />
            </div>
          */}
          <div className="section-title-content">
            <div className="Page-title">Buy / Sell KLP</div>
            <div className="Page-description">
              Purchase KLP tokens to earn {nativeTokenSymbol} fees from swaps and leverages trading.
            </div>
          </div>
        </div>
        <KlpSwap {...props} isBuying={isBuying} setIsBuying={setIsBuying} />
      </div>
    </>
  );
}
