import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";

import Modal from "../../components/Modal/Modal";
import Tooltip from "../../components/Tooltip/Tooltip";

import Vault from "../../abis/Vault.json";
import ReaderV2 from "../../abis/ReaderV2.json";
import Vester from "../../abis/Vester.json";
import RewardRouter from "../../abis/RewardRouter.json";
import RewardReader from "../../abis/RewardReader.json";
import Token from "../../abis/Token.json";
import KlpManager from "../../abis/KlpManager.json";

import { ethers } from "ethers";
import {
  helperToast,
  bigNumberify,
  fetcher,
  formatAmount,
  formatKeyAmount,
  formatAmountFree,
  getChainName,
  parseValue,
  approveTokens,
  useLocalStorageSerializeKey,
  useChainId,
  KLP_DECIMALS,
  USD_DECIMALS,
  KCC,
  PLACEHOLDER_ACCOUNT,
  getBalanceAndSupplyData,
  getDepositBalanceData,
  getVestingData,
  getStakingData,
  getProcessedData,
  getPageTitle,
} from "../../Helpers";
import { callContract, useKMXPrice, useStakingApr, useTotalKMXSupply } from "../../Api";
import { getConstant } from "../../Constants";

import useSWR from "swr";

import { getContract } from "../../Addresses";

import klp40Icon from "../../img/ic_klp_40.svg";
import kmx40Icon from "../../img/ic_kmx_40.svg";
import * as StakeV2Styled from "./StakeV2Styles";

import "./StakeV2.css";

import SEO from "../../components/Common/SEO";
import ClaimModal from "./ClaimModal";
import Toggle from "../../components/Toggle/Toggle";
import KlpPriceChart from "./KlpPriceChart";

function CompoundModal(props) {
  const {
    isVisible,
    setIsVisible,
    rewardRouterAddress,
    active,
    account,
    library,
    chainId,
    setPendingTxns,
    totalVesterRewards,
    nativeTokenSymbol,
    wrappedTokenSymbol,
    processedData,
    vesterAddress,
    stakedKlpTrackerAddress,
    esKmxAddress,
  } = props;
  const [isCompounding, setIsCompounding] = useState(false);
  const [isDepositing, setIsDepositing] = useState(false);

  const [shouldClaimKmx, setShouldClaimKmx] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-claim-kmx"],
    true
  );

  const [shouldClaimEsKmx, setShouldClaimEsKmx] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-claim-esKmx"],
    true
  );

  const [shouldBuyKlpWithEth, setShouldBuyKlpWithEth] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-buy-klp"],
    true
  );

  const kmxAddress = getContract(chainId, "KMX");
  const [isApproving, setIsApproving] = useState(false);

  const { data: tokenAllowance } = useSWR(
    active && [active, chainId, kmxAddress, "allowance", account, stakedKlpTrackerAddress],
    {
      fetcher: fetcher(library, Token),
    }
  );

  const needApproval = tokenAllowance && totalVesterRewards && totalVesterRewards.gt(tokenAllowance);

  const isPrimaryEnabled = () => {
    return !isCompounding && !isApproving && !isCompounding && !isDepositing;
  };

  const getPrimaryText = () => {
    if (isApproving) {
      return `Approving KMX...`;
    }
    if (needApproval) {
      return `Approve KMX`;
    }
    if (isCompounding) {
      return "Compounding...";
    }
    if (isDepositing) {
      return "Depositing...";
    }
    return "Compound";
  };

  const onClickPrimary = () => {
    if (needApproval) {
      approveTokens({
        setIsApproving,
        library,
        tokenAddress: kmxAddress,
        spender: stakedKlpTrackerAddress,
        chainId,
      });
      return;
    }

    setIsCompounding(true);

    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());
    callContract(
      chainId,
      contract,
      "handleRewards",
      [
        shouldClaimKmx, // shouldClaimKmx,
        false, // shouldStakeKMX,
        shouldClaimEsKmx, // shouldClaimEsKmx,
        false, // shouldStakeEsKmx,
        false, // shouldStakeMultiplierPoints,
        shouldBuyKlpWithEth, // shouldClaimWeth,
        false, // shouldConvertWeth,
        shouldBuyKlpWithEth, // shouldBuyKlpWithEth
      ],
      {
        sentMsg: "Compound submitted!",
        failMsg: "Compound failed.",
        successMsg: "Compound completed!",
        setPendingTxns,
      }
    )
      .then(async (res) => {
        if (shouldClaimEsKmx) {
          await res.wait();
          depositEsKmx();
        } else {
          setIsVisible(false);
        }
      })
      .finally(() => {
        setIsCompounding(false);
      });
  };

  const depositEsKmx = async () => {
    setIsDepositing(true);
    const contract = new ethers.Contract(vesterAddress, Vester.abi, library.getSigner());
    const esKmx = new ethers.Contract(esKmxAddress, Token.abi, library.getSigner());
    const balance = await esKmx.balanceOf(account);

    callContract(chainId, contract, "deposit", [balance], {
      sentMsg: "Deposit submitted!",
      failMsg: "Deposit failed!",
      successMsg: "Deposited!",
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsDepositing(false);
      });
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label="Compound Rewards">
        <div className="CompoundModal-menu">
          <StakeV2Styled.ModalRow>
            <StakeV2Styled.ModalRowHeader>Claim Vested KMX</StakeV2Styled.ModalRowHeader>
            {shouldClaimKmx && (
              <>
                <StakeV2Styled.ModalRowText large inline>
                  {formatKeyAmount(processedData, "klpVesterRewards", 18, 4)} KMX
                </StakeV2Styled.ModalRowText>{" "}
                <StakeV2Styled.ModalRowText inline secondary>
                  (${formatKeyAmount(processedData, "klpVesterRewardsUsd", USD_DECIMALS, 4)})
                </StakeV2Styled.ModalRowText>
              </>
            )}
            <Toggle isChecked={shouldClaimKmx} handleToggle={setShouldClaimKmx} />
          </StakeV2Styled.ModalRow>
          <StakeV2Styled.ModalRow>
            <StakeV2Styled.ModalRowHeader>Claim and vest esKMX Rewards</StakeV2Styled.ModalRowHeader>
            {shouldClaimEsKmx && (
              <>
                <StakeV2Styled.ModalRowText inline large>
                  {formatKeyAmount(processedData, "stakedKlpTrackerRewards", 18, 4)} esKMX
                </StakeV2Styled.ModalRowText>{" "}
                <StakeV2Styled.ModalRowText inline secondary>
                  ($
                  {formatKeyAmount(processedData, "stakedKlpTrackerRewardsUsd", USD_DECIMALS, 2, true)})
                </StakeV2Styled.ModalRowText>
              </>
            )}
            <Toggle isChecked={shouldClaimEsKmx} handleToggle={setShouldClaimEsKmx} />
          </StakeV2Styled.ModalRow>
          <StakeV2Styled.ModalRow>
            <StakeV2Styled.ModalRowHeader>Buy KLP with {wrappedTokenSymbol} Rewards</StakeV2Styled.ModalRowHeader>
            {shouldBuyKlpWithEth && (
              <>
                <StakeV2Styled.ModalRowText large inline>
                  {formatKeyAmount(processedData, "feeKlpTrackerRewards", 18, 4)} {nativeTokenSymbol} (
                  {wrappedTokenSymbol})
                </StakeV2Styled.ModalRowText>{" "}
                <StakeV2Styled.ModalRowText inline secondary>
                  ($
                  {formatKeyAmount(processedData, "feeKlpTrackerRewardsUsd", USD_DECIMALS, 2, true)})
                </StakeV2Styled.ModalRowText>
              </>
            )}
            <Toggle isChecked={shouldBuyKlpWithEth} handleToggle={setShouldBuyKlpWithEth} />
          </StakeV2Styled.ModalRow>
        </div>
        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </div>
  );
}

function VesterDepositModal(props) {
  const {
    isVisible,
    setIsVisible,
    chainId,
    title,
    maxAmount,
    value,
    setValue,
    balance,
    vestedAmount,
    maxVestableAmount,
    vesterAddress,
    setPendingTxns,
  } = props;
  const [isDepositing, setIsDepositing] = useState(false);
  const { library, account } = useWeb3React();

  const { data: ethBalance } = useSWR([library, "getBalance", account, "latest"], {
    fetcher: (library, method, ...params) => library[method](...params),
  });

  let amount = parseValue(value, 18);

  let nextDepositAmount = vestedAmount;
  if (amount) {
    nextDepositAmount = vestedAmount.add(amount);
  }

  const getError = () => {
    if (ethBalance?.eq(0)) {
      return ["Not enough ETH for gas"];
    }

    if (!amount || amount.eq(0)) {
      return "Enter an amount";
    }
    if (maxAmount && amount.gt(maxAmount)) {
      return "Max amount exceeded";
    }
  };

  const onClickPrimary = () => {
    setIsDepositing(true);
    const contract = new ethers.Contract(vesterAddress, Vester.abi, library.getSigner());

    callContract(chainId, contract, "deposit", [amount], {
      sentMsg: "Deposit submitted!",
      failMsg: "Deposit failed!",
      successMsg: "Deposited!",
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsDepositing(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isDepositing) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isDepositing) {
      return "Depositing...";
    }
    return "Deposit";
  };

  return (
    <>
      <SEO
        title={getPageTitle("Earn")}
        description="Claim fees and liquidity mining rewards earned via providing liquidity to the Kmxelium Perpetual Swap liquidity pool."
      />
      <div className="StakeModal">
        <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title} className="non-scrollable">
          <div className="Exchange-swap-section">
            <div className="Exchange-swap-section-top">
              <div className="muted">
                <div className="Exchange-swap-usd">Deposit</div>
              </div>
              <div
                className="muted align-right clickable"
                onClick={() => setValue(formatAmountFree(maxAmount, 18, 18))}
              >
                Max: {formatAmount(maxAmount, 18, 4, true)}
              </div>
            </div>
            <div className="Exchange-swap-section-bottom">
              <div>
                <input
                  type="number"
                  placeholder="0.0"
                  className="Exchange-swap-input"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
              </div>
              <div className="PositionEditor-token-symbol">esKMX</div>
            </div>
          </div>
          <div className="VesterDepositModal-info-rows">
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">Wallet</div>
              <div className="align-right">{formatAmount(balance, 18, 2, true)} esKMX</div>
            </div>
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">Vault Capacity</div>
              <div className="align-right">
                <Tooltip
                  handle={`${formatAmount(nextDepositAmount, 18, 2, true)} / ${formatAmount(
                    maxVestableAmount,
                    18,
                    2,
                    true
                  )}`}
                  position="right-bottom"
                  renderContent={() => {
                    return (
                      <>
                        Vault Capacity for your Account
                        <br />
                        <br />
                        Deposited: {formatAmount(vestedAmount, 18, 2, true)} esKMX
                        <br />
                        Max Capacity: {formatAmount(maxVestableAmount, 18, 2, true)} esKMX
                        <br />
                      </>
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className="Exchange-swap-button-container">
            <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
              {getPrimaryText()}
            </button>
          </div>
        </Modal>
      </div>
    </>
  );
}

function VesterWithdrawModal(props) {
  const { isVisible, setIsVisible, chainId, title, library, vesterAddress, setPendingTxns } = props;
  const [isWithdrawing, setIsWithdrawing] = useState(false);

  const onClickPrimary = () => {
    setIsWithdrawing(true);
    const contract = new ethers.Contract(vesterAddress, Vester.abi, library.getSigner());

    callContract(chainId, contract, "withdraw", [], {
      sentMsg: "Withdraw submitted.",
      failMsg: "Withdraw failed.",
      successMsg: "Withdrawn!",
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsWithdrawing(false);
      });
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title}>
        <div>
          This will withdraw and unreserve all tokens as well as pause vesting.
          <br />
          <br />
          esKMX tokens that have been converted to KMX will remain as KMX tokens.
          <br />
          <br />
          To claim KMX tokens without withdrawing, use the "Claim" button under the Total Rewards section.
          <br />
          <br />
        </div>
        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={isWithdrawing}>
            {!isWithdrawing && "Confirm Withdraw"}
            {isWithdrawing && "Confirming..."}
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default function StakeV2({
  setPendingTxns,
  connectWallet,
  trackAction,
  savedSlippageAmount,
  infoTokens,
  trackPageWithTraits,
  analytics,
}) {
  const { active, library, account } = useWeb3React();
  const { chainId } = useChainId();

  const chainName = getChainName(chainId);

  const [isVesterDepositModalVisible, setIsVesterDepositModalVisible] = useState(false);
  const [vesterDepositTitle, setVesterDepositTitle] = useState("");
  const [vesterDepositStakeTokenLabel, setVesterDepositStakeTokenLabel] = useState("");
  const [vesterDepositMaxAmount, setVesterDepositMaxAmount] = useState("");
  const [vesterDepositBalance, setVesterDepositBalance] = useState("");
  const [vesterDepositEscrowedBalance, setVesterDepositEscrowedBalance] = useState("");
  const [vesterDepositVestedAmount, setVesterDepositVestedAmount] = useState("");
  const [vesterDepositAverageStakedAmount, setVesterDepositAverageStakedAmount] = useState("");
  const [vesterDepositMaxVestableAmount, setVesterDepositMaxVestableAmount] = useState("");
  const [vesterDepositValue, setVesterDepositValue] = useState("");
  const [vesterDepositReserveAmount, setVesterDepositReserveAmount] = useState("");
  const [vesterDepositMaxReserveAmount, setVesterDepositMaxReserveAmount] = useState("");
  const [vesterDepositAddress, setVesterDepositAddress] = useState("");

  const [isVesterWithdrawModalVisible, setIsVesterWithdrawModalVisible] = useState(false);
  const [vesterWithdrawTitle, setVesterWithdrawTitle] = useState(false);
  const [vesterWithdrawAddress, setVesterWithdrawAddress] = useState("");

  const [isCompoundModalVisible, setIsCompoundModalVisible] = useState(false);
  const [isClaimModalVisible, setIsClaimModalVisible] = useState(false);

  const rewardRouterAddress = getContract(chainId, "RewardRouter");
  const rewardReaderAddress = getContract(chainId, "RewardReader");
  const readerAddress = getContract(chainId, "Reader");

  const vaultAddress = getContract(chainId, "Vault");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const kmxAddress = getContract(chainId, "KMX");
  const esKmxAddress = getContract(chainId, "ES_KMX");
  const bnKmxAddress = getContract(chainId, "BN_KMX");
  const klpAddress = getContract(chainId, "KLP");

  const stakedKmxTrackerAddress = getContract(chainId, "StakedKmxTracker");
  const bonusKmxTrackerAddress = getContract(chainId, "BonusKmxTracker");
  const feeKmxTrackerAddress = getContract(chainId, "FeeKmxTracker");

  const stakedKlpTrackerAddress = getContract(chainId, "StakedKlpTracker");
  const feeKlpTrackerAddress = getContract(chainId, "FeeKlpTracker");

  const klpManagerAddress = getContract(chainId, "KlpManager");

  const kmxVesterAddress = getContract(chainId, "KmxVester");
  const klpVesterAddress = getContract(chainId, "KlpVester");

  const vesterAddresses = [kmxVesterAddress, klpVesterAddress];

  const nativeTokenSymbol = getConstant(chainId, "nativeTokenSymbol");
  const wrappedTokenSymbol = getConstant(chainId, "wrappedTokenSymbol");

  const walletTokens = [kmxAddress, esKmxAddress, klpAddress, stakedKmxTrackerAddress];
  const depositTokens = [
    kmxAddress,
    esKmxAddress,
    stakedKmxTrackerAddress,
    bonusKmxTrackerAddress,
    bnKmxAddress,
    klpAddress,
  ];
  const rewardTrackersForDepositBalances = [
    stakedKmxTrackerAddress,
    stakedKmxTrackerAddress,
    bonusKmxTrackerAddress,
    feeKmxTrackerAddress,
    feeKmxTrackerAddress,
    feeKlpTrackerAddress,
  ];
  const rewardTrackersForStakingInfo = [
    stakedKmxTrackerAddress,
    bonusKmxTrackerAddress,
    feeKmxTrackerAddress,
    stakedKlpTrackerAddress,
    feeKlpTrackerAddress,
  ];

  const { data: walletBalances } = useSWR(
    [
      `StakeV2:walletBalances:${active}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: fetcher(library, ReaderV2, [walletTokens]),
    }
  );

  const { data: depositBalances } = useSWR(
    [
      `StakeV2:depositBalances:${active}`,
      chainId,
      rewardReaderAddress,
      "getDepositBalances",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: fetcher(library, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
    }
  );

  const { data: stakingInfo } = useSWR(
    [`StakeV2:stakingInfo:${active}`, chainId, rewardReaderAddress, "getStakingInfo", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: fetcher(library, RewardReader, [rewardTrackersForStakingInfo]),
    }
  );

  const { data: stakedKmxSupply } = useSWR(
    [`StakeV2:stakedKmxSupply:${active}`, chainId, kmxAddress, "balanceOf", stakedKmxTrackerAddress],
    {
      fetcher: fetcher(library, Token),
    }
  );

  const { data: aums } = useSWR([`StakeV2:getAums:${active}`, chainId, klpManagerAddress, "getAums"], {
    fetcher: fetcher(library, KlpManager),
  });

  const { data: nativeTokenPrice } = useSWR(
    [`StakeV2:nativeTokenPrice:${active}`, chainId, vaultAddress, "getMinPrice", nativeTokenAddress],
    {
      fetcher: fetcher(library, Vault),
    }
  );

  const { data: vestingInfo } = useSWR(
    [`StakeV2:vestingInfo:${active}`, chainId, readerAddress, "getVestingInfo", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: fetcher(library, ReaderV2, [vesterAddresses]),
    }
  );

  const { data: ethBalance } = useSWR([library, "getBalance", account, "latest"], {
    fetcher: (library, method, ...params) => library[method](...params),
  });

  const { kmxPrice } = useKMXPrice(chainId, { arbitrum: chainId === KCC ? library : undefined }, active);

  const { total: kmxSupply } = useTotalKMXSupply();

  let aum;
  if (aums && aums.length > 0) {
    aum = aums[0].add(aums[1]).div(2);
  }

  const { balanceData, supplyData } = getBalanceAndSupplyData(walletBalances);
  const depositBalanceData = getDepositBalanceData(depositBalances);
  const stakingData = getStakingData(stakingInfo);
  const vestingData = getVestingData(vestingInfo);

  const processedData = getProcessedData(
    balanceData,
    supplyData,
    depositBalanceData,
    stakingData,
    vestingData,
    aum,
    nativeTokenPrice,
    stakedKmxSupply,
    kmxPrice,
    kmxSupply
  );

  const stakingApr = useStakingApr(kmxPrice, nativeTokenPrice);

  let totalRewardTokens;
  if (processedData && processedData.bnKmxInFeeKmx && processedData.bonusKmxInFeeKmx) {
    totalRewardTokens = processedData.bnKmxInFeeKmx.add(processedData.bonusKmxInFeeKmx);
  }

  let totalRewardTokensAndKlp;
  if (totalRewardTokens && processedData && processedData.klpBalance) {
    totalRewardTokensAndKlp = totalRewardTokens.add(processedData.klpBalance);
  }

  let earnMsg;
  if (totalRewardTokensAndKlp && totalRewardTokensAndKlp.gt(0)) {
    earnMsg = (
      <div>
        You are earning {nativeTokenSymbol} rewards with{" "}
        {formatKeyAmount(processedData, "klpBalance", KLP_DECIMALS, 2, true)} KLP tokens.
      </div>
    );
  }

  const showKlpCompoundModal = () => {
    if (ethBalance?.eq(0)) {
      helperToast.error("You don't have any ETH to pay for gas");
    } else {
      setIsCompoundModalVisible(true);
    }
  };

  const showKlpClaimModal = () => {
    if (ethBalance?.eq(0)) {
      helperToast.error("You don't have any ETH to pay for gas");
    } else {
      setIsClaimModalVisible(true);
    }
  };

  const showKmxVesterDepositModal = () => {
    if (ethBalance?.eq(0)) {
      helperToast.error("You don't have any ETH to pay for gas");
      return;
    } else if (!vestingData) {
      helperToast.error("Loading vesting data, please wait.");
      return;
    }
    // let remainingVestableAmount = vestingData.klpVester.maxVestableAmount.sub(vestingData.klpVester.vestedAmount);
    // if (processedData.esKmxBalance.lt(remainingVestableAmount)) {
    // }
    let remainingVestableAmount = processedData.esKmxBalance;
    let maxVestableAmount = bigNumberify(remainingVestableAmount).add(vestingData.klpVesterVestedAmount);

    setIsVesterDepositModalVisible(true);
    setVesterDepositTitle("esKMX Vault");
    setVesterDepositStakeTokenLabel("staked KMX + esKMX + Multiplier Points");
    setVesterDepositMaxAmount(remainingVestableAmount);
    setVesterDepositBalance(processedData.esKmxBalance);
    setVesterDepositEscrowedBalance(vestingData.klpVester.escrowedBalance);
    setVesterDepositVestedAmount(vestingData.klpVester.vestedAmount);
    setVesterDepositMaxVestableAmount(maxVestableAmount);
    setVesterDepositAverageStakedAmount(vestingData.klpVester.averageStakedAmount);
    setVesterDepositReserveAmount(vestingData.klpVester.pairAmount);
    setVesterDepositMaxReserveAmount(totalRewardTokens);
    setVesterDepositValue("");
    setVesterDepositAddress(klpVesterAddress);
  };

  const showKmxVesterWithdrawModal = () => {
    if (ethBalance?.eq(0)) {
      helperToast.error("You don't have any ETH to pay for gas");
      return;
    } else if (!vestingData || !vestingData.klpVesterVestedAmount || vestingData.klpVesterVestedAmount.eq(0)) {
      helperToast.error("You have not deposited any tokens for vesting.");
      return;
    }

    setIsVesterWithdrawModalVisible(true);
    setVesterWithdrawTitle("Withdraw from esKMX Vault");
    setVesterWithdrawAddress(klpVesterAddress);
  };

  return (
    <div className="StakeV2 Page page-layout default-container">
      <VesterDepositModal
        isVisible={isVesterDepositModalVisible}
        setIsVisible={setIsVesterDepositModalVisible}
        chainId={chainId}
        title={vesterDepositTitle}
        stakeTokenLabel={vesterDepositStakeTokenLabel}
        maxAmount={vesterDepositMaxAmount}
        balance={vesterDepositBalance}
        escrowedBalance={vesterDepositEscrowedBalance}
        vestedAmount={vesterDepositVestedAmount}
        averageStakedAmount={vesterDepositAverageStakedAmount}
        maxVestableAmount={vesterDepositMaxVestableAmount}
        reserveAmount={vesterDepositReserveAmount}
        maxReserveAmount={vesterDepositMaxReserveAmount}
        value={vesterDepositValue}
        setValue={setVesterDepositValue}
        vesterAddress={vesterDepositAddress}
        setPendingTxns={setPendingTxns}
      />
      <VesterWithdrawModal
        isVisible={isVesterWithdrawModalVisible}
        setIsVisible={setIsVesterWithdrawModalVisible}
        vesterAddress={vesterWithdrawAddress}
        chainId={chainId}
        title={vesterWithdrawTitle}
        library={library}
        setPendingTxns={setPendingTxns}
      />
      <CompoundModal
        active={active}
        account={account}
        setPendingTxns={setPendingTxns}
        isVisible={isCompoundModalVisible}
        setIsVisible={setIsCompoundModalVisible}
        rewardRouterAddress={rewardRouterAddress}
        totalVesterRewards={processedData.totalVesterRewards}
        wrappedTokenSymbol={wrappedTokenSymbol}
        nativeTokenSymbol={nativeTokenSymbol}
        library={library}
        chainId={chainId}
        processedData={processedData}
        vesterAddress={klpVesterAddress}
        stakedKlpTrackerAddress={stakedKlpTrackerAddress}
        esKmxAddress={esKmxAddress}
      />
      <ClaimModal
        active={active}
        setPendingTxns={setPendingTxns}
        connectWallet={connectWallet}
        library={library}
        chainId={chainId}
        isVisible={isClaimModalVisible}
        setIsVisible={setIsClaimModalVisible}
        rewardRouterAddress={rewardRouterAddress}
        wrappedTokenSymbol={wrappedTokenSymbol}
        nativeTokenSymbol={nativeTokenSymbol}
        processedData={processedData}
      />

      <StakeV2Styled.StakeV2Content className="StakeV2-content">
        <StakeV2Styled.StakeV2Cards className="StakeV2-cards">
          <StakeV2Styled.StakeV2Card>
            <div className="Page-title-section">
              <div className="Page-title">Earn (soon)</div>
              <div className="Page-description">
                Stake{" "}
                <a href="https://kuswapv3.gitbook.io/kmx/tokenomics/klp" target="_blank" rel="noopener noreferrer">
                  KLP
                </a>{" "}
                to earn rewards.
              </div>
              {earnMsg && <div className="Page-description">{earnMsg}</div>}
            </div>
            <StakeV2Styled.Card>
              <StakeV2Styled.CardTitle className="App-card-title">
                <img src={klp40Icon} alt="klp40Icon" />
                KLP ({chainName})
              </StakeV2Styled.CardTitle>
              <KlpPriceChart chainId={chainId} klpPrice={processedData.klpPrice} />
              <StakeV2Styled.KlpInfo>
                <StakeV2Styled.RewardsBanner>
                  <StakeV2Styled.RewardsBannerRow>
                    <StakeV2Styled.RewardsBannerText large>Statistics</StakeV2Styled.RewardsBannerText>
                  </StakeV2Styled.RewardsBannerRow>
                  <StakeV2Styled.RewardsBannerRow>
                    <div className="App-card-row">
                      <div className="label">Total APR</div>
                      <div>
                        <Tooltip
                          handle={`${formatKeyAmount(processedData, "klpAprTotal", 2, 2, true)}%`}
                          position="right-bottom"
                          renderContent={() => {
                            return (
                              <>
                                <div className="Tooltip-row">
                                  <span className="label">
                                    {nativeTokenSymbol} ({wrappedTokenSymbol}) APR
                                  </span>
                                  <span>{formatKeyAmount(processedData, "klpAprForNativeToken", 2, 2, true)}%</span>
                                </div>
                                <div className="Tooltip-row">
                                  <span className="label">esKMX APR</span>
                                  <span>{formatKeyAmount(processedData, "klpAprForEsKmx", 2, 2, true)}%</span>
                                </div>
                              </>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </StakeV2Styled.RewardsBannerRow>
                  <StakeV2Styled.RewardsBannerRow>
                    <div className="App-card-row">
                      <div className="label">Price</div>
                      <div>${formatKeyAmount(processedData, "klpPrice", USD_DECIMALS, 3, true)}</div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">Wallet</div>
                      <div>
                        {formatKeyAmount(processedData, "klpBalance", KLP_DECIMALS, 2, true)} KLP ($
                        {formatKeyAmount(processedData, "klpBalanceUsd", USD_DECIMALS, 2, true)})
                      </div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">Staked</div>
                      <div>
                        {formatKeyAmount(processedData, "klpBalance", KLP_DECIMALS, 2, true)} KLP ($
                        {formatKeyAmount(processedData, "klpBalanceUsd", USD_DECIMALS, 2, true)})
                      </div>
                    </div>
                  </StakeV2Styled.RewardsBannerRow>
                  <StakeV2Styled.RewardsBannerRow>
                    <div className="App-card-row">
                      <div className="label">Total Staked</div>
                      <div>
                        {formatKeyAmount(processedData, "klpSupply", 18, 2, true)} KLP ($
                        {formatKeyAmount(processedData, "klpSupplyUsd", USD_DECIMALS, 2, true)})
                      </div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">Total Supply</div>
                      <div>
                        {formatKeyAmount(processedData, "klpSupply", 18, 2, true)} KLP ($
                        {formatKeyAmount(processedData, "klpSupplyUsd", USD_DECIMALS, 2, true)})
                      </div>
                    </div>
                  </StakeV2Styled.RewardsBannerRow>
                  <StakeV2Styled.Buttons>
                    <Link className="App-button-option App-card-option" to="/buy_klp">
                      Buy KLP
                    </Link>
                    <Link className="App-button-option App-card-option" to="/buy_klp#redeem">
                      Sell KLP
                    </Link>
                  </StakeV2Styled.Buttons>
                </StakeV2Styled.RewardsBanner>
                <StakeV2Styled.RewardsBanner>
                  <StakeV2Styled.RewardsBannerRow>
                    <StakeV2Styled.RewardsBannerText large>Rewards</StakeV2Styled.RewardsBannerText>
                  </StakeV2Styled.RewardsBannerRow>
                  <StakeV2Styled.RewardsBannerRow>
                    <div className="App-card-row">
                      <div className="label">{nativeTokenSymbol} Rewards</div>
                      <div>
                        <StakeV2Styled.RewardsBannerTextWrap>
                          <StakeV2Styled.RewardsBannerText large>
                            {formatKeyAmount(processedData, "feeKlpTrackerRewards", 18, 4)} {nativeTokenSymbol}
                          </StakeV2Styled.RewardsBannerText>{" "}
                          <StakeV2Styled.RewardsBannerText secondary>
                            ($
                            {formatKeyAmount(processedData, "feeKlpTrackerRewardsUsd", USD_DECIMALS, 2, true)})
                          </StakeV2Styled.RewardsBannerText>
                        </StakeV2Styled.RewardsBannerTextWrap>
                      </div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">esKMX Rewards</div>
                      <StakeV2Styled.RewardsBannerTextWrap>
                        <StakeV2Styled.RewardsBannerText large>
                          {formatKeyAmount(processedData, "stakedKlpTrackerRewards", 18, 4)} esKMX
                        </StakeV2Styled.RewardsBannerText>{" "}
                        <StakeV2Styled.RewardsBannerText secondary>
                          ($
                          {formatKeyAmount(processedData, "stakedKlpTrackerRewardsUsd", USD_DECIMALS, 2, true)})
                        </StakeV2Styled.RewardsBannerText>
                      </StakeV2Styled.RewardsBannerTextWrap>
                    </div>
                  </StakeV2Styled.RewardsBannerRow>
                  <StakeV2Styled.Buttons>
                    {active && (
                      <button className="App-button-option App-card-option" disabled onClick={() => showKlpClaimModal()}>
                        Claim
                      </button>
                    )}
                    {active && (
                      <button className="App-button-option App-card-option" disabled onClick={() => showKlpCompoundModal()}>
                        Compound
                      </button>
                    )}
                    {!active && (
                      <button className="App-button-option App-card-option" onClick={() => connectWallet()}>
                        Connect Wallet
                      </button>
                    )}
                  </StakeV2Styled.Buttons>
                </StakeV2Styled.RewardsBanner>
              </StakeV2Styled.KlpInfo>
            </StakeV2Styled.Card>
          </StakeV2Styled.StakeV2Card>
          <StakeV2Styled.StakeV2Card>
            <div className="Page-title-section">
              <div className="Page-title">Vest</div>
              <div className="Page-description">Convert esKMX tokens to KMX tokens.</div>
            </div>
            <StakeV2Styled.Card>
              <StakeV2Styled.CardTitle className="App-card-title">
                <img src={kmx40Icon} alt="kmx40Icon" />
                esKMX Vault
              </StakeV2Styled.CardTitle>
              <StakeV2Styled.VestingInfo>
                <StakeV2Styled.StakedTokens>
                  <StakeV2Styled.RewardsBannerText secondary large>
                    Staked Tokens
                  </StakeV2Styled.RewardsBannerText>
                  <div>
                    <StakeV2Styled.RewardsBannerTextWrap>
                      <StakeV2Styled.RewardsBannerText large inline>
                        {formatKeyAmount(vestingData, "klpVesterVestedAmount", 18, 4, true)} esKMX
                      </StakeV2Styled.RewardsBannerText>{" "}
                      <StakeV2Styled.RewardsBannerText inline>
                        ($
                        {formatKeyAmount(processedData, "klpVesterVestedAmountUsd", USD_DECIMALS, 2, true)})
                      </StakeV2Styled.RewardsBannerText>
                    </StakeV2Styled.RewardsBannerTextWrap>
                  </div>
                </StakeV2Styled.StakedTokens>
                <StakeV2Styled.StakingBannerRow>
                  <div className="App-card-row">
                    <div className="label">Reserved for Vesting</div>
                    <div>
                      {formatKeyAmount(vestingData, "klpVesterPairAmount", 18, 2, true)} /{" "}
                      {formatAmount(totalRewardTokens, 18, 2, true)}
                    </div>
                  </div>
                  <div className="App-card-row">
                    <div className="label">Vesting Status</div>
                    <div>
                      <Tooltip
                        handle={`${formatKeyAmount(vestingData, "klpVesterClaimSum", 18, 4, true)} / ${formatKeyAmount(
                          vestingData,
                          "klpVesterVestedAmount",
                          18,
                          4,
                          true
                        )}`}
                        position="right-bottom"
                        renderContent={() => {
                          return (
                            <>
                              {formatKeyAmount(vestingData, "klpVesterClaimSum", 18, 4, true)} tokens have been
                              converted to KMX from the&nbsp;
                              {formatKeyAmount(vestingData, "klpVesterVestedAmount", 18, 4, true)} esKMX deposited for
                              vesting.
                            </>
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="App-card-row">
                    <div className="label">Claimable</div>
                    <div>
                      <Tooltip
                        handle={`${formatKeyAmount(vestingData, "klpVesterClaimable", 18, 4, true)} KMX`}
                        position="right-bottom"
                        renderContent={() =>
                          `${formatKeyAmount(
                            vestingData,
                            "klpVesterClaimable",
                            18,
                            4,
                            true
                          )} KMX tokens can be claimed, use the options under the Earn section to claim them.`
                        }
                      />
                    </div>
                  </div>
                  <StakeV2Styled.Buttons>
                    {!active && (
                      <button className="App-button-option App-card-option" onClick={() => connectWallet()}>
                        Connect Wallet
                      </button>
                    )}
                    {active && (
                      <button
                        className="App-button-option App-card-option disabled"
                        disabled
                        // onClick={() => showKmxVesterDepositModal()}
                      >
                        Deposit
                      </button>
                    )}
                    {active && (
                      <button
                        disabled
                        className="App-button-option App-card-option"
                        // onClick={() => showKmxVesterWithdrawModal()}
                      >
                        Withdraw
                      </button>
                    )}
                  </StakeV2Styled.Buttons>
                </StakeV2Styled.StakingBannerRow>
                <StakeV2Styled.StakingBannerRow borderTop>
                  <StakeV2Styled.RewardsBannerText large title>
                    KMX Staking
                  </StakeV2Styled.RewardsBannerText>
                  <StakeV2Styled.RewardsBannerText secondary title>
                    Stake KMX to receive interest in KCS
                  </StakeV2Styled.RewardsBannerText>
                  {stakingApr && (
                    <div className="App-card-row">
                      <div className="label">Staking APR</div>
                      <div>{stakingApr}%</div>
                    </div>
                  )}
                  <StakeV2Styled.Buttons>
                    {/* <a href="https://lend.kmxelium.xyz" target="_blank" rel="noopener noreferrer">
                      <button className="App-button-option App-card-option">KMX Staking</button>
                    </a> */}
                  </StakeV2Styled.Buttons>
                </StakeV2Styled.StakingBannerRow>
              </StakeV2Styled.VestingInfo>
            </StakeV2Styled.Card>
          </StakeV2Styled.StakeV2Card>
        </StakeV2Styled.StakeV2Cards>
      </StakeV2Styled.StakeV2Content>
    </div>
  );
}
