import React from "react";
import "./global.css";
import styled from "styled-components";
import Vapor from "./vapor";
import QuickToken from "./quicktokens";
import { useHistory } from "react-router-dom";

const Homepage = () => {
    const LandingPageHeroLayout = styled.div`
    @media screen and (min-width: 767px) {
      padding-left: 24px;
      padding-right: 24px;
    }
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    margin-left: auto;
    margin-right: auto;
    padding-left: 16px;
    padding-right: 16px;
    max-width: 1248px;
  `;

    const LandingPageText = styled.div`
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-top: 24px;

    @media screen and (min-width: 767px) {
      padding-top: 40px;
    }
    @media screen and (min-width: 1023px) {
      padding-top: 80px;
    }
  `;
    const FirstSection = styled.div`
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    margin-right: 0;
    padding-top: 0;
    width: 100%;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
    @media screen and (min-width: 767px) {
      margin-right: 0;
      padding-top: 50px;
    }

    @media screen and (min-width: 1023px) {
      margin-right: 106px;
      padding-top: 0;
    }

    @media screen and (min-width: 767px) {
      width: 100%;
      -webkit-order: 2;
      -ms-flex-order: 2;
      order: 2;
    }
    @media screen and (min-width: 1023px) {
      width: 560px;
      -webkit-order: 1;
      padding-top:2rem;
      -ms-flex-order: 1;
      order: 1;
    }
  `;
    const SecondSection = styled.div`
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    width: 0%;
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;

    @media screen and (min-width: 767px) {
      width: 100%;
      -webkit-flex: none;
      -ms-flex: none;
      flex: none;
      -webkit-order: 1;
      -ms-flex-order: 1;
      order: 1;
    }
    @media screen and (min-width: 1023px) {
      width: auto;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      -webkit-order: 2;
      -ms-flex-order: 2;
      order: 2;
    }
  `;
    const InnerT = styled.div`
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    width: 0%;
    margin-left: auto;
    margin-right: auto;
    @media screen and (min-width: 767px) {
      width: 66%;

      @media screen and (min-width: 1023px) {
        width: 100%;
      }
    }
  `;

    const Picture = styled.picture`
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    max-width: 100%;
    height: auto;
    width: 0;
    height: 0;

    @media screen and (min-width: 767px) {
      width: 509px;
      height: 420px;
    }
    @media screen and (min-width: 1023px) {
      width: 534px;
      height: 440px;
    }

    img {
      border-style: none;
    }
  `;

    const history = useHistory();



    const MouseMoveHandler = () => {
        React.useEffect(() => {
            const kmxCards = document.getElementById("kmx-cards") as HTMLElement;
            kmxCards.onmousemove = (e) => {
                for (const card of document.getElementsByClassName("kmx-card")) {
                    const rect = (card as HTMLElement).getBoundingClientRect(),
                        x = e.clientX - rect.left,
                        y = e.clientY - rect.top;

                    (card as HTMLElement).style.setProperty("--mouse-x", `${x}px`);
                    (card as HTMLElement).style.setProperty("--mouse-y", `${y}px`);
                }
            };
        }, []);

        return null;
    }

    return (
        <>
            <MouseMoveHandler />
            <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', overflow: 'hidden', zIndex: 1, clip: 'rect(0, 2602.666748046875px, 2819.55224609375px, 0)', WebkitClipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)', clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)', background: '#000000c4' }} />
            <section className="first pt-14 lg:pt-18 pb-14 lg:pb-28 text-white absolute w-full top-0 z-10">
                <>
                    {/* landing */}
                    <LandingPageHeroLayout>
                        <LandingPageText>
                            {/* first */}
                            <FirstSection className="lg:mr-24 lg:order-1 lg:pt-0 m-0 md:mr-0 md:order-2 md:pt-12 md:w-full min-w-0 order-1 pt-0 text-black w-full">
                                <h1
                                    id="id-topCta-title"
                                    className="m-0 min-w-0 text-3xl font-semibold leading-10 text-gray-200 md:text-4xl md:font-semibold"
                                >
                                    <div className="m-0 min-w-0 text-4xl" style={{ minHeight: "1.2em" }}>
                                        Maximize Your Crypto Profits with 50x Leverage - Start Trading Now!
                                    </div>
                                </h1>
                                <div className="mx-0 mt-10 mb-0 min-w-0 md:mt-12">
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="//bridge.kcc.io"
                                        className="flex items-center m-0 min-w-0 text-blue-700 bg-transparent cursor-pointer"
                                        style={{ textDecoration: "none" }}
                                    >
                                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_24_16963)">
                                                <path
                                                    d="M11.952 6C9.55073 6 7.45542 7.42031 6.33717 9.53194H4.36279V12.8012H8.31154C8.42292 10.7182 10.0069 9.06506 11.9515 9.06506C13.896 9.06506 15.48 10.7182 15.5914 12.8012H19.5402V9.53194H17.5658C16.4481 7.42031 14.3522 6 11.9509 6H11.952ZM4.36279 13.3834V18.6568H8.31604V13.3834H4.36279ZM15.588 13.3834V18.6568H19.5413V13.3834H15.588Z"
                                                    fill="white"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_24_16963">
                                                    <rect width={24} height={24} fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>

                                        <div

                                            className="my-0 mr-0 ml-2 min-w-0 text-sm font-normal text-gray-200 md:text-xl md:font-normal md:leading-7"
                                        >
                                            <div className="m-0 min-w-0 text-xl leading-7">
                                                <div className="m-0 min-w-0" style={{ minHeight: "1.2em" }}>
                                                    Bridge to KCC
                                                </div>
                                            </div>
                                        </div>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            className="m-0 w-4 min-w-0 h-4 text-2xl leading-7 text-gray-400"
                                            style={{ fill: "rgb(132, 142, 156)" }}
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M12.288 12l-3.89 3.89 1.768 1.767L15.823 12l-1.768-1.768-3.889-3.889-1.768 1.768 3.89 3.89z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </a>
                                    <div className="block mb-0 md:flex min-w-0 mt-4 mx-0">
                                        <div className="lg:mt-2 m-0 md:mt-0 md:w-full min-w-0 w-full">
                                            <a
                                                href="/trade"
                                                className="inline-flex justify-center items-center py-3 px-6 m-0 w-full text-base font-medium btn-np leading-6 text-center bg-primary rounded appearance-none cursor-pointer select-none truncate text-zinc-900"
                                                style={{
                                                    textDecoration: "none",
                                                    outline: "none",
                                                    minWidth: 80,
                                                    wordBreak: "keep-all",
                                                    minHeight: 24,
                                                }}
                                            >
                                                <div
                                                    className="m-0 min-w-0 whitespace-nowrap"
                                                    style={{ wordBreak: "keep-all", color: "white" }}
                                                >
                                                    START LEVERAGE
                                                </div>
                                            </a>
                                            <div className="flex justify-between items-center my-4 mx-0 min-w-0">
                                                <div className="flex-1 m-0 min-w-0 h-px bg-zinc-600" />
                                                <div className="flex-1 m-0 min-w-0 h-px bg-zinc-600" />
                                            </div>
                                            <div className="flex flex-row m-0 min-w-0">
                                                <a
                                                    href="http://kuswap.finance"
                                                    className="flex-1 m-0 min-w-0 text-blue-700 bg-transparent cursor-pointer"
                                                    style={{ textDecoration: "none" }}
                                                >
                                                    <div className="flex justify-center items-center m-0 min-w-0 h-12 text-gray-200 rounded bg-kus btn-np">
                                                        <div className="flex justify-center m-0 min-w-0 text-base font-medium leading-6 uppercase">
                                                            EXPLORE ALL
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </FirstSection>
                            {/* second */}
                            <SecondSection>
                                <InnerT>
                                    <Picture data-src="/F392.png">
                                        <source srcSet="/F392.png" type="image/png" />
                                        <img src="/F392.png" alt="" />
                                    </Picture>
                                </InnerT>
                            </SecondSection>
                        </LandingPageText>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "20px",
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                history.push("/trade");
                            }}
                        >
                            <QuickToken />
                        </div>
                        <div id="kmx-cards">
                            <div className="kmx-card">
                                <div style={{

                                    position: 'relative',
                                    zIndex: '1',
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    WebkitBoxPack: 'end',
                                }}>
                                    {/* // first */}
                                    <div style={{
                                    }}>
                                        <img alt="aggregate prices" src="/assets/liq.png" style={{

                                            width: '323px',
                                            height: 'auto',
                                        }}></img>
                                    </div>
                                    {/* // second */}

                                    <div className="card-info-wrapper">
                                        <div className="card-info">
                                            <div className="card-info-title">
                                                <h3>Reduced Liquidation Risks</h3>
                                                <h4>Aggregate of high quality price feeds determine liquidations, keeping positions safe from wicks.</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>  </div>
                            {/* 2 */}
                            <div className="kmx-card">
                                <div style={{

                                    position: 'relative',
                                    zIndex: '1',
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    WebkitBoxPack: 'end',
                                }}>
                                    {/* // first */}
                                    <div style={{
                                    }}>
                                        <img alt="aggregate prices" src="/assets/cost.png" style={{

                                            width: '323px',
                                            height: 'auto',
                                        }}></img>
                                    </div>
                                    {/* // second */}

                                    <div className="card-info-wrapper">
                                        <div className="card-info">
                                            <div className="card-info-title">
                                                <h3>Low Costs</h3>
                                                <h4>Enter or exit with min spread & zero price impact; get optimal price without extra cost.</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* 3 */}
                            <div className="kmx-card">
                                <div style={{

                                    position: 'relative',
                                    zIndex: '1',
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    WebkitBoxPack: 'end',
                                }}>
                                    {/* // first */}
                                    <div style={{
                                    }}>
                                        <img alt="aggregate prices" src="/assets/swap.png" style={{

                                            width: '323px',
                                            height: 'auto',
                                        }}></img>
                                    </div>
                                    {/* // second */}

                                    <div className="card-info-wrapper">
                                        <div className="card-info">
                                            <div className="card-info-title">
                                                <h3>Simple Swaps</h3>
                                                <h4>Swap from any supported asset into desired position via simple interface.</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* 4 */}
                            <div className="kmx-card">
                                <div style={{

                                    position: 'relative',
                                    zIndex: '1',
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    WebkitBoxPack: 'end',
                                }}>
                                    {/* // first */}
                                    <div style={{
                                    }}>
                                        <img alt="aggregate prices" src="/assets/bolt.png" style={{

                                            width: '323px',
                                            height: 'auto',
                                        }}></img>
                                    </div>
                                    {/* // second */}

                                    <div className="card-info-wrapper">
                                        <div className="card-info">
                                            <div className="card-info-title">
                                                <h3>Lightning Fast</h3>
                                                <h4>Experience optimization for faster and more efficient order execution.</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* 5 */}
                            <div className="kmx-card">
                                <div style={{

                                    position: 'relative',
                                    zIndex: '1',
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    WebkitBoxPack: 'end',
                                }}>
                                    {/* // first */}
                                    <div style={{
                                    }}>
                                        <img alt="aggregate prices" src="/assets/restr.png" style={{

                                            width: '323px',
                                            height: 'auto',
                                        }}></img>
                                    </div>
                                    {/* // second */}

                                    <div className="card-info-wrapper">
                                        <div className="card-info">
                                            <div className="card-info-title">
                                                <h3>Permissionless</h3>
                                                <h4>Permissionless by design. Trade anything, anytime, anywhere, without restrictions.</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* 6 */}
                            <div className="kmx-card">
                                <div style={{

                                    position: 'relative',
                                    zIndex: '1',
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    WebkitBoxPack: 'end',
                                }}>
                                    {/* // first */}
                                    <div style={{
                                    }}>
                                        <img alt="aggregate prices" src="/assets/secure.png" style={{

                                            width: '323px',
                                            height: 'auto',
                                        }}></img>
                                    </div>
                                    {/* // second */}

                                    <div className="card-info-wrapper">
                                        <div className="card-info">
                                            <div className="card-info-title">
                                                <h3>Self-Custody</h3>
                                                <h4>Control your assets with your private key and trade securely and directly with your Web3 wallet.</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </LandingPageHeroLayout>
                </>
            </section>
            <div style={{ position: "fixed", zIndex: "0" }}>
                <Vapor />
            </div>
        </>
    );
};

export default Homepage;
