import { ApolloClient, InMemoryCache } from "@apollo/client";

export const chainlinkClient = createClient("");
// export const arbitrumGraphClient = createClient("https://api.thegraph.com/subgraphs/name/kmx-ethereum/kmx-swaps-stats");

export const arbitrumGraphClient = createClient("https://testnet.kuswap.finance/subgraphs/name/kmx/core");

export const arbitrumTestnetGraphClient = createClient(
  "https://api.thegraph.com/subgraphs/name/tracer-protocol/arbitrum-rinkeby-gmx-stats"
);

export const nissohGraphClient = createClient("https://testnet.kuswap.finance/subgraphs/name/kmx/leaderboard");
// export const nissohGraphClient = createClient("https://api.thegraph.com/subgraphs/name/dospore/gmx-vault");

export const arbitrumReferralsGraphClient = createClient(
  "https://api.thegraph.com/subgraphs/name/kmx-ethereum/kmx-swaps-referrals"
);
export const arbitrumTestnetReferralsGraphClient = createClient(
  "https://api.thegraph.com/subgraphs/name/kmx-ethereum/kmx-swaps-testnet-referrals"
);

function createClient(uri: string) {
  return new ApolloClient({
    uri,
    cache: new InMemoryCache(),
  });
}
