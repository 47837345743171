import { Helmet } from "react-helmet";

function SEO(props) {
  const { ...customMeta } = props;
  const meta = {
    title: "KuSwap Perpetual Swaps - Upto 50x leverage on KCC!",
    description: `Experience high-leverage trading with KuSwap Perpetual Swaps. Get up to 50x leverage on Kucoin Community Chain network (KCC) and trade 24/7 on the decentralized platform`,
    type: "exchange",
    ...customMeta,
  };
  const defaultImage = "/meta.png";
  const image = !!meta.image ? meta.image : defaultImage;
  return (
    <Helmet>
      <title>{meta.title}</title>
      <meta name="robots" content="follow, index" />
      <meta name="description" content={meta.description} />

      <meta property="og:url" content="https://kmx.kuswap.io" />
      <meta property="og:type" content={meta.type} />
      <meta property="og:site_name" content="KuSwap Perpetual Swaps - KMX" />
      <meta property="og:description" content={meta.description} />
      <meta property="og:title" content={meta.title} />
      <meta property="og:image" content={image} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@kuswapfinance" />
      <meta name="twitter:title" content={meta.title} />
      <meta name="twitter:description" content={meta.description} />
      <meta name="twitter:image" content={image} />
    </Helmet>
  );
}

export default SEO;
