import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import useSWR from "swr";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import TooltipComponent from "../../components/Tooltip/Tooltip";

import hexToRgba from "hex-to-rgba";
import { ethers } from "ethers";

import { getWhitelistedTokens } from "../../data/Tokens";
import { getFeeHistory, SECONDS_PER_WEEK } from "../../data/Fees";

import {
  fetcher,
  formatAmount,
  formatKeyAmount,
  expandDecimals,
  bigNumberify,
  formatDate,
  getChainName,
  useChainId,
  USD_DECIMALS,
  KMX_DECIMALS,
  KLP_DECIMALS,
  BASIS_POINTS_DIVISOR,
  KCC,
  KLP_POOL_COLORS,
  DEFAULT_MAX_USDK_AMOUNT,
  getPageTitle,
  ETH_DECIMALS,
} from "../../Helpers";
import {
  useTotalKMXInLiquidity,
  useKMXPrice,
  useTotalKMXSupply,
  useFees,
  useFeesSince,
  useStakingApr,
  useTotalStaked,
  useSpreadCaptureVolume,
} from "../../Api";

import { getContract } from "../../Addresses";

import VaultV2 from "../../abis/VaultV2.json";
import ReaderV2 from "../../abis/ReaderV2.json";
import KlpManager from "../../abis/KlpManager.json";

import "./DashboardV2.css";

import kmxToken from "../../img/ic_kmx_custom.svg";
import klp40Icon from "../../img/ic_klp_40.svg";
import kcc16Icon from "../../img/ic_kcc_16.svg";
import kcc24Icon from "../../img/ic_kcc_24.svg";

import AssetDropdown from "./AssetDropdown";
import SEO from "../../components/Common/SEO";
import { ADDRESS_ZERO } from "@uniswap/v3-sdk";
import { useInfoTokens } from "src/hooks/useInfoTokens";
import { getServerUrl } from "src/lib";

const { AddressZero } = ethers.constants;

export const getUnclaimedFees = (tokenAddresses, infoTokens, fees) => {
  if (!fees || !infoTokens) {
    return bigNumberify(0);
  }

  let currentFeesUsd = bigNumberify(0);
  for (let i = 0; i < tokenAddresses.length; i++) {
    const tokenAddress = tokenAddresses[i];
    const tokenInfo = infoTokens[tokenAddress];
    if (!tokenInfo || !tokenInfo.contractMinPrice) {
      continue;
    }

    const feeUsd = fees[i].mul(tokenInfo.contractMinPrice).div(expandDecimals(1, tokenInfo.decimals));
    currentFeesUsd = currentFeesUsd.add(feeUsd);
  }

  return currentFeesUsd;
};

export default function DashboardV2() {
  const { active, library } = useWeb3React();
  const { chainId } = useChainId();

  const chainName = getChainName(chainId);

  const positionStatsUrl = getServerUrl(chainId, "/positionStats");
  const { data: positionStats } = useSWR([positionStatsUrl], {
    fetcher: (...args) => fetch(...args).then((res) => res.json()),
  });

  const kmxTotalVolumeUrl = getServerUrl(chainId, "/volume");
  const { data: kmxTotalVolume } = useSWR([kmxTotalVolumeUrl], {
    fetcher: (...args) => fetch(...args).then((res) => res.json()),
  });

  let { total: kmxSupply, circulating: circulatingKMXSupply } = useTotalKMXSupply();

  let totalLongPositionSizes;
  let totalShortPositionSizes;
  if (positionStats && positionStats.totalLongPositionSizes && positionStats.totalShortPositionSizes) {
    totalLongPositionSizes = bigNumberify(positionStats.totalLongPositionSizes);
    totalShortPositionSizes = bigNumberify(positionStats.totalShortPositionSizes);
  }

  const whitelistedTokens = getWhitelistedTokens(chainId);
  const whitelistedTokenAddresses = whitelistedTokens.map((token) => token.address);
  const tokenList = whitelistedTokens.filter((t) => !t.isWrapped);

  const readerAddress = getContract(chainId, "Reader");
  const vaultAddress = getContract(chainId, "Vault");
  const klpManagerAddress = getContract(chainId, "KlpManager");

  const kmxAddress = getContract(chainId, "KMX");
  const klpAddress = getContract(chainId, "KLP");
  const usdkAddress = getContract(chainId, "USDK");

  const tokensForSupplyQuery = [kmxAddress, klpAddress, usdkAddress];

  const { data: aums } = useSWR([`Dashboard:getAums:${active}`, chainId, klpManagerAddress, "getAums"], {
    fetcher: fetcher(library, KlpManager),
  });

  const { data: fees } = useSWR([`Dashboard:fees:${active}`, chainId, readerAddress, "getFees", vaultAddress], {
    fetcher: fetcher(library, ReaderV2, [whitelistedTokenAddresses]),
  });

  const { data: totalSupplies } = useSWR(
    [`Dashboard:totalSupplies:${active}`, chainId, readerAddress, "getTokenBalancesWithSupplies", AddressZero],
    {
      fetcher: fetcher(library, ReaderV2, [tokensForSupplyQuery]),
    }
  );

  const { data: totalTokenWeights } = useSWR(
    [`KlpSwap:totalTokenWeights:${active}`, chainId, vaultAddress, "totalTokenWeights"],
    {
      fetcher: fetcher(library, VaultV2),
    }
  );

  const { infoTokens } = useInfoTokens(library, chainId, active, undefined, undefined);

  const allFees = useFees(chainId);

  const feeHistory = getFeeHistory(chainId);

  const from = feeHistory[0]?.to;
  const to = from + SECONDS_PER_WEEK * 2;
  const currentGraphFees = useFeesSince(chainId, from, to);
  const currentUnclaimedFees = getUnclaimedFees(whitelistedTokenAddresses, infoTokens, fees);
  let totalCurrentFees;
  if (currentUnclaimedFees && currentGraphFees) {
    totalCurrentFees = currentUnclaimedFees.gt(currentGraphFees) ? currentUnclaimedFees : currentGraphFees;
  }

  let totalFeesDistributed;
  if (allFees) {
    totalFeesDistributed = bigNumberify(allFees.mint)
      .add(allFees.burn)
      .add(allFees.marginAndLiquidation)
      .add(allFees.swap);
  }

  const totalMMFees = useSpreadCaptureVolume(chainId);

  let totalFees;
  if (totalFeesDistributed && totalMMFees) {
    totalFees = totalFeesDistributed.add(totalMMFees);
  }

  const { kmxPrice, kmxPriceFromMainnet, kmxPriceFromArbitrum } = useKMXPrice(
    chainId,
    { arbitrum: chainId === KCC ? library : undefined },
    active
  );

  const ethToken = infoTokens[ADDRESS_ZERO];
  const ethPrice = ethToken.maxPrice;

  let { mainnet: totalKMXInLiquidityMainnet, arbitrum: totalKMXInLiquidityArbitrum } = useTotalKMXInLiquidity(
    chainId,
    active
  );

  let kmxMarketCap;
  if (kmxPrice && circulatingKMXSupply) {
    kmxMarketCap = kmxPrice.mul(circulatingKMXSupply).div(expandDecimals(1, KMX_DECIMALS));
  }

  let kmxFullyDilutedMarketCap;
  if (kmxPrice && kmxSupply) {
    kmxFullyDilutedMarketCap = kmxPrice.mul(kmxSupply).div(expandDecimals(1, KMX_DECIMALS));
  }

  let aum;
  if (aums && aums.length > 0) {
    aum = aums[0].add(aums[1]).div(2);
  }

  let klpPrice;
  let klpSupply;
  let klpMarketCap;
  if (aum && totalSupplies && totalSupplies[3]) {
    klpSupply = totalSupplies[3];
    klpPrice =
      aum && aum.gt(0) && klpSupply.gt(0)
        ? aum.mul(expandDecimals(1, KLP_DECIMALS)).div(klpSupply)
        : expandDecimals(1, USD_DECIMALS);
    klpMarketCap = klpPrice.mul(klpSupply).div(expandDecimals(1, KLP_DECIMALS));
  }

  const stakingApr = useStakingApr(kmxPrice, ethPrice);
  const totalStakedKmx = useTotalStaked();

  const stakingTvl = useMemo(() => {
    if (!kmxPrice || !totalStakedKmx) return bigNumberify(0);
    return kmxPrice.mul(totalStakedKmx);
  }, [kmxPrice, totalStakedKmx]);

  const tvl = aum?.add(stakingTvl);

  let adjustedUsdkSupply = bigNumberify(0);

  for (let i = 0; i < tokenList.length; i++) {
    const token = tokenList[i];
    const tokenInfo = infoTokens[token.address];
    if (tokenInfo && tokenInfo.usdkAmount) {
      adjustedUsdkSupply = adjustedUsdkSupply.add(tokenInfo.usdkAmount);
    }
  }

  const getWeightText = (tokenInfo) => {
    if (
      !tokenInfo.weight ||
      !tokenInfo.usdkAmount ||
      !adjustedUsdkSupply ||
      adjustedUsdkSupply.eq(0) ||
      !totalTokenWeights
    ) {
      return "...";
    }

    const currentWeightBps = tokenInfo.usdkAmount.mul(BASIS_POINTS_DIVISOR).div(adjustedUsdkSupply);
    const targetWeightBps = tokenInfo.weight.mul(BASIS_POINTS_DIVISOR).div(totalTokenWeights);

    const weightText = `${formatAmount(currentWeightBps, 2, 2, false)}% / ${formatAmount(
      targetWeightBps,
      2,
      2,
      false
    )}%`;

    return (
      <TooltipComponent
        handle={weightText}
        position="right-bottom"
        renderContent={() => {
          return (
            <>
              Current Weight: {formatAmount(currentWeightBps, 2, 2, false)}%<br />
              Target Weight: {formatAmount(targetWeightBps, 2, 2, false)}%<br />
              <br />
              {currentWeightBps.lt(targetWeightBps) && (
                <div>
                  {tokenInfo.symbol} is below its target weight.
                  <br />
                  <br />
                  Get lower fees to{" "}
                  <Link to="/buy_klp" target="_blank" rel="noopener noreferrer">
                    buy KLP
                  </Link>{" "}
                  with {tokenInfo.symbol},&nbsp; and to{" "}
                  <Link to="/" target="_blank" rel="noopener noreferrer">
                    swap
                  </Link>{" "}
                  {tokenInfo.symbol} for other tokens.
                </div>
              )}
              {currentWeightBps.gt(targetWeightBps) && (
                <div>
                  {tokenInfo.symbol} is above its target weight.
                  <br />
                  <br />
                  Get lower fees to{" "}
                  <Link to="/" target="_blank" rel="noopener noreferrer">
                    swap
                  </Link>{" "}
                  tokens for {tokenInfo.symbol}.
                </div>
              )}
              {/* <br /> */}
              {/* <div>
                <a
                  href="https://swaps.docs.kmxelium.xyz/protocol-design/kmxelium-liquidity-pool-klp/klp-mechanism"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  More Info
                </a>
              </div> */}
            </>
          );
        }}
      />
    );
  };
  const equaliseValue = (item) => {
    return item.div(expandDecimals(1, ETH_DECIMALS)).toNumber();
  };

  const formatPercentage = (value) => {
    return parseFloat((value * 100).toFixed(2));
  };

  let stakedPercent = 0;
  if (circulatingKMXSupply && !circulatingKMXSupply.isZero() && totalStakedKmx) {
    stakedPercent = totalStakedKmx.toNumber() / equaliseValue(circulatingKMXSupply);
    stakedPercent = formatPercentage(stakedPercent);
  }

  let arbitrumLiquidityPercent = 0;
  if (circulatingKMXSupply && !circulatingKMXSupply.isZero() && totalKMXInLiquidityArbitrum) {
    arbitrumLiquidityPercent = equaliseValue(totalKMXInLiquidityArbitrum) / equaliseValue(circulatingKMXSupply);
    arbitrumLiquidityPercent = formatPercentage(arbitrumLiquidityPercent);
  }

  let mainnetLiquidityPercent = 0;
  if (circulatingKMXSupply && !circulatingKMXSupply.isZero() && totalKMXInLiquidityMainnet) {
    mainnetLiquidityPercent = equaliseValue(totalKMXInLiquidityMainnet) / equaliseValue(circulatingKMXSupply);
    mainnetLiquidityPercent = formatPercentage(mainnetLiquidityPercent);
  }

  let notStakedPercent = parseFloat(
    (100 - arbitrumLiquidityPercent - mainnetLiquidityPercent - stakedPercent).toFixed(2)
  );
  let kmxDistributionData = [
    {
      name: "staked",
      value: stakedPercent,
      color: "#4353fa",
    },
    {
      name: "in Arbitrum liquidity",
      value: arbitrumLiquidityPercent,
      color: "#0598fa",
    },
    {
      name: "in Mainnet liquidity",
      value: mainnetLiquidityPercent,
      color: "#4353fa",
    },
    {
      name: "in wallets",
      value: notStakedPercent,
      color: "#23D1B0",
    },
  ];

  const totalStatsStartDate = "14 Aug 2022";

  let stableKlp = 0;
  let totalKlp = 0;

  let klpPool = tokenList.map((token) => {
    const tokenInfo = infoTokens[token.address];
    if (tokenInfo.usdkAmount && adjustedUsdkSupply && !adjustedUsdkSupply.eq(0)) {
      const currentWeightBps = tokenInfo.usdkAmount.mul(BASIS_POINTS_DIVISOR).div(adjustedUsdkSupply);
      if (tokenInfo.isStable) {
        stableKlp += parseFloat(`${formatAmount(currentWeightBps, 2, 2, false)}`);
      }
      totalKlp += parseFloat(`${formatAmount(currentWeightBps, 2, 2, false)}`);
      return {
        fullname: token.name,
        name: token.symbol,
        value: parseFloat(`${formatAmount(currentWeightBps, 2, 2, false)}`),
      };
    }
    return null;
  });

  let stablePercentage = totalKlp > 0 ? ((stableKlp * 100) / totalKlp).toFixed(2) : "0.0";

  klpPool = klpPool.filter(function (element) {
    return element !== null;
  });

  klpPool = klpPool.sort(function (a, b) {
    if (a.value < b.value) return 1;
    else return -1;
  });

  kmxDistributionData = kmxDistributionData.sort(function (a, b) {
    if (a.value < b.value) return 1;
    else return -1;
  });

  const [kmxActiveIndex, setKMXActiveIndex] = useState(null);

  const onKMXDistributionChartEnter = (_, index) => {
    setKMXActiveIndex(index);
  };

  const onKMXDistributionChartLeave = (_, index) => {
    setKMXActiveIndex(null);
  };

  const [klpActiveIndex, setKLPActiveIndex] = useState(null);

  const onKLPPoolChartEnter = (_, index) => {
    setKLPActiveIndex(index);
  };

  const onKLPPoolChartLeave = (_, index) => {
    setKLPActiveIndex(null);
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="stats-label">
          <div className="stats-label-color" style={{ backgroundColor: payload[0].color }}></div>
          {payload[0].value}% {payload[0].name}
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <SEO
        title={getPageTitle("Dashboard")}
        description="View stats on Kmxelium Perpetual Swaps, the KMX token, the KLP token and the KLP liquidity pool."
      />
      <div className="default-container DashboardV2 page-layout">
        <div className="section-title-block">
          <div className="section-title-content">
            <div className="Page-title">Stats {chainId === KCC && <img src={kcc24Icon} alt="kcc24Icon" />}</div>
            <div className="Page-description">
              {chainName} Total Stats{" "}
              {/* See detailed analytics{" "}
              <a href="https://analytics.kmxelium.xyz" target="_blank" rel="noopener noreferrer">
                here.
              </a> */}
            </div>
          </div>
        </div>
        <div className="DashboardV2-content">
          <div className="DashboardV2-cards">
            <div className="App-card">
              <div className="App-card-title">Overview</div>
              <div className="App-card-divider"></div>
              <div className="App-card-content">
                <div className="App-card-row">
                  <div className="label">AUM</div>
                  <div>
                    <TooltipComponent
                      handle={`$${formatAmount(tvl, USD_DECIMALS, 0, true)}`}
                      position="right-bottom"
                      renderContent={() => `Assets Under Management: KMX staked (All chains) + KLP pool (${chainName})`}
                    />
                  </div>
                </div>

                <div className="App-card-row">
                  <div className="label">KLP Pool</div>
                  <div>
                    <TooltipComponent
                      handle={`$${formatAmount(aum, USD_DECIMALS, 0, true)}`}
                      position="right-bottom"
                      renderContent={() => `Total value of tokens in KLP pool (${chainName})`}
                    />
                  </div>
                </div>
                <div className="App-card-row">
                  <div className="label">24h Volume</div>
                  <div>${formatAmount(kmxTotalVolume?.oneDayVolume, USD_DECIMALS, 0, true)}</div>
                </div>
                <div className="App-card-row">
                  <div className="label">Long Positions</div>
                  <div>${formatAmount(totalLongPositionSizes, USD_DECIMALS, 0, true)}</div>
                </div>
                <div className="App-card-row">
                  <div className="label">Short Positions</div>
                  <div>${formatAmount(totalShortPositionSizes, USD_DECIMALS, 0, true)}</div>
                </div>
                {feeHistory.length ? (
                  <div className="App-card-row">
                    <div className="label">Fees since {formatDate(feeHistory[0].to)}</div>
                    <div>${formatAmount(totalCurrentFees, USD_DECIMALS, 2, true)}</div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="App-card">
              <div className="App-card-title">Total Stats</div>
              <div className="App-card-divider"></div>
              <div className="App-card-content">
                <div className="App-card-row">
                  <div className="label">Total Fees</div>
                  <div>
                    <TooltipComponent
                      position="right-bottom"
                      className="nowrap"
                      handle={`$${formatAmount(totalFees, USD_DECIMALS, 0, true)}`}
                      renderContent={() => (
                        <>
                          Distributed Fees: ${formatAmount(totalFeesDistributed, USD_DECIMALS, 0, true)}
                          <br />
                          Spread Capture: ${formatAmount(totalMMFees, USD_DECIMALS, 0, true)}
                        </>
                      )}
                    />
                  </div>
                </div>
                <div className="App-card-row">
                  <div className="label">Total Volume</div>
                  <div>${formatAmount(kmxTotalVolume?.totalVolume, USD_DECIMALS, 0, true)}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="Tab-title-section">
            <div className="Page-title">Tokens {chainId === KCC && <img src={kcc24Icon} alt="kcc24Icon" />}</div>
            <div className="Page-description">Platform and KLP index tokens.</div>
          </div>
          <div className="DashboardV2-token-cards">
            <div className="stats-wrapper stats-wrapper--kmx">
              <div className="App-card Kmx">
                <div className="stats-container">
                  <div className="stats-block">
                    <div className="App-card-title">
                      <div className="App-card-title-mark">
                        <div className="App-card-title-mark-icon">
                          <img src={kmxToken} alt="kmxToken" />
                        </div>
                        <div className="App-card-title-mark-info">
                          <div className="App-card-title-mark-title">KMX</div>
                          <div className="App-card-title-mark-subtitle">KMX</div>
                        </div>
                        <div>
                          <AssetDropdown assetSymbol="KMX" />
                        </div>
                      </div>
                    </div>
                    <div className="App-card-divider"></div>
                    <div className="App-card-content">
                      <div className="App-card-row">
                        <div className="label">Price</div>
                        <div>
                          {!kmxPrice && "..."}
                          {kmxPrice && (
                            <TooltipComponent
                              position="right-bottom"
                              className="nowrap"
                              handle={`$${formatAmount(kmxPrice, USD_DECIMALS, 3, true)}`}
                              renderContent={() => (
                                <>
                                  Price on KCC: ${formatAmount(kmxPriceFromArbitrum, USD_DECIMALS, 4, true)}
                                  <br />
                                  Price on Mainnet: ${formatAmount(kmxPriceFromMainnet, USD_DECIMALS, 4, true)}
                                </>
                              )}
                            />
                          )}
                        </div>
                      </div>
                      <div className="App-card-row">
                        <div className="label">Supply</div>
                        <div>
                          <TooltipComponent
                            position="right-bottom"
                            className="nowrap"
                            handle={`${formatAmount(circulatingKMXSupply, KMX_DECIMALS, 0, true)} KMX`}
                            renderContent={() => (
                              <>
                                Circulating: {formatAmount(circulatingKMXSupply, KMX_DECIMALS, 0, true)} KMX
                                <br />
                                Total: {formatAmount(kmxSupply, KMX_DECIMALS, 0, true)} KMX
                              </>
                            )}
                          />
                        </div>
                      </div>
                      {/*<div className="App-card-row">
                      <div className="label">Total Staked</div>
                      <div>
                        {
                          <TooltipComponent
                            position="right-bottom"
                            className="nowrap"
                            handle={`$${formatAmount(stakedKmxSupplyUsd, USD_DECIMALS, 0, true)}`}
                            renderContent={() => (
                              <>
                                Staked on Arbitrum: {formatAmount(arbitrumStakedKmx, KMX_DECIMALS, 0, true)} KMX
                              </>
                            )}
                          />
                        }
                      </div>
                    </div>*/}
                      <div className="App-card-row">
                        <div className="label">Market Cap</div>
                        <div>
                          <TooltipComponent
                            position="right-bottom"
                            className="nowrap"
                            handle={`$${formatAmount(kmxMarketCap, USD_DECIMALS, 0, true)}`}
                            renderContent={() => (
                              <>Fully Diluted: ${formatAmount(kmxFullyDilutedMarketCap, USD_DECIMALS, 0, true)}</>
                            )}
                          />
                        </div>
                      </div>
                      {stakingApr && (
                        <div className="App-card-row">
                          <div className="label">Staking APR</div>
                          <div>{stakingApr}%</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="stats-piechart" onMouseLeave={onKMXDistributionChartLeave}>
                    {kmxDistributionData.length > 0 && (
                      <PieChart width={210} height={210}>
                        <Pie
                          data={kmxDistributionData}
                          cx={100}
                          cy={100}
                          innerRadius={73}
                          outerRadius={80}
                          fill="#8884d8"
                          dataKey="value"
                          startAngle={90}
                          endAngle={-270}
                          paddingAngle={2}
                          onMouseEnter={onKMXDistributionChartEnter}
                          onMouseOut={onKMXDistributionChartLeave}
                          onMouseLeave={onKMXDistributionChartLeave}
                        >
                          {kmxDistributionData.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={entry.color}
                              style={{
                                filter:
                                  kmxActiveIndex === index
                                    ? `drop-shadow(0px 0px 6px ${hexToRgba(entry.color, 0.7)})`
                                    : "none",
                                cursor: "pointer",
                              }}
                              stroke={entry.color}
                              strokeWidth={kmxActiveIndex === index ? 1 : 1}
                            />
                          ))}
                        </Pie>
                        <text x={"50%"} y={"50%"} fill="white" textAnchor="middle" dominantBaseline="middle">
                          Distribution
                        </text>
                        <Tooltip content={<CustomTooltip />} />
                      </PieChart>
                    )}
                  </div>
                </div>
                {/* <div className="Button-container">
                  <div className="Staking-btn">
                    <a href="https://kmx.kuswap.finance/stake" target="_blank" rel="noopener noreferrer">
                      <button className="App-button-option App-card-option">KMX Staking</button>
                    </a>
                  </div>
                  <div className="Buy-btn">
                    <a href="https://kmx.kuswap.finance" target="_blank" rel="noopener noreferrer">
                      <button className="App-button-option App-card-option">Buy KMX</button>
                    </a>
                  </div>
                </div> */}
              </div>
              <div className="App-card">
                <div className="stats-block">
                  <div className="App-card-title">
                    <div className="App-card-title-mark">
                      <div className="App-card-title-mark-icon">
                        <img src={klp40Icon} alt="klp40Icon" />
                      </div>
                      <div className="App-card-title-mark-info">
                        <div className="App-card-title-mark-title">KLP</div>
                        <div className="App-card-title-mark-subtitle">KLP</div>
                      </div>
                      <div>
                        <AssetDropdown assetSymbol="KLP" />
                      </div>
                    </div>
                  </div>
                  <div className="App-card-divider"></div>
                  <div className="App-card-content">
                    <div className="App-card-row">
                      <div className="label">Price</div>
                      <div>${formatAmount(klpPrice, USD_DECIMALS, 3, true)}</div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">Supply</div>
                      <div>{formatAmount(klpSupply, KLP_DECIMALS, 0, true)} KLP</div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">Total Staked</div>
                      <div>${formatAmount(klpMarketCap, USD_DECIMALS, 0, true)}</div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">Market Cap</div>
                      <div>${formatAmount(klpMarketCap, USD_DECIMALS, 0, true)}</div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">Stablecoin Percentage</div>
                      <div>{stablePercentage}%</div>
                    </div>
                  </div>
                </div>
                <div className="stats-piechart" onMouseOut={onKLPPoolChartLeave}>
                  {klpPool.length > 0 && (
                    <PieChart width={210} height={210}>
                      <Pie
                        data={klpPool}
                        cx={100}
                        cy={100}
                        innerRadius={73}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                        startAngle={90}
                        endAngle={-270}
                        onMouseEnter={onKLPPoolChartEnter}
                        onMouseOut={onKLPPoolChartLeave}
                        onMouseLeave={onKLPPoolChartLeave}
                        paddingAngle={2}
                      >
                        {klpPool.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={KLP_POOL_COLORS[entry.name]}
                            style={{
                              filter:
                                klpActiveIndex === index
                                  ? `drop-shadow(0px 0px 6px ${hexToRgba(
                                      KLP_POOL_COLORS[entry?.name] || "#007bff",
                                      0.7
                                    )})`
                                  : "none",
                              cursor: "pointer",
                            }}
                            stroke={KLP_POOL_COLORS[entry.name]}
                            strokeWidth={klpActiveIndex === index ? 1 : 1}
                          />
                        ))}
                      </Pie>
                      <text x={"50%"} y={"50%"} fill="white" textAnchor="middle" dominantBaseline="middle">
                        KLP Pool
                      </text>
                      <Tooltip content={<CustomTooltip />} />
                    </PieChart>
                  )}
                </div>
              </div>
            </div>
            <div className="token-table-wrapper App-card">
              <div className="App-card-title">
                KLP Index Composition {chainId === KCC && <img src={kcc16Icon} alt="kcc16Icon" />}
              </div>
              <div className="App-card-divider"></div>
              <table className="token-table">
                <thead>
                  <tr>
                    <th>Token</th>
                    <th>Price</th>
                    <th>Pool</th>
                    <th>Weight</th>
                    <th>Utilization</th>
                  </tr>
                </thead>
                <tbody>
                  {tokenList.map((token) => {
                    const tokenInfo = infoTokens[token.address];
                    let utilization = bigNumberify(0);
                    if (tokenInfo && tokenInfo.reservedAmount && tokenInfo.poolAmount && tokenInfo.poolAmount.gt(0)) {
                      utilization = tokenInfo.reservedAmount.mul(BASIS_POINTS_DIVISOR).div(tokenInfo.poolAmount);
                    }
                    let maxUsdkAmount = DEFAULT_MAX_USDK_AMOUNT;
                    if (tokenInfo.maxUsdkAmount && tokenInfo.maxUsdkAmount.gt(0)) {
                      maxUsdkAmount = tokenInfo.maxUsdkAmount;
                    }

                    var tokenImage = null;

                    try {
                      tokenImage = require("../../img/ic_" + token.symbol.toLowerCase() + "_40.svg");
                    } catch (error) {
                      console.error(error);
                    }

                    return (
                      <tr key={token.symbol}>
                        <td>
                          <div className="token-symbol-wrapper">
                            <div className="App-card-title-info">
                              <div className="App-card-title-info-icon">
                                <img src={tokenImage && tokenImage.default} alt={token.symbol} width="40px" />
                              </div>
                              <div className="App-card-title-info-text">
                                <div className="App-card-info-title">{token.name}</div>
                                <div className="App-card-info-subtitle">{token.symbol}</div>
                              </div>
                              <div>
                                <AssetDropdown assetSymbol={token.symbol} assetInfo={token} />
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>${formatKeyAmount(tokenInfo, "minPrice", USD_DECIMALS, 2, true)}</td>
                        <td>
                          <TooltipComponent
                            handle={`$${formatKeyAmount(tokenInfo, "managedUsd", USD_DECIMALS, 0, true)}`}
                            position="right-bottom"
                            renderContent={() => {
                              return (
                                <>
                                  Pool Amount: {formatKeyAmount(tokenInfo, "managedAmount", token.decimals, 2, true)}{" "}
                                  {token.symbol}
                                  <br />
                                  <br />
                                  Max {tokenInfo.symbol} Capacity: ${formatAmount(maxUsdkAmount, 18, 0, true)}
                                </>
                              );
                            }}
                          />
                        </td>
                        <td>{getWeightText(tokenInfo)}</td>
                        <td>{formatAmount(utilization, 2, 2, false)}%</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="token-grid">
              {tokenList.map((token) => {
                const tokenInfo = infoTokens[token.address];
                let utilization = bigNumberify(0);
                if (tokenInfo && tokenInfo.reservedAmount && tokenInfo.poolAmount && tokenInfo.poolAmount.gt(0)) {
                  utilization = tokenInfo.reservedAmount.mul(BASIS_POINTS_DIVISOR).div(tokenInfo.poolAmount);
                }
                let maxUsdkAmount = DEFAULT_MAX_USDK_AMOUNT;
                if (tokenInfo.maxUsdkAmount && tokenInfo.maxUsdkAmount.gt(0)) {
                  maxUsdkAmount = tokenInfo.maxUsdkAmount;
                }

                return (
                  <div className="App-card" key={token.symbol}>
                    <div className="App-card-title">
                      <div style={{ display: "flex" }}>
                        {token.symbol}
                        <div>
                          <AssetDropdown assetSymbol={token.symbol} assetInfo={token} />
                        </div>
                      </div>
                    </div>
                    <div className="App-card-divider"></div>
                    <div className="App-card-content">
                      <div className="App-card-row">
                        <div className="label">Price</div>
                        <div>${formatKeyAmount(tokenInfo, "minPrice", USD_DECIMALS, 2, true)}</div>
                      </div>
                      <div className="App-card-row">
                        <div className="label">Pool</div>
                        <div>
                          <TooltipComponent
                            handle={`$${formatKeyAmount(tokenInfo, "managedUsd", USD_DECIMALS, 0, true)}`}
                            position="right-bottom"
                            renderContent={() => {
                              return (
                                <>
                                  Pool Amount: {formatKeyAmount(tokenInfo, "managedAmount", token.decimals, 2, true)}{" "}
                                  {token.symbol}
                                  <br />
                                  <br />
                                  Max {tokenInfo.symbol} Capacity: ${formatAmount(maxUsdkAmount, 18, 0, true)}
                                </>
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className="App-card-row">
                        <div className="label">Weight</div>
                        <div>{getWeightText(tokenInfo)}</div>
                      </div>
                      <div className="App-card-row">
                        <div className="label">Utilization</div>
                        <div>{formatAmount(utilization, 2, 2, false)}%</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
