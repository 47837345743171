import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
import { SWRConfig } from "swr";
import { ethers } from "ethers";

import { motion, AnimatePresence } from "framer-motion";

import { Web3ReactProvider, useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import styled from "styled-components";
import { Switch, Route, NavLink, Redirect, useLocation } from "react-router-dom";
import { appTheme } from "./state/atoms";
import { useRecoilState } from "recoil";
import { ThemeProvider } from "@tracer-protocol/tracer-ui";
import { getTokens, getWhitelistedTokens } from "./data/Tokens";
import { LeaderboardProvider } from "./context/LeaderboardContext";

import {
  SLIPPAGE_BPS_KEY,
  IS_PNL_IN_LEVERAGE_KEY,
  SHOW_PNL_AFTER_FEES_KEY,
  SHOULD_SHOW_POSITION_LINES_KEY,
  REFERRAL_CODE_KEY,
  REFERRAL_CODE_QUERY_PARAMS,
  SHOULD_EAGER_CONNECT_LOCALSTORAGE_KEY,
  CURRENT_PROVIDER_LOCALSTORAGE_KEY,
} from "./config/localstorage";
import { Banner, BannerTitle, BannerContent } from "./components/Banner/Banner";
import {
  DEFAULT_SLIPPAGE_AMOUNT,
  BASIS_POINTS_DIVISOR,
  fetcher,
  clearWalletConnectData,
  KCC,
  switchNetwork,
  helperToast,
  getChainName,
  useChainId,
  getAccountUrl,
  getInjectedHandler,
  useEagerConnect,
  useLocalStorageSerializeKey,
  useInactiveListener,
  getExplorerUrl,
  getWalletConnectHandler,
  activateInjectedProvider,
  hasMetaMaskWalletExtension,
  hasCoinBaseWalletExtension,
  isMobileDevice,
  clearWalletLinkData,
  getBalanceAndSupplyData,
  formatAmount,
  formatTitleCase,
  getUserTokenBalances,
  hasChangedAccount,
  setCurrentAccount,
  networkOptions,
  PLACEHOLDER_ACCOUNT,
  getDefaultArbitrumRpcUrl,
  KCC_TESTNET,
} from "./Helpers";
import ReaderV2 from "./abis/ReaderV2.json";

import Dashboard from "./views/Dashboard/DashboardV2";
import Stake from "./views/Stake/StakeV2";
import { Exchange } from "./views/Exchange/Exchange";
import Actions from "./views/Actions/Actions";
import OrdersOverview from "./views/OrdersOverview/OrdersOverview";
import PositionsOverview from "./views/PositionsOverview/PositionsOverview";
// import BuyKMX from "./views/BuyKMX/BuyKMX";
import BuyKlp from "./views/BuyKlp/BuyKlp";
import SellKlp from "./views/SellKlp/SellKlp";
import Rewards from "./views/Rewards/Rewards";
import Referrals from "./views/Referrals/Referrals";
// import NftWallet from "./views/NftWallet/NftWallet";
// import BeginAccountTransfer from "./views/BeginAccountTransfer/BeginAccountTransfer";
// import CompleteAccountTransfer from "./views/CompleteAccountTransfer/CompleteAccountTransfer";
// import Debug from "./views/Debug/Debug";
// import ConsentModal from "./components/ConsentModal/ConsentModal";
import MobileLinks from "./components/Navigation/MobileNav";

import cx from "classnames";
import { cssTransition, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NetworkSelector from "./components/NetworkSelector/NetworkSelector";
import Modal from "./components/Modal/Modal";
import Checkbox from "./components/Checkbox/Checkbox";
import V1Footer from "./Footer";

import { RiMenuLine } from "react-icons/ri";
import { FaTimes } from "react-icons/fa";
import { FiX } from "react-icons/fi";
import WalletConnectModal from "./WalletConnectModal";

import "./Shared.css";
import "./App.css";
import "./Input.css";
import "./AppOrder.css";

import logoImg from "./img/logo_KUS.svg";

import { KUSIcon, KMXIcon, NFTIcon, FarmIcon, PredictionIcon } from "./img/menu-icons";

import logoSmallImg from "./img/logo_KUS_small.svg";
// import poolsSmallImg from "./img/kmx_pools_short.svg";
import connectWalletImg from "./img/ic_wallet_24.svg";
import AllMenuNav from "./components/AllMenuNav";
import metamaskImg from "./img/metamask.png";
import coinbaseImg from "./img/coinbaseWallet.png";
import walletConnectImg from "./img/walletconnect-circle-blue.svg";
import AddressDropdown from "./components/AddressDropdown/AddressDropdown";
import { ConnectWalletButton } from "./components/Common/Button";
import useEventToast from "./components/EventToast/useEventToast";
import { Link } from "react-router-dom";
import EventToastContainer from "./components/EventToast/EventToastContainer";
import useRouteQuery from "./hooks/useRouteQuery";
import { encodeReferralCode } from "./Api/referrals";

import { getContract } from "./Addresses";
import VaultV2 from "./abis/VaultV2.json";
import VaultV2b from "./abis/VaultV2b.json";
import PositionRouter from "./abis/PositionRouter.json";
import PageNotFound from "./views/PageNotFound/PageNotFound";
import Homepage from "./views/Homepage";
import useSWR from "swr";
import LinkDropdown from "./components/Navigation/LinkDropdown/LinkDropdown";
import Sidebar from "./components/Navigation/Sidebar/Sidebar";
import EventModal from "./components/EventModal/EventModal";
import AppDropdown from "./components/AppDropdown/AppDropdown";
import { useInfoTokens } from "./hooks/useInfoTokens";
// import { Banner, BannerContent } from "./components/Banner/Banner";

if ("ethereum" in window) {
  window.ethereum.autoRefreshOnNetworkChange = false;
}

function getLibrary(provider) {
  const library = new Web3Provider(provider);
  return library;
}

const Zoom = cssTransition({
  enter: "zoomIn",
  exit: "zoomOut",
  appendPosition: false,
  collapse: true,
  collapseDuration: 200,
  duration: 200,
});

function inPreviewMode() {
  return false;
}

function getWsProvider(active, chainId) {
  if (!active) {
    return;
  }

  if (chainId === KCC_TESTNET) {
    return new ethers.providers.WebSocketProvider("wss://rpc-ws-testnet.kcc.network");
  }
  // return 'wss://rpc-ws-testnet.kcc.network'
}
export const BetaBanner = styled.div(
  (props) => `
  position: fixed;
  left:0;
  right: 0;
  bottom: 0px;
  height: 50px;
  background: linear-gradient(112.34deg, #000 17.72%, rgba(0, 49, 0, 0) 120.46%), #07101E;
  display: flex;
  align-items: center;
  border: 0.5px solid hsl(217deg 75% 55%);
  justify-content:center;
  padding: 0 20px;
  transition: width 0.3s ease, opacity 0.3s ease;
  z-index: 20;
  pointer-events: none;
  min-width: 320px;
  &.active {
    opacity: 1;
    pointer-events: all;
  }
  @media (max-width: 1000px) {
    height: 150px;
    padding: 2px 16px;
    align-items: center;
    ${BannerTitle} {
      margin:  8px 0;
    }
  }
  `
);

function AppHeaderLinks({ small, openSettings, clickCloseIcon, trackAction }) {
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  if (inPreviewMode()) {
    return (
      <div className="app-header-links preview">
        <div className="app-header-link-container app-header-link-home">
          <NavLink activeClassName="active" exact to="/">
            HOME
          </NavLink>
        </div>
        {/* <div className="app-header-link-container">
          <NavLink activeClassName="active" to="/earn">
            EARN
          </NavLink>
        </div> */}
        <div className="app-header-link-container">
          <a href="https://kuswapv3.gitbook.io/kmx" target="_blank" rel="noopener noreferrer">
            ABOUT
          </a>
        </div>
      </div>
    );
  }
  return (
    <div className="app-header-links">
      {small && (
        <div className="app-header-links-header">
          <div className="app-header-menu-icon-block" onClick={() => clickCloseIcon()}>
            <FiX className="app-header-menu-icon" />
          </div>
          <Link className="app-header-link-main" to="/">
            <img src={logoImg} alt="KMX Swaps Logo" />
          </Link>
        </div>
      )}
      <div className="app-header-link-container">
        <NavLink exact activeClassName="active" to="/dashboard">
          Dashboard
        </NavLink>
      </div>
      {/* <div className="app-header-link-container">
        <NavLink exact activeClassName="active" to="#">
          Earn
        </NavLink>
      </div> */}
      {/* id title description href icon isNew */}
      <div
        onMouseEnter={() => setIsPopoverOpen(true)}
        style={{
          height: "100%",
        }}
      >
        <AllMenuNav
          items={[
            {
              id: "AMM",
              title: "Decentralized Exchange",
              href: "https://kuswap.finance",
              icon: (
                <>
                  <KUSIcon />
                </>
              ),
              description: "AMM",
              isNew: false,
            },
            {
              id: "Perpetuals",
              title: "Perpetual Exchange",
              href: "/trade",
              icon: (
                <>
                  <KMXIcon />
                </>
              ),
              description: "Perpetuals",
              isNew: true,
            },
            {
              id: "Marketplace",
              title: "NFT Marketplace",
              href: "https://nft.kuswap.finance",
              icon: (
                <>
                  <NFTIcon />
                </>
              ),
              description: "NFT Marketplace",
              isNew: true,
            },
            {
              id: "Predictions",
              title: "Predictions",
              href: "https://play.kuswap.finance",
              icon: (
                <>
                  <PredictionIcon />
                </>
              ),
              description: "KUS/KCS Predictions",
              isNew: true,
            },
            {
              id: "Farm",
              title: "Yield Farming",
              href: "https://app.kuswap.finance/#/farms",
              icon: (
                <>
                  <FarmIcon />
                </>
              ),
              description: "Stake & Earn",
              isNew: true,
            },
          ]}
          setIsPopoverOpen={setIsPopoverOpen}
          isPopoverOpen={isPopoverOpen}
        >
          <>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} fill="var(--bg-theme)">
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M3 4h18v2H3V4zm0 7h18v2H3v-2zm0 7h18v2H3v-2z" />
            </svg>
          </>
        </AllMenuNav>
      </div>
      <div className="app-header-link-container">
        <NavLink exact activeClassName="active" to="/trade">
          Trade
        </NavLink>
      </div>
      <div className="app-header-link-container">
        <NavLink exact activeClassName="active" to="/buy_klp">
          Buy
        </NavLink>
      </div>
      <div className="app-header-link-container">
        <NavLink exact activeClassName="active" to="/dashboard">
          Dashboard
        </NavLink>
      </div>{" "}
      <div className="app-header-link-container">
        <NavLink exact activeClassName="active" to="/earn">
          Earn
        </NavLink>
      </div>
      <div className="app-header-link-container">
        <NavLink exact activeClassName="active" to="/rewards">
          Rewards
        </NavLink>
      </div>
      <div className="app-header-link-container">
        <NavLink exact activeClassName="active disabled" to="####">
          NFT
        </NavLink>
      </div>
      {/* <div className="app-header-link-container">
        <NavLink exact activeClassName="active" to="/rewards">
          Rewards
        </NavLink>
      </div> */}
      {/* <div className="app-header-link-container">
        <NavLink exact activeClassName="active" to="/referrals">
          Referrals
        </NavLink>
      </div> */}
      {/* <div className="app-header-link-container">
          <a href="https://kuswapv3.gitbook.io/documentation/" target="_blank" rel="noopener noreferrer">
            Docs
          </a>
        </div> */}{" "}
      {/* <div className="app-header-link-container">
          <a href="https://kuswapv3.gitbook.io/documentation/" target="_blank" rel="noopener noreferrer">
            Docs
          </a>
        </div> */}
      {small && (
        <div className="app-header-link-container">
          {/* eslint-disable-next-line */}
          <a href="#" onClick={openSettings}>
            Settings
          </a>
        </div>
      )}
    </div>
  );
}

function AppHeaderUser({
  openSettings,
  small,
  setWalletModalVisible,
  showNetworkSelectorModal,
  disconnectAccountAndCloseSettings,
  trackAction,
}) {
  const { chainId } = useChainId();
  const { active, account } = useWeb3React();
  const showSelector = true;

  useEffect(() => {
    if (active) {
      setWalletModalVisible(false);
    }
  }, [active, setWalletModalVisible]);

  const onNetworkSelect = useCallback(
    (option) => {
      if (option.value === chainId) {
        return;
      }
      return switchNetwork(option.value, active);
    },
    [chainId, active]
  );

  const selectorLabel = getChainName(chainId);

  if (!active) {
    return (
      <div className="app-header-user">
        {/* {showSelector && (
          <NetworkSelector
            options={networkOptions}
            label={selectorLabel}
            onSelect={onNetworkSelect}
            className="app-header-user-netowork"
            showCaret={true}
            modalLabel="Select Network"
            small={small}
            showModal={showNetworkSelectorModal}
            trackAction={trackAction}
          />
        )} */}
        <ConnectWalletButton
          onClick={() => {
            setWalletModalVisible(true);
          }}
          imgSrc={connectWalletImg}
        >
          <b>CONNECT</b>
        </ConnectWalletButton>
        {/* <AppDropdown /> */}
      </div>
    );
  }

  const accountUrl = getAccountUrl(chainId, account);

  return (
    <div className="app-header-user">
      <div className="app-header-user-link  app-header-user-netowork">
        <NavLink exact activeClassName="active" className="default-btn trade-link" to="/trade">
          Trade
        </NavLink>
      </div>
      <div className="app-header-user-address">
        <AddressDropdown
          account={account}
          small={small}
          accountUrl={accountUrl}
          disconnectAccountAndCloseSettings={disconnectAccountAndCloseSettings}
          openSettings={openSettings}
          trackAction={trackAction}
        />
      </div>
      {/* <AppDropdown /> */}
    </div>
  );
}

function FullApp() {
  const location = useLocation();
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [loggedInTracked, setLoggedInTracked] = useState(false);

  const exchangeRef = useRef();
  const { connector, library, deactivate, activate, active, account } = useWeb3React();
  const { chainId } = useChainId();
  const readerAddress = getContract(chainId, "Reader");
  const tokens = getTokens(chainId);
  const tokenAddresses = tokens.map((token) => token.address);
  const whitelistedTokens = getWhitelistedTokens(chainId);
  const whitelistedTokenAddresses = whitelistedTokens.map((token) => token.address);
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");

  useEventToast();
  const [activatingConnector, setActivatingConnector] = useState();
  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector, chainId]);
  const triedEager = useEagerConnect(setActivatingConnector);
  useInactiveListener(!triedEager || !!activatingConnector);

  const query = useRouteQuery();

  useEffect(() => {
    let referralCode = query.get(REFERRAL_CODE_QUERY_PARAMS);
    if (referralCode && referralCode.length <= 20) {
      const encodedReferralCode = encodeReferralCode(referralCode);
      if (encodeReferralCode !== ethers.constants.HashZero) {
        localStorage.setItem(REFERRAL_CODE_KEY, encodedReferralCode);
      }
    }
  }, [query]);

  const disconnectAccount = useCallback(() => {
    // only works with WalletConnect
    clearWalletConnectData();
    // force clear localStorage connection for MM/CB Wallet (Brave legacy)
    clearWalletLinkData();
    deactivate();
  }, [deactivate]);

  const disconnectAccountAndCloseSettings = () => {
    disconnectAccount();
    localStorage.removeItem(SHOULD_EAGER_CONNECT_LOCALSTORAGE_KEY);
    localStorage.removeItem(CURRENT_PROVIDER_LOCALSTORAGE_KEY);
    setIsSettingsVisible(false);
  };

  const connectInjectedWallet = getInjectedHandler(activate);
  const activateWalletConnect = () => {
    getWalletConnectHandler(activate, deactivate, setActivatingConnector)();
  };

  const userOnMobileDevice = "navigator" in window && isMobileDevice(window.navigator);

  const activateMetaMask = () => {
    if (!hasMetaMaskWalletExtension()) {
      helperToast.error(
        <div>
          MetaMask not detected.
          <br />
          <br />
          <a href="https://metamask.io" target="_blank" rel="noopener noreferrer">
            Install MetaMask
          </a>
          {userOnMobileDevice ? ", and use KMX with its built-in browser" : " to start using KMX"}.
        </div>
      );
      return false;
    }
    attemptActivateWallet("MetaMask");
  };
  // const activateCoinBase = () => {
  //   if (!hasCoinBaseWalletExtension()) {
  //     helperToast.error(
  //       <div>
  //         Coinbase Wallet not detected.
  //         <br />
  //         <br />
  //         <a href="https://www.coinbase.com/wallet" target="_blank" rel="noopener noreferrer">
  //           Install Coinbase Wallet
  //         </a>
  //         {userOnMobileDevice ? ", and use KMX with its built-in browser" : " to start using KMX"}.
  //       </div>
  //     );
  //     return false;
  //   }
  //   attemptActivateWallet("CoinBase");
  // };

  const attemptActivateWallet = (providerName) => {
    localStorage.setItem(SHOULD_EAGER_CONNECT_LOCALSTORAGE_KEY, true);
    localStorage.setItem(CURRENT_PROVIDER_LOCALSTORAGE_KEY, providerName);
    activateInjectedProvider(providerName);
    connectInjectedWallet();
  };

  const [walletModalVisible, setWalletModalVisible] = useState();
  // const [isEventModalVisible, setEventModalVisible] = useState(false);
  const connectWallet = () => setWalletModalVisible(true);

  const [isDrawerVisible, setIsDrawerVisible] = useState(undefined);
  const [isNativeSelectorModalVisible, setisNativeSelectorModalVisible] = useState(false);
  const fadeVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };
  // const slideVariants = {
  //   hidden: { x: "-100%" },
  //   visible: { x: 0 },
  // };

  const [isSettingsVisible, setIsSettingsVisible] = useState(false);
  const [savedSlippageAmount, setSavedSlippageAmount] = useLocalStorageSerializeKey(
    [chainId, SLIPPAGE_BPS_KEY],
    DEFAULT_SLIPPAGE_AMOUNT
  );
  const [slippageAmount, setSlippageAmount] = useState(0);
  const [slippageError, setSlippageError] = useState("");
  const [isPnlInLeverage, setIsPnlInLeverage] = useState(false);
  const [showPnlAfterFees, setShowPnlAfterFees] = useState(false);

  const MAX_DECIMALS = 2;

  const parseSlippageAmount = (amount) => {
    const strWithoutLeadingsZeroes = amount.replace(/^[0]+/g, "0");
    const decimals = strWithoutLeadingsZeroes.toString().split(".")[1];
    if (parseFloat(amount) > 5.0) {
      setSlippageError("Slippage should be less than 5%");
    } else if (decimals?.length > MAX_DECIMALS) {
      setSlippageError("Max slippage precision is 0.01%");
    }
    // limit the amount of decimals
    else if (!decimals || decimals?.length <= MAX_DECIMALS) {
      // replace commas with periods for other locales
      setSlippageAmount(strWithoutLeadingsZeroes.replace(/,/g, "."));
      setSlippageError("");
    }
    setSlippageAmount(amount);
  };

  const [savedIsPnlInLeverage, setSavedIsPnlInLeverage] = useLocalStorageSerializeKey(
    [chainId, IS_PNL_IN_LEVERAGE_KEY],
    false
  );

  const [savedShowPnlAfterFees, setSavedShowPnlAfterFees] = useLocalStorageSerializeKey(
    [chainId, SHOW_PNL_AFTER_FEES_KEY],
    false
  );

  const [savedShouldShowPositionLines, setSavedShouldShowPositionLines] = useLocalStorageSerializeKey(
    [chainId, SHOULD_SHOW_POSITION_LINES_KEY],
    false
  );

  const openSettings = () => {
    const slippage = parseInt(savedSlippageAmount);
    setSlippageAmount((slippage / BASIS_POINTS_DIVISOR) * 100);
    setIsPnlInLeverage(savedIsPnlInLeverage);
    setShowPnlAfterFees(savedShowPnlAfterFees);
    setIsSettingsVisible(true);
  };

  const showNetworkSelectorModal = (val) => {
    setisNativeSelectorModalVisible(val);
  };

  const saveAndCloseSettings = () => {
    if (slippageError === "") {
      const slippage = parseFloat(slippageAmount);
      if (isNaN(slippage)) {
        helperToast.error("Invalid slippage value");
        return;
      }
      if (slippage > 5) {
        helperToast.error("Slippage should be less than 5%");
        return;
      }

      const basisPoints = (slippage * BASIS_POINTS_DIVISOR) / 100;
      if (parseInt(basisPoints) !== parseFloat(basisPoints)) {
        helperToast.error("Max slippage precision is 0.01%");
        return;
      }

      setSavedIsPnlInLeverage(isPnlInLeverage);
      setSavedShowPnlAfterFees(showPnlAfterFees);
      setSavedSlippageAmount(basisPoints);
      setIsSettingsVisible(false);
    }
  };
  useEffect(() => {
    if (isDrawerVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => (document.body.style.overflow = "unset");
  }, [isDrawerVisible]);

  const [pendingTxns, setPendingTxns] = useState([]);

  useEffect(() => {
    const pendingTxnHashes = {};
    const checkPendingTxns = async () => {
      const updatedPendingTxns = [];
      for (let i = 0; i < pendingTxns.length; i++) {
        const pendingTxn = pendingTxns[i];
        // because the interval is 2 seconds, if the txn takes longer than 2 seconds there
        // is potential for the interval event que to trigger multiple success or error notifications
        if (pendingTxnHashes[pendingTxn.hash]) {
          continue;
        }
        const receipt = await library.getTransactionReceipt(pendingTxn.hash);
        pendingTxnHashes[pendingTxn.hash] = true;
        if (receipt) {
          if (receipt.status === 0) {
            const txUrl = getExplorerUrl(chainId) + "tx/" + pendingTxn.hash;
            helperToast.error(
              <div>
                Txn failed.{" "}
                <a href={txUrl} target="_blank" rel="noopener noreferrer">
                  View
                </a>
                <br />
              </div>
            );
          }
          if (receipt.status === 1 && pendingTxn.message) {
            const txUrl = getExplorerUrl(chainId) + "tx/" + pendingTxn.hash;
            helperToast.success(
              <div>
                {pendingTxn.message}{" "}
                <a href={txUrl} target="_blank" rel="noopener noreferrer">
                  View
                </a>
                <br />
              </div>
            );
          }
          continue;
        }
        updatedPendingTxns.push(pendingTxn);
      }

      if (updatedPendingTxns.length !== pendingTxns.length) {
        setPendingTxns(updatedPendingTxns);
      }
    };

    const interval = setInterval(() => {
      checkPendingTxns();
    }, 2 * 1000);
    return () => clearInterval(interval);
  }, [library, pendingTxns, chainId]);

  const vaultAddress = getContract(chainId, "Vault");
  const positionRouterAddress = getContract(chainId, "PositionRouter");

  useEffect(() => {
    const wsVaultAbi = chainId === KCC_TESTNET ? VaultV2.abi : VaultV2b.abi;
    const wsProvider = getWsProvider(active, chainId);

    if (!wsProvider) {
      return;
    }
    const wsVault = new ethers.Contract(vaultAddress, wsVaultAbi, wsProvider);
    const wsPositionRouter = new ethers.Contract(positionRouterAddress, PositionRouter.abi, wsProvider);

    const callExchangeRef = (method, ...args) => {
      if (!exchangeRef || !exchangeRef.current) {
        return;
      }

      exchangeRef.current[method](...args);
    };

    // handle the subscriptions here instead of within the Exchange component to avoid unsubscribing and re-subscribing
    // each time the Exchange components re-renders, which happens on every data update
    const onUpdatePosition = (...args) => callExchangeRef("onUpdatePosition", ...args);
    const onClosePosition = (...args) => callExchangeRef("onClosePosition", ...args);
    const onIncreasePosition = (...args) => callExchangeRef("onIncreasePosition", ...args);
    const onDecreasePosition = (...args) => callExchangeRef("onDecreasePosition", ...args);
    const onCancelIncreasePosition = (...args) => callExchangeRef("onCancelIncreasePosition", ...args);
    const onCancelDecreasePosition = (...args) => callExchangeRef("onCancelDecreasePosition", ...args);

    wsVault.on("UpdatePosition", onUpdatePosition);
    wsVault.on("ClosePosition", onClosePosition);
    wsVault.on("IncreasePosition", onIncreasePosition);
    wsVault.on("DecreasePosition", onDecreasePosition);
    wsPositionRouter.on("CancelIncreasePosition", onCancelIncreasePosition);
    wsPositionRouter.on("CancelDecreasePosition", onCancelDecreasePosition);

    return function cleanup() {
      wsVault.off("UpdatePosition", onUpdatePosition);
      wsVault.off("ClosePosition", onClosePosition);
      wsVault.off("IncreasePosition", onIncreasePosition);
      wsVault.off("DecreasePosition", onDecreasePosition);
      wsPositionRouter.off("CancelIncreasePosition", onCancelIncreasePosition);
      wsPositionRouter.off("CancelDecreasePosition", onCancelDecreasePosition);
    };
  }, [active, chainId, vaultAddress, positionRouterAddress]);

  const { data: tokenBalances } = useSWR(
    [`FullApp:getTokenBalances:${active}`, chainId, readerAddress, "getTokenBalances", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: fetcher(library, ReaderV2, [tokenAddresses]),
    }
  );
  const { data: fundingRateInfo } = useSWR([active, chainId, readerAddress, "getFundingRates"], {
    fetcher: fetcher(library, ReaderV2, [vaultAddress, nativeTokenAddress, whitelistedTokenAddresses]),
  });
  const { infoTokens } = useInfoTokens(library, chainId, active, tokenBalances, fundingRateInfo);

  // Track user wallet connect
  useEffect(() => {
    const accountChanged = hasChangedAccount(account);
    if ((!loggedInTracked || accountChanged) && infoTokens) {
      const sendTrackLoginData = async () => {
        if (account && tokenBalances) {
          const { balanceData } = getBalanceAndSupplyData(tokenBalances);

          // Format KMX token balances from BigNumber to float
          const tokenDecimals = tokens.map((token) => token.decimals);
          let klpBalances = {};
          Object.keys(balanceData).forEach((token, i) => {
            if (balanceData[token]) {
              const fieldName = `balance${formatTitleCase(token)}`;
              klpBalances[fieldName] = parseFloat(formatAmount(balanceData[token], tokenDecimals[i], 4, true));
            }
          });

          // Format user ERC20 token balances from BigNumber to float
          // const [userBalances] = getUserTokenBalances(infoTokens);

          setCurrentAccount(account);
          setLoggedInTracked(true); // Only track once
        }
      };
      sendTrackLoginData();
    }
  }, [account, chainId, tokenBalances, loggedInTracked, library, infoTokens, tokens]);

  const selectorLabel = getChainName(chainId);

  const onNetworkSelect = useCallback(
    (option) => {
      if (option.value === chainId) {
        return;
      }
      return switchNetwork(option.value, active);
    },
    [chainId, active]
  );

  const isHome = location?.pathname === "/";
  const isExchange = location?.pathname === "/trade";

  const Footer = () => {
    return (
      <>
        <div
          className="flex sticky bottom-0 z-10 justify-between items-center py-0 pr-0 pl-2 mx-0 mb-0 -mt-px min-w-0 text-black border-t border-solid border bg-theme"
          style={{ borderBottom: 0 }}
        >
          <div className="flex items-center m-0 min-w-0">
            <svg width={16} height={12} viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg" className>
              <rect y="9.667" width="1.67" height="2.5" fill="#0ECB81" className />
              <rect x="4.44446" y="5.667" width="1.66667" height="6.67" fill="#0ECB81" className />
              <rect x="8.88892" y={3} width="1.66667" height="10.83" fill="#0ECB81" className />
              <rect x="13.3333" width="1.66667" height={15} fill="#0ECB81" className />
            </svg>
            <div className="my-0 mr-0 ml-2 min-w-0 text-xs text-emerald-500">Stable connection</div>
          </div>
          <div className="flex flex-1 my-0 mr-0 ml-8 min-w-0 h-4">
            <div className="mt-1 w-px h-2 bg-zinc-600" />
            <div className="inline-block relative m-0 min-w-0" style={{ outline: "none" }}>
              <div className="inline-block relative m-0 min-w-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  className="my-0 mr-0 ml-4 w-4 min-w-0 h-4 text-base text-gray-400 hover:cursor-pointer hover:text-yellow-500"
                  style={{ fill: "rgb(132, 142, 156)" }}
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21 6h-7.79a4.001 4.001 0 00-7.42 0H3v3h2.79a4.001 4.001 0 007.42 0H21V6zm-10.21 9H3v3h7.79a4.001 4.001 0 007.42 0H21v-3h-2.79a4.001 4.001 0 00-7.42 0z"
                    fill="currentColor"
                    className
                  />
                </svg>
              </div>
            </div>
            <div className="flex overflow-auto flex-wrap flex-1 my-0 mr-0 ml-px min-w-0">
              <div className="flex flex-wrap">
                <div className="flex ml-2 cursor-pointer hover:opacity-80">
                  <div className="mr-2 text-xs text-neutral-300">BTCBUSD</div>
                  <div className="mr-2 text-xs text-emerald-500">+0.16</div>
                  <div className="mr-3 text-xs text-gray-400">23210.91</div>
                  <div className="mt-1 w-px h-2 bg-zinc-600" />
                </div>
                <div className="flex ml-2 cursor-pointer hover:opacity-80">
                  <div className="mr-2 text-xs text-neutral-300">ETHBUSD</div>
                  <div className="mr-2 text-xs text-emerald-500">+0.41</div>
                  <div className="mr-3 text-xs text-gray-400">1604.3</div>
                  <div className="mt-1 w-px h-2 bg-zinc-600" />
                </div>
                <div className="flex ml-2 cursor-pointer hover:opacity-80">
                  <div className="mr-2 text-xs text-neutral-300">DOGEBUSD</div>
                  <div className="mr-2 text-xs text-rose-500">-5.88</div>
                  <div className="mr-3 text-xs text-gray-400">0.09132</div>
                  <div className="mt-1 w-px h-2 bg-zinc-600" />
                </div>
                <div className="flex ml-2 cursor-pointer hover:opacity-80">
                  <div className="mr-2 text-xs text-neutral-300">SOLBUSD</div>
                  <div className="mr-2 text-xs text-rose-500">-1.94</div>
                  <div className="mr-3 text-xs text-gray-400">23.76</div>
                  <div className="mt-1 w-px h-2 bg-zinc-600" />
                </div>
                <div className="flex ml-2 cursor-pointer hover:opacity-80">
                  <div className="mr-2 text-xs text-neutral-300">HIGHBUSD</div>
                  <div className="mr-2 text-xs text-rose-500">-6.47</div>
                  <div className="mr-3 text-xs text-gray-400">3.197</div>
                  <div className="mt-1 w-px h-2 bg-zinc-600" />
                </div>
                <div className="flex ml-2 cursor-pointer hover:opacity-80">
                  <div className="mr-2 text-xs text-neutral-300">DYDXBUSD</div>
                  <div className="mr-2 text-xs text-emerald-500">+5.29</div>
                  <div className="mr-3 text-xs text-gray-400">3.265</div>
                  <div className="mt-1 w-px h-2 bg-zinc-600" />
                </div>
                <div className="flex ml-2 cursor-pointer hover:opacity-80">
                  <div className="mr-2 text-xs text-neutral-300">SHIBBUSD</div>
                  <div className="mr-2 text-xs text-rose-500">-2.09</div>
                  <div className="mr-3 text-xs text-gray-400">0.0000117</div>
                  <div className="mt-1 w-px h-2 bg-zinc-600" />
                </div>
                <div className="flex ml-2 cursor-pointer hover:opacity-80">
                  <div className="mr-2 text-xs text-neutral-300">MATICBUSD</div>
                  <div className="mr-2 text-xs text-rose-500">-0.57</div>
                  <div className="mr-3 text-xs text-gray-400">1.1142</div>
                  <div className="mt-1 w-px h-2 bg-zinc-600" />
                </div>
                <div className="flex ml-2 cursor-pointer hover:opacity-80">
                  <div className="mr-2 text-xs text-neutral-300">FTMBUSD</div>
                  <div className="mr-2 text-xs text-rose-500">-1.39</div>
                  <div className="mr-3 text-xs text-gray-400">0.5326</div>
                  <div className="mt-1 w-px h-2 bg-zinc-600" />
                </div>
                <div className="flex ml-2 cursor-pointer hover:opacity-80">
                  <div className="mr-2 text-xs text-neutral-300">APTBUSD</div>
                  <div className="mr-2 text-xs text-rose-500">-3.39</div>
                  <div className="mr-3 text-xs text-gray-400">16.6141</div>
                  <div className="mt-1 w-px h-2 bg-zinc-600" />
                </div>
                <div className="flex ml-2 cursor-pointer hover:opacity-80">
                  <div className="mr-2 text-xs text-neutral-300">ADABUSD</div>
                  <div className="mr-2 text-xs text-rose-500">-2.13</div>
                  <div className="mr-3 text-xs text-gray-400">0.3857</div>
                  <div className="mt-1 w-px h-2 bg-zinc-600" />
                </div>
                <div className="flex ml-2 cursor-pointer hover:opacity-80">
                  <div className="mr-2 text-xs text-neutral-300">SFPBUSD</div>
                  <div className="mr-2 text-xs text-emerald-500">+5.76</div>
                  <div className="mr-3 text-xs text-gray-400">0.602</div>
                  <div className="mt-1 w-px h-2 bg-zinc-600" />
                </div>
                <div className="flex ml-2 cursor-pointer hover:opacity-80">
                  <div className="mr-2 text-xs text-neutral-300">LTCBUSD</div>
                  <div className="mr-2 text-xs text-emerald-500">+2.85</div>
                  <div className="mr-3 text-xs text-gray-400">97.53</div>
                  <div className="mt-1 w-px h-2 bg-zinc-600" />
                </div>
                <div className="flex ml-2 cursor-pointer hover:opacity-80">
                  <div className="mr-2 text-xs text-neutral-300">HOOKBUSD</div>
                  <div className="mr-2 text-xs text-rose-500">-2.95</div>
                  <div className="mr-3 text-xs text-gray-400">2.9764</div>
                  <div className="mt-1 w-px h-2 bg-zinc-600" />
                </div>
                <div className="flex ml-2 cursor-pointer hover:opacity-80">
                  <div className="mr-2 text-xs text-neutral-300">TRXBUSD</div>
                  <div className="mr-2 text-xs text-rose-500">-0.21</div>
                  <div className="mr-3 text-xs text-gray-400">0.06234</div>
                  <div className="mt-1 w-px h-2 bg-zinc-600" />
                </div>
              </div>
            </div>
          </div>
          <div className="flex m-0 min-w-0">
            <div id="announcements_entrance" className="flex m-0 min-w-0">
              <div className="flex items-center py-0 px-4 m-0 min-w-0 h-6 text-xs border-l border-solid cursor-pointer border-zinc-800 text-neutral-300 hover:text-yellow-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="t.secondary"
                  className="my-0 mr-2 ml-0 w-4 min-w-0 h-4 text-sm"
                  style={{ color: "currentcolor", fill: "currentcolor" }}
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 3a7 7 0 00-7 7v3l-1 1v2h16v-2l-1-1v-3a7 7 0 00-7-7zm0 18a5.001 5.001 0 01-4.584-3h9.168A5.001 5.001 0 0112 21z"
                    fill="currentColor"
                    className
                    style={{ fill: "rgb(132, 142, 156)" }}
                  />
                </svg>
                <div className="m-0 min-w-0">Announcements</div>
              </div>
            </div>
            <div
              data-ot-trigger="true"
              className="flex items-center py-0 px-4 m-0 min-w-0 h-6 text-xs border-l border-solid cursor-pointer border-zinc-800 text-neutral-300 hover:text-yellow-500"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                className="my-0 mr-2 ml-0 w-4 min-w-0 h-4 text-sm"
                style={{ color: "currentcolor", fill: "currentcolor" }}
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4 16V4h16v12l-8 5-8-5zm10-6.24a2 2 0 01-1 1.732v2.506h-2v-2.506a2 2 0 113-1.732z"
                  fill="currentColor"
                  className
                  style={{ fill: "rgb(132, 142, 156)" }}
                />
              </svg>
              <span className>Cookie Preferences</span>
            </div>
            <div className="inline-block relative m-0 min-w-0" style={{ outline: "none" }}>
              <div
                data-testid="electronDownloadButton"
                className="flex items-center py-0 px-4 m-0 min-w-0 h-6 text-xs border-l border-solid cursor-pointer border-zinc-800 text-neutral-300 hover:text-yellow-500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  className="my-0 mr-2 ml-0 w-4 min-w-0 h-4 text-sm"
                  style={{ color: "currentcolor", fill: "currentcolor" }}
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.5 9V6H16v3h3V3H4.5v6h3zm-3 12H19v-6h-3v3H7.5v-3h-3v6zm6.009-13h3v4.002l2.508.006-4.008 4.008-4.01-4.008 2.51-.025V8z"
                    fill="currentColor"
                    className
                    style={{ fill: "rgb(132, 142, 156)" }}
                  />
                </svg>
                <span className>Download</span>
              </div>
            </div>
            <div className="flex items-center py-0 px-4 m-0 min-w-0 h-6 text-xs border-l border-solid cursor-pointer border-zinc-800 text-neutral-300 hover:text-yellow-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                className="my-0 mr-2 ml-0 w-4 min-w-0 h-4 text-sm"
                style={{ color: "currentcolor", fill: "currentcolor" }}
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3 3h18v14h-9l-5 5v-5H3V3zm15 3H6v2.5h12V6zm0 5.5H6V14h12v-2.5z"
                  fill="currentColor"
                  className
                  style={{ fill: "rgb(132, 142, 156)" }}
                />
              </svg>
              <span className>Online Support</span>
            </div>
          </div>
        </div>
      </>
    );
  };

  const [themeToggle, setThemeToggle] = useRecoilState(appTheme);

  const update = () => {
    const themeName = themeToggle ? "dark" : "light";
    window?.localStorage.setItem("theme", themeName);
  };
  const getClass = () => {
    return window?.localStorage.getItem("theme");
  };

  React.useEffect(() => {
    const theme = getClass();
    if (theme === "dark") {
      setThemeToggle(false);
    } else if (theme === "light") {
      setThemeToggle(true);
    } else {
      update();
    }
  }, [setThemeToggle]);

  const ToggleTheme = () => {
    return (
      <button
        type="button"
        role="switch"
        aria-checked={themeToggle}
        className={`p-0 m-0 min-w-0 h-6 font-sans text-sm text-center text-black bg-${themeToggle ? "gray-200" : "theme"
          } rounded-full appearance-none cursor-pointer`}
        style={{ direction: "ltr" }}
        onClick={() => {
          setThemeToggle(!themeToggle);
          update();
        }}
      >
        <div
          className="flex justify-center items-center my-0 mx-1 w-4 min-w-0 h-4 text-sm text-black bg-white rounded-full duration-200"
          style={{
            boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px",
            transitionProperty: "transform, border-color",
            transitionTimingFunction: "ease-in-out",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            className="m-0 w-4 min-w-0 h-4 text-xs"
            style={{
              fill: "var(--bg)",
              color: "var(--theme)",
            }}
          >
            <path
              d={
                !themeToggle
                  ? "M11.38 2.019a7.5 7.5 0 1 0 10.6 10.6C21.662 17.854 17.316 22 12.001 22 6.477 22 2 17.523 2 12c0-5.315 4.146-9.661 9.38-9.981z"
                  : "M12 18a6 6 0 1 1 0-12 6 6 0 0 1 0 12zM11 1h2v3h-2V1zm0 19h2v3h-2v-3zM3.515 4.929l1.414-1.414L7.05 5.636 5.636 7.05 3.515 4.93zM16.95 18.364l1.414-1.414 2.121 2.121-1.414 1.414-2.121-2.121zm2.121-14.85l1.414 1.415-2.121 2.121-1.414-1.414 2.121-2.121zM5.636 16.95l1.414 1.414-2.121 2.121-1.414-1.414 2.121-2.121zM23 11v2h-3v-2h3zM4 11v2H1v-2h3z"
              }
              fill="currentColor"
              className="text-center"
            />
          </svg>
        </div>
        <input
          type="checkbox"
          data-bn-type="checkbox"
          className="hidden m-0 min-w-0 text-black cursor-default"
          value={themeToggle}
        />
      </button>
    );
  };

  return (
    <>
      <div
        className={cx("App kus-fade", {
          "full-width": sidebarVisible,
          "dark-theme": getClass() === "dark",
          "light-theme": getClass() === "light",
        })}
        id={isHome ? "cta" : "kmx-trade"}
      >
        <img className="top-gradient anim-bg-kus" src="/top-bg2.svg" loading="lazy" alt="Background gradient" />
        <img className="top-gradient anim-bg-kus" src="/top-bg2.svg" loading="lazy" alt="Background gradient" />
        <img
          className="bottom-gradient bottom-gradient-mobile anim-bg-kus"
          src="/images/footer-gradient-mobile.svg"
          loading="lazy"
          alt="Background gradient"
        />
        <img className="top-gradient anim-bg-kus" src="/top-gradient2.svg" loading="lazy" alt="Background gradient" />

        <div className={cx("app-content", { "full-width": sidebarVisible })}>
          {isDrawerVisible && (
            <AnimatePresence>
              {isDrawerVisible && (
                <motion.div
                  className="app-header-backdrop"
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={fadeVariants}
                  transition={{ duration: 0.2 }}
                  onClick={() => setIsDrawerVisible(!isDrawerVisible)}
                ></motion.div>
              )}
            </AnimatePresence>
          )}
          {isNativeSelectorModalVisible && (
            <AnimatePresence>
              {isNativeSelectorModalVisible && (
                <motion.div
                  className="selector-backdrop"
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={fadeVariants}
                  transition={{ duration: 0.2 }}
                  onClick={() => setisNativeSelectorModalVisible(!isNativeSelectorModalVisible)}
                ></motion.div>
              )}
            </AnimatePresence>
          )}
          <nav>
            <div className="app-header large default-container">
              <div className="app-header-container-left">
                <Link className="app-header-link-main" to="/">
                  <img src={logoImg} className="big" alt="KMX Swaps Logo" />
                  <img src={logoSmallImg} className="small" alt="KMX Swaps Logo" />
                </Link>
              </div>
              <div className="app-header-container-right">
                <AppHeaderLinks />
                {(location?.pathname !== "/" && (
                  <>
                    <AppHeaderUser
                      disconnectAccountAndCloseSettings={disconnectAccountAndCloseSettings}
                      openSettings={openSettings}
                      setActivatingConnector={setActivatingConnector}
                      walletModalVisible={walletModalVisible}
                      setWalletModalVisible={setWalletModalVisible}
                      showNetworkSelectorModal={showNetworkSelectorModal}
                      trackAction={() => { }}
                    />
                    <div
                      style={{
                        cursor: "pointer",
                        marginLeft: "24px",
                      }}
                    >
                      <ToggleTheme />
                    </div>
                  </>
                )) || (
                    <>
                      <div className="app-header-user-link Trade-btn-mobile">
                        <NavLink exact activeClassName="active" className="default-btn trade-link" to="/trade">
                          Trade
                        </NavLink>
                      </div>
                    </>
                  )}
              </div>
            </div>
            <div className={cx("app-header small  default-container", { active: isDrawerVisible })}>
              <div
                className={cx("app-header-link-container", "app-header-top", {
                  active: isDrawerVisible,
                })}
              >
                <div className="app-header-container-left">
                  <Link className="app-header-link-main clickable" to="/">
                    <img src={logoSmallImg} className="small" alt="KMX Swaps Logo" />
                    <img src={logoImg} className="big" alt="KMX Swaps Logo" />
                  </Link>
                </div>
                <div>
                  <div className="app-header-container-right">
                    <AppHeaderLinks trackAction={() => { }} />
                    <LinkDropdown />
                    <AppHeaderUser
                      disconnectAccountAndCloseSettings={disconnectAccountAndCloseSettings}
                      openSettings={openSettings}
                      small
                      setActivatingConnector={setActivatingConnector}
                      walletModalVisible={walletModalVisible}
                      setWalletModalVisible={setWalletModalVisible}
                      showNetworkSelectorModal={showNetworkSelectorModal}
                      trackAction={() => { }}
                    />
                  </div>
                  {/* {location?.pathname !== "/" && (
                    <div className="app-header-user-link Trade-btn-mobile">
                      <NavLink exact activeClassName="active" className="default-btn trade-link" to="/trade">
                        Trade
                      </NavLink>
                    </div>
                  )} */}
                  {/* <AppDropdown isMobile /> */}
                  {/* Hamburger menu */}
                  <button className="app-header-menu-icon-block" onClick={() => setIsDrawerVisible(!isDrawerVisible)}>
                    <span />
                    <span />
                    <span />
                  </button>
                </div>
              </div>
            </div>
          </nav>
          <div
            className={cx("app-header-links-container app-header-drawer", {
              closed: !isDrawerVisible,
            })}
          >
            <MobileLinks
              openSettings={openSettings}
              clickCloseIcon={() => setIsDrawerVisible(false)}
              trackAction={() => { }}
              networkOptions={networkOptions}
              selectorLabel={selectorLabel}
              onNetworkSelect={onNetworkSelect}
              setWalletModalVisible={setWalletModalVisible}
              showNetworkSelectorModal={showNetworkSelectorModal}
              disconnectAccountAndCloseSettings={disconnectAccountAndCloseSettings}
            />
          </div>
          {/* <div className="default-container">
            <BetaBanner>
              <BannerTitle></BannerTitle>
              <BannerContent>
             
              </BannerContent>
            </BetaBanner>
          </div> */}
          <Switch>
            <Route exact path="/">
              <Homepage />
            </Route>
            <Route exact path="/trade">
              <Exchange
                ref={exchangeRef}
                savedShowPnlAfterFees={savedShowPnlAfterFees}
                savedIsPnlInLeverage={savedIsPnlInLeverage}
                setSavedIsPnlInLeverage={setSavedIsPnlInLeverage}
                savedSlippageAmount={savedSlippageAmount}
                setPendingTxns={setPendingTxns}
                pendingTxns={pendingTxns}
                savedShouldShowPositionLines={savedShouldShowPositionLines}
                setSavedShouldShowPositionLines={setSavedShouldShowPositionLines}
                connectWallet={connectWallet}
                infoTokens={infoTokens}
                trackPageWithTraits={() => { }}
                trackAction={() => { }}
                analytics={() => { }}
                sidebarVisible={sidebarVisible}
              />
            </Route>
            <Route exact path="/dashboard" >
              {/* <Redirect to="/trade" /> */}
              <Dashboard />
            </Route>
            <Route exact path="/earn">
              <Stake
                setPendingTxns={setPendingTxns}
                connectWallet={connectWallet}
                trackAction={() => { }}
                trackPageWithTraits={() => { }}
                analytics={() => { }}
                infoTokens={infoTokens}
                savedSlippageAmount={savedSlippageAmount}
              />
            </Route>
            <Route exact path="/buy_klp">
              <BuyKlp
                savedSlippageAmount={savedSlippageAmount}
                setPendingTxns={setPendingTxns}
                connectWallet={connectWallet}
                trackPageWithTraits={() => { }}
                trackAction={() => { }}
                analytics={() => { }}
              />
            </Route>
            <Route exact path="/sell_klp">
              <SellKlp
                savedSlippageAmount={savedSlippageAmount}
                setPendingTxns={setPendingTxns}
                connectWallet={connectWallet}
              />
            </Route>
            <Route exact path="/rewards">
              <Rewards
                connectWallet={connectWallet}
                trackPageWithTraits={() => { }}
                trackAction={() => { }}
                analytics={() => { }}
                infoTokens={infoTokens}
                setPendingTxns={setPendingTxns}
              />
            </Route>
            {/* <Route exact path="/referrals">
              <Referrals
                connectWallet={connectWallet}
                trackPageWithTraits={() => {}}
                trackAction={() => {}}
                analytics={() => {}}
                infoTokens={infoTokens}
                pendingTxns={pendingTxns}
                setPendingTxns={setPendingTxns}
              />
            </Route> */}
            {/*
            <Route exact path="/nft_wallet">
              <NftWallet />
            </Route>
            */}
            <Route exact path="/actions/:account">
              <Actions trackAction={() => { }} />
            </Route>
            <Route exact path="/orders_overview">
              <OrdersOverview />
            </Route>
            <Route exact path="/positions_overview">
              <PositionsOverview />
            </Route>
            <Route exact path="/actions">
              <Actions trackAction={() => { }} />
            </Route>
            {/*
            <Route exact path="/begin_account_transfer">
              <BeginAccountTransfer setPendingTxns={setPendingTxns} />
            </Route>
            <Route exact path="/complete_account_transfer/:sender/:receiver">
              <CompleteAccountTransfer setPendingTxns={setPendingTxns} />
            </Route>
            <Route exact path="/debug">
              <Debug />
            </Route>
            <Route exact path="/referral-terms">
              <ReferralTerms />
            </Route> */}
            <Route path="*">
              <PageNotFound />
            </Route>
          </Switch>
        </div>
        {/* <Sidebar sidebarVisible={sidebarVisible} setSidebarVisible={setSidebarVisible} /> */}

        {!isHome && <V1Footer />}
      </div>
      <ToastContainer
        limit={3}
        transition={Zoom}
        position="bottom-right"
        autoClose={7000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick={false}
        draggable={false}
        pauseOnHover
      />
      {/* <EventModal
        isModalVisible={isEventModalVisible}
        setEventModalVisible={setEventModalVisible}
        eventKey={"referrals-comp"}
        twitterButtonText={"Share your code for a chance to win"}
        twitterText={` @KMX IYKYK\n\nUse my code for a discount: [insert code]`}
      />
      <EventToastContainer /> */}

      <Modal
        className="Connect-wallet-modal"
        isVisible={walletModalVisible}
        setIsVisible={setWalletModalVisible}
        label="Connect Wallet"
      >
        <WalletConnectModal activateMetaMask={activateMetaMask} activateWalletConnect={activateWalletConnect} />

        {/* <button
          className="Wallet-btn MetaMask-btn"
          onClick={() => {
            activateMetaMask();
          }}
        >
          <img src={metamaskImg} alt="MetaMask" />
          <div>MetaMask</div>
        </button>

        <button
          className="Wallet-btn WalletConnect-btn"
          onClick={() => {
            activateWalletConnect();
          }}
        >
          <img src={walletConnectImg} alt="WalletConnect" />
          <div>WalletConnect</div>
        </button> */}
      </Modal>
      <Modal
        className="App-settings"
        isVisible={isSettingsVisible}
        setIsVisible={setIsSettingsVisible}
        label="Settings"
      >
        <div className="App-settings-row">
          <div>Allowed Slippage</div>
          <div className="App-slippage-tolerance-input-container">
            <input
              type="number"
              className="App-slippage-tolerance-input"
              step="0.01"
              min="0"
              value={slippageAmount}
              onChange={(e) => parseSlippageAmount(e.target.value)}
            />
            <div className="App-slippage-tolerance-input-percent">%</div>
          </div>
          {slippageError !== "" && <div className="App-slippage-tolerance-error">{slippageError}</div>}
        </div>
        <div className="Exchange-settings-row">
          <Checkbox isChecked={showPnlAfterFees} setIsChecked={setShowPnlAfterFees}>
            Display PnL after fees
          </Checkbox>
        </div>
        <div className="Exchange-settings-row">
          <Checkbox isChecked={isPnlInLeverage} setIsChecked={setIsPnlInLeverage}>
            Include PnL in leverage display
          </Checkbox>
        </div>
        <button
          className="App-cta Exchange-swap-button"
          onClick={() => {
            saveAndCloseSettings();
          }}
        >
          Save
        </button>
      </Modal>
      {/* {isExchange && <Footer />} */}
    </>
  );
}

function PreviewApp() {
  const [isDrawerVisible, setIsDrawerVisible] = useState(undefined);
  const fadeVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };
  const slideVariants = {
    hidden: { x: "-100%" },
    visible: { x: 0 },
  };

  return (
    <>
      <div className="App">
        <div className="App-background-side-1"></div>
        <div className="App-background-side-2"></div>
        <div className="App-background"></div>
        <div className="App-background-ball-1"></div>
        <div className="App-background-ball-2"></div>
        <div className="App-highlight"></div>
        <div className={cx("app-content", { "full-width": isDrawerVisible })}>
          {isDrawerVisible && (
            <AnimatePresence>
              {isDrawerVisible && (
                <motion.div
                  className="app-header-backdrop"
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={fadeVariants}
                  transition={{ duration: 0.2 }}
                  onClick={() => setIsDrawerVisible(!isDrawerVisible)}
                ></motion.div>
              )}
            </AnimatePresence>
          )}
          <header>
            <div className="app-header large preview">
              <div className="app-header-container-left">
                <NavLink exact activeClassName="active" className="app-header-link-main" to="/">
                  <img src={logoImg} alt="KMX Swaps Logo" />
                  KMX
                </NavLink>
              </div>
              <div className="app-header-container-right">
                <AppHeaderLinks />
              </div>
            </div>
            <div className={cx("app-header", "small", { active: isDrawerVisible })}>
              <div
                className={cx("app-header-link-container", "app-header-top", {
                  active: isDrawerVisible,
                })}
              >
                <div className="app-header-container-left">
                  <div className="app-header-link-main">
                    <img src={logoImg} alt="KMX Swaps Logo" />
                  </div>
                </div>
                <div className="app-header-container-right">
                  <div onClick={() => setIsDrawerVisible(!isDrawerVisible)}>
                    {!isDrawerVisible && <RiMenuLine className="app-header-menu-icon" />}
                    {isDrawerVisible && <FaTimes className="app-header-menu-icon" />}
                  </div>
                </div>
              </div>
              <AnimatePresence>
                {isDrawerVisible && (
                  <motion.div
                    onClick={() => setIsDrawerVisible(false)}
                    className="app-header-links-container app-header-drawer"
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    variants={slideVariants}
                    transition={{ duration: 0.2 }}
                  >
                    <AppHeaderLinks small />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </header>
        </div>
      </div>
    </>
  );
}

function App() {
  const [hasConsented, setConsented] = useState(false);

  useEffect(() => {
    const consentAcknowledged = localStorage.getItem("consentAcknowledged") === "true";
    setConsented(consentAcknowledged);
  }, []);

  if (inPreviewMode()) {
    return (
      <Web3ReactProvider getLibrary={getLibrary}>
        <LeaderboardProvider>
          <ThemeProvider>
            <PreviewApp />
          </ThemeProvider>
        </LeaderboardProvider>
      </Web3ReactProvider>
    );
  }

  return (
    <SWRConfig value={{ refreshInterval: 5000 }}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <LeaderboardProvider>
          <ThemeProvider>
            <FullApp />
          </ThemeProvider>
          {/* <ConsentModal hasConsented={hasConsented} setConsented={setConsented} /> */}
        </LeaderboardProvider>
      </Web3ReactProvider>
    </SWRConfig>
  );
}

export default App;
