import React from 'react'
import CoinGecko from 'coingecko-api'


const QuickToken = () => {

    const CoinGeckoClient = new CoinGecko()


    const [data, setData] = React.useState<[]>([])

    React.useEffect(() => {
        try {
            const fetchData = async () => {
                try {
                    const response = await CoinGeckoClient.simple.price({
                        ids: ['kucoin-shares', 'bitcoin', 'ethereum', 'binancecoin'],
                        vs_currencies: ['usd']
                    })
                    setData(response.data)
                } catch (error) {
                    console.error("Error fetching data:", error)
                    setData([])
                }
            }
            fetchData()
        } catch (error) {
            console.error("Error initializing API client:", error)
        }

    }, [])


    return (<>

        <svg width={717} height={45} viewBox="0 0 717 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="path-1-outside-1_24_16973" maskUnits="userSpaceOnUse" x={4} y={3} width={150} height={38} fill="black">
                <rect fill="white" x={4} y={3} width={150} height={38} />
                <path d="M135 4H23C13.0589 4 5 12.0589 5 22C5 31.9411 13.0589 40 23 40H135C144.941 40 153 31.9411 153 22C153 12.0589 144.941 4 135 4Z" />
            </mask>
            <path d="M135 4H23C13.0589 4 5 12.0589 5 22C5 31.9411 13.0589 40 23 40H135C144.941 40 153 31.9411 153 22C153 12.0589 144.941 4 135 4Z" fill="#1E1E24" />
            <path d="M135 4H23C13.0589 4 5 12.0589 5 22C5 31.9411 13.0589 40 23 40H135C144.941 40 153 31.9411 153 22C153 12.0589 144.941 4 135 4Z" stroke="#00FFCB" strokeWidth={2} mask="url(#path-1-outside-1_24_16973)" />
            <path d="M24 25C25.6569 25 27 23.6569 27 22C27 20.3431 25.6569 19 24 19C22.3431 19 21 20.3431 21 22C21 23.6569 22.3431 25 24 25Z" fill="#2DBD96" />
            <text fill="#F5F5FF" xmlSpace="preserve" style={{ whiteSpace: 'pre' }} fontFamily="Inter" fontSize={14} letterSpacing="0em"><tspan x={37} y="26.6818">KCS</tspan></text>
            <text fill="#F5F5FF" xmlSpace="preserve" style={{ whiteSpace: 'pre' }} fontFamily="Inter" fontSize={14} fontWeight="bold" letterSpacing="0em"><tspan x={97} y="26.5909">{data['kucoin-shares']?.usd ? `$${data['kucoin-shares'].usd}` : "$8.21"}</tspan></text>
            <mask id="path-5-outside-2_24_16973" maskUnits="userSpaceOnUse" x={168} y={3} width={179} height={38} fill="black">
                <rect fill="white" x={168} y={3} width={179} height={38} />
                <path d="M322.913 4H192.087C179.336 4 169 12.0589 169 22C169 31.9411 179.336 40 192.087 40H322.913C335.664 40 346 31.9411 346 22C346 12.0589 335.664 4 322.913 4Z" />
            </mask>
            <path d="M322.913 4H192.087C179.336 4 169 12.0589 169 22C169 31.9411 179.336 40 192.087 40H322.913C335.664 40 346 31.9411 346 22C346 12.0589 335.664 4 322.913 4Z" fill="#1E1E24" />
            <path d="M322.913 4H192.087C179.336 4 169 12.0589 169 22C169 31.9411 179.336 40 192.087 40H322.913C335.664 40 346 31.9411 346 22C346 12.0589 335.664 4 322.913 4Z" stroke="#00FFCB" strokeWidth={2} mask="url(#path-5-outside-2_24_16973)" />
            <path d="M188 25C189.657 25 191 23.6569 191 22C191 20.3431 189.657 19 188 19C186.343 19 185 20.3431 185 22C185 23.6569 186.343 25 188 25Z" fill="#F2A900" />
            <text fill="#F5F5FF" xmlSpace="preserve" style={{ whiteSpace: 'pre' }} fontFamily="Inter" fontSize={14} letterSpacing="0em"><tspan x={201} y="26.6818">BTC</tspan></text>
            <text fill="#F5F5FF" xmlSpace="preserve" style={{ whiteSpace: 'pre' }} fontFamily="Inter" fontSize={14} fontWeight="bold" letterSpacing="0em"><tspan x={270} y="26.5909">{data['bitcoin']?.usd ? `$${data['bitcoin'].usd}` : "$23100"}</tspan></text>
            <mask id="path-9-outside-3_24_16973" maskUnits="userSpaceOnUse" x={361} y={3} width={170} height={38} fill="black">
                <rect fill="white" x={361} y={3} width={170} height={38} />
                <path d="M512 4H380C370.059 4 362 12.0589 362 22C362 31.9411 370.059 40 380 40H512C521.941 40 530 31.9411 530 22C530 12.0589 521.941 4 512 4Z" />
            </mask>
            <path d="M512 4H380C370.059 4 362 12.0589 362 22C362 31.9411 370.059 40 380 40H512C521.941 40 530 31.9411 530 22C530 12.0589 521.941 4 512 4Z" fill="#1E1E24" />
            <path d="M512 4H380C370.059 4 362 12.0589 362 22C362 31.9411 370.059 40 380 40H512C521.941 40 530 31.9411 530 22C530 12.0589 521.941 4 512 4Z" stroke="#00FFCB" strokeWidth={2} mask="url(#path-9-outside-3_24_16973)" />
            <path d="M381 25C382.657 25 384 23.6569 384 22C384 20.3431 382.657 19 381 19C379.343 19 378 20.3431 378 22C378 23.6569 379.343 25 381 25Z" fill="#ECF0F1" />
            <text fill="#F5F5FF" xmlSpace="preserve" style={{ whiteSpace: 'pre' }} fontFamily="Inter" fontSize={14} letterSpacing="0em"><tspan x={394} y="26.6818">ETH</tspan></text>
            <text fill="#F5F5FF" xmlSpace="preserve" style={{ whiteSpace: 'pre' }} fontFamily="Inter" fontSize={14} fontWeight="bold" letterSpacing="0em"><tspan x={446} y="26.5909">{data['ethereum']?.usd ? `$${data['ethereum'].usd}` : "$1580.14"}</tspan></text>
            <mask id="path-13-outside-4_24_16973" maskUnits="userSpaceOnUse" x={545} y={3} width={167} height={38} fill="black">
                <rect fill="white" x={545} y={3} width={167} height={38} />
                <path d="M688.669 4H568.331C555.998 4 546 12.0589 546 22C546 31.9411 555.998 40 568.331 40H688.669C701.002 40 711 31.9411 711 22C711 12.0589 701.002 4 688.669 4Z" />
            </mask>
            <path d="M688.669 4H568.331C555.998 4 546 12.0589 546 22C546 31.9411 555.998 40 568.331 40H688.669C701.002 40 711 31.9411 711 22C711 12.0589 701.002 4 688.669 4Z" fill="#1E1E24" />
            <path d="M688.669 4H568.331C555.998 4 546 12.0589 546 22C546 31.9411 555.998 40 568.331 40H688.669C701.002 40 711 31.9411 711 22C711 12.0589 701.002 4 688.669 4Z" stroke="#00FFCB" strokeWidth={2} mask="url(#path-13-outside-4_24_16973)" />
            <path d="M565 25C566.657 25 568 23.6569 568 22C568 20.3431 566.657 19 565 19C563.343 19 562 20.3431 562 22C562 23.6569 563.343 25 565 25Z" fill="#FCD535" />
            <text fill="#F5F5FF" xmlSpace="preserve" style={{ whiteSpace: 'pre' }} fontFamily="Inter" fontSize={14} letterSpacing="0em"><tspan x={578} y="26.6818">BNB</tspan></text>
            <text fill="#F5F5FF" xmlSpace="preserve" style={{ whiteSpace: 'pre' }} fontFamily="Inter" fontSize={14} fontWeight="bold" letterSpacing="0em"><tspan x={631} y="26.5909">{data['binancecoin']?.usd ? `$${data['binancecoin'].usd}` : "$307.25"}</tspan></text>
        </svg>



    </>)
}

export default QuickToken

