import React from "react";

import cx from "classnames";

import "./TimeTab.css";

export default function TimeTab(props) {
  const { options, option, type = "block", className, optionLabels, icons, newItem, onClick } = props;

  return (
    <div className={cx("TimeTab", type, className)}>
      {options.map((opt) => {
        const label = optionLabels && optionLabels[opt] ? optionLabels[opt] : opt;
        return (
          <div
            className={cx("TimeTab-option flex-col", "muted", {
              active: opt === option,
              "new-item": opt === newItem,
            })}
            onClick={() => onClick(opt)}
            key={opt}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none" width="17px">
              <path d="M16 9v1.2L12 15l-4-4.8V9h8z" fill="currentColor" />
            </svg>
            <span>{label}</span>
          </div>
        );
      })}
    </div>
  );
}
