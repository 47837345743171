import cx from "classnames";
import { SwitchContainer, ToggleSwitch, SwitchThumb, SwitchText } from "./ChartToggle.styles.js";

export const ChartToggle = (props) => {
  const { selectedChart, setSelectedChart } = props;

  const handleSwitch = ({ text }) => {
    setSelectedChart(text);
  };

  return (
    <SwitchContainer>
      <div
        style={{
          margin: "0",
          minWidth: "0",
        }}
      >
        <div
          style={{
            boxSizing: "border-box",
            margin: "0px",
            minWidth: "0px",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "20px",
            display: "inline-block",
            cursor: "pointer",
          }}
          onClick={() => {
            handleSwitch({
              text: "lightweight",
            });
          }}
        >
          <SwitchText
            className={cx({
              selected: selectedChart === "lightweight",
            })}
          >
            Original
          </SwitchText>
        </div>
        <div
          style={{
            boxSizing: "border-box",
            margin: "0px",
            minWidth: "0px",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "20px",
            display: "inline-block",
            cursor: "pointer",
            paddingLeft: 16,
          }}
          onClick={() => {
            handleSwitch({
              text: "advanced",
            });
          }}
        >
          <SwitchText
            className={cx({
              selected: selectedChart === "advanced",
            })}
          >
            Trading View
          </SwitchText>
        </div>
      </div>

      {/* <ToggleSwitch
        onClick={handleSwitch}
        className={cx({
          selected: selectedChart === "advanced",
        })}
      >
        <SwitchThumb
          className={cx({
            selected: selectedChart === "advanced",
          })}
        />
      </ToggleSwitch> */}
    </SwitchContainer>
  );
};
