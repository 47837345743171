import React from "react";
import cx from "classnames";
import { formatAmount, USD_DECIMALS, getTokenInfo, CHART_PERIODS } from "../../../Helpers";
import ChartTokenSelector from "../ChartTokenSelector";
import Tab from "../../Tab/Tab";
import { useRecoilValue } from "recoil";
import { availableLiquidityState, leverageOptionState } from "../../../state/atoms";

export const ExchangeHeader = (props) => {
  const {
    priceData,
    currentAveragePrice,
    setChartToken,
    setToTokenAddress,
    swapOption,
    chainId,
    chartToken,
    trackAction,
    period,
    setPeriod,
    infoTokens,
    selectedChart,
    setSelectedChart,
  } = props;

  const leverageOption = useRecoilValue(leverageOptionState);
  const availableLiquidity = useRecoilValue(availableLiquidityState);

  let high;
  let low;
  let deltaPrice;
  let delta;
  let deltaPercentage;
  let deltaPercentageStr;

  const now = parseInt(Date.now() / 1000);
  const timeThreshold = now - 24 * 60 * 60;

  if (priceData) {
    let last = priceData.length - 1;
    const price = priceData[last];

    if (!low) {
      low = price?.low;
    }
    if (!high) {
      high = price?.high;
    }

    if (price?.high > high) {
      high = price?.high;
    }
    if (price?.low < low) {
      low = price?.low;
    }

    deltaPrice = price?.close;

    //!/ og
    // for (let i = priceData.length - 1; i > 0; i--) {
    //   const price = priceData[i];
    //   if (price.time < timeThreshold) {
    //     break;
    //   }
    //   if (!low) {
    //     low = price.low;
    //   }
    //   if (!high) {
    //     high = price.high;
    //   }

    //   if (price.high > high) {
    //     high = price.high;
    //   }
    //   if (price.low < low) {
    //     low = price.low;
    //   }

    //   deltaPrice = price.open;
    // }
  }

  if (deltaPrice && currentAveragePrice) {
    const average = parseFloat(formatAmount(currentAveragePrice, USD_DECIMALS, 2));
    delta = average - deltaPrice;
    deltaPercentage = (delta * 100) / average;
    if (deltaPercentage > 0) {
      deltaPercentageStr = `+${deltaPercentage.toFixed(2)}%`;
    } else {
      deltaPercentageStr = `${deltaPercentage.toFixed(2)}%`;
    }
    if (deltaPercentage === 0) {
      deltaPercentageStr = "0.00";
    }
  }

  const onSelectToken = (token) => {
    const tmp = getTokenInfo(infoTokens, token.address);
    setChartToken(tmp);
    setToTokenAddress(swapOption, token.address);
  };

  return (
    <>
      <div className="py-2.5 m-0 w-full min-w-0 h-full text-sm text-gray-200">
        <div
          className="flex relative flex-wrap justify-between items-center m-0 w-full min-w-0 h-full text-gray-200"
          style={{
            padding: "0px 16px",
          }}
        >
          <div className="flex flex-1 m-0 min-w-0">
            <div className="flex">
              <div className="flex flex-row flex-none justify-between items-center mr-0">
                <div className="flex flex-1 items-center">
                  <div className="flex flex-col justify-around pr-6 m-0 min-w-0 h-auto border-r-0 border-solid border-zinc-800 md:mr-6 md:ml-1 md:h-12 md:border-r">
                    <div className="flex flex-nowrap m-0 min-w-0 h-6 md:h-8 md:flex-wrap">
                      <div className="my-0 mr-1 ml-0 min-w-0">
                        <ChartTokenSelector
                          chainId={chainId}
                          selectedToken={chartToken}
                          swapOption={swapOption}
                          infoTokens={infoTokens}
                          onSelectToken={onSelectToken}
                          className="chart-token-selector"
                          trackAction={trackAction}
                        />
                      </div>
                      <span
                        className="inline-block px-1 m-0 min-w-0 h-4 text-xs font-normal leading-4 text-theme rounded-sm bg-stone-theme"
                        style={{
                          cursor: "inherit",
                          width: 41,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {leverageOption || 2}x
                      </span>
                    </div>
                    <div className="flex m-0 min-w-0 text-xs leading-4">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="my-0 mr-1 ml-0 w-4 min-w-0 h-4 text-base text-gray-400"
                      >
                        <g clipPath="url(#clip0_29_18092)">
                          <mask
                            id="mask0_29_18092"
                            style={{ maskType: "alpha" }}
                            maskUnits="userSpaceOnUse"
                            x={0}
                            y={0}
                            width={24}
                            height={24}
                          >
                            <path
                              d="M12 0C18.6274 0 24 5.36465 24 11.9823C24 18.5999 18.6274 23.9646 12 23.9646C5.37258 23.9646 0 18.5999 0 11.9823C0 5.36465 5.37258 0 12 0Z"
                              fill="currentColor"
                            />
                          </mask>
                          <g mask="url(#mask0_29_18092)">
                            <path
                              d="M12.5053 21.6533C16.576 21.6533 19.8759 18.4649 19.8759 14.5317C19.8759 10.5986 16.576 7.41016 12.5053 7.41016C8.43468 7.41016 5.13477 10.5986 5.13477 14.5317C5.13477 18.4649 8.43468 21.6533 12.5053 21.6533Z"
                              fill="currentColor"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M13.7349 7.44068C12.3393 6.39872 10.0973 6.04851 9.76986 4.04785C9.15295 4.62645 8.7944 5.32253 8.91764 6.23675C5.45591 6.60814 2.97116 8.45559 1.82715 11.2647C2.61983 10.849 3.47778 10.5669 4.36535 10.43C2.21245 12.8523 1.80414 16.44 3.77144 20.0962C3.88013 19.2189 4.17775 18.3744 4.64449 17.6191C5.35272 20.5858 7.86543 22.6937 10.5232 23.3139C6.79291 21.5968 5.63929 18.8369 7.03938 16.5611C7.22798 15.2244 7.81966 14.1321 8.8842 13.3194C8.02405 13.1381 7.13036 13.1839 6.29404 13.4521C7.65233 12.3303 9.40188 11.7728 11.1715 11.898C10.657 11.3945 10.0291 11.0171 9.33858 10.7963C13.1448 10.3687 15.9331 13.4875 18.1951 11.6677C15.4223 10.752 15.3348 8.63067 13.7349 7.44068Z"
                              fill="currentColor"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M4.30639 9.10816C3.31184 9.60846 2.45166 10.3432 1.79883 11.2501C2.58368 10.8274 3.43326 10.5403 4.31226 10.4007C5.14625 9.45909 6.17892 8.7178 7.33443 8.23145C6.01114 8.58498 4.90846 9.12448 3.99468 9.8197L4.30639 9.10816Z"
                              fill="currentColor"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M13.3721 8.65212C12.8746 8.49103 12.3369 8.90146 11.9258 8.81613C12.2929 8.95953 12.7063 9.53398 13.1435 9.61488C13.5643 9.69363 13.8703 9.50384 14.2859 9.61488C13.9359 9.43693 13.8703 8.81392 13.3721 8.65212Z"
                              fill="currentColor"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M20.3516 7.54633C20.3768 8.52906 20.2353 9.37697 19.8819 10.0603C18.7589 5.7288 13.8547 3.98702 14.231 0.241211C11.7418 1.47961 10.5906 3.9047 11.9604 6.44637C12.553 6.753 13.1664 7.03857 13.6687 7.41378C15.2651 8.60189 15.4326 10.7531 18.1944 11.6673C16.3675 15.5712 9.70533 12.0833 6.9858 16.5197C5.58861 18.794 6.56943 21.9439 10.4743 23.3083C14.1517 24.1679 18.6151 22.7255 20.9857 19.8386C19.8252 20.1159 18.6136 20.1119 17.455 19.8269C22.6666 17.7909 23.7171 11.4407 20.3516 7.54633Z"
                              fill="currentColor"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M6.70868 16.4881C5.277 18.7502 6.28204 21.8833 10.2833 23.2404C7.34937 21.9035 6.81796 19.4726 8.00217 17.5953C10.1862 14.1398 16.7167 17.1112 18.3959 13.6282C17.9089 13.4751 17.4471 13.257 17.0242 12.9805C14.0101 14.4893 9.06871 12.7486 6.70868 16.4881Z"
                              fill="currentColor"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M11.9443 6.44184C12.6128 6.77679 12.6699 6.80128 13.2002 7.10543C12.0972 4.60244 12.7429 2.53352 14.2033 0.254883C11.7326 1.49254 10.5848 3.90167 11.9443 6.44184Z"
                              fill="currentColor"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M19.8714 10.0471C19.2639 7.68786 17.8044 6.38628 16.4091 4.83789C18.6752 7.73533 19.6846 10.8456 18.6623 14.3046C19.3257 14.1075 19.929 13.7496 20.4179 13.2632C20.5033 16.6092 18.129 19.5284 13.894 20.4758C15.2444 20.4649 16.433 20.2315 17.4597 19.826C22.6362 17.7877 23.6791 11.4305 20.3378 7.53181C20.3628 8.51415 20.2223 9.36305 19.8714 10.0471Z"
                              fill="currentColor"
                            />
                          </g>
                        </g>
                        <defs>
                          <clipPath id="clip0_29_18092">
                            <rect width={24} height={24} fill="white" />
                          </clipPath>
                        </defs>
                      </svg>

                      <a
                        data-bn-type="link"
                        href="/buy_klp"
                        disabled
                        target="_blank"
                        className="m-0 min-w-0 text-gray-400 cursor-pointer"
                        style={{ textDecoration: "underline" }}
                        rel="noreferrer"
                      >
                        Buy KLP with {chartToken.name}
                      </a>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col ml-4">
                  <div className="flex items-center text-base text-emerald-500" style={{ color: "rgb(14, 203, 129)" }}>
                    {deltaPrice && deltaPrice}
                  </div>
                  <div className="text-xs">${deltaPrice && deltaPrice}</div>
                </div>
              </div>
            </div>
            <div
              className="flex overflow-hidden relative flex-1 justify-between items-center min-w-0"
              style={{ flexFlow: "row nowrap" }}
            >
              <div
                className="flex overflow-hidden relative items-center mr-2 ml-8 xs-hidden"
                style={{ flexFlow: "row nowrap" }}
              >
                <div className="whitespace-nowrap" style={{ overflow: "auto hidden" }}>
                  <div style={{ gridArea: "A / A / A / A" }} className="inline-block pr-8">
                    <div className="mr-0 mb-px text-xs font-normal leading-4 text-left text-gray-400 border-b border-transparent border-solid truncate">
                      24h Change
                    </div>
                    {/* <div className="text-xs">
                      <span style={{ color: "rgb(14, 203, 129)", display: "flex" }} className="flex text-emerald-500">
                        <div style={{ direction: "ltr" }}></div>
                        &nbsp;
                        <div style={{ direction: "ltr" }}>{deltaPercentageStr && deltaPercentageStr}</div>
                      </span>
                    </div> */}
                    <div className={cx({ positive: deltaPercentage > 0, negative: deltaPercentage < 0 })}>
                      {!deltaPercentageStr && "-"}
                      {deltaPercentageStr && deltaPercentageStr}
                    </div>
                  </div>
                  <div style={{ gridArea: "B / B / B / B" }} className="inline-block pr-8 sm-hidden">
                    <div className="mr-0 mb-px text-xs font-normal leading-4 text-left text-gray-400 border-b border-transparent border-solid truncate">
                      24h High
                    </div>
                    <div className="text-xs"> {high && high.toFixed(2)}</div>
                  </div>
                  <div style={{ gridArea: "C / C / C / C" }} className="inline-block pr-8 sm-hidden">
                    <div className="mr-0 mb-px text-xs font-normal leading-4 text-left text-gray-400 border-b border-transparent border-solid truncate">
                      24h Low
                    </div>
                    <div className="text-xs">{low && low.toFixed(2)}</div>
                  </div>
                  <div style={{ gridArea: "D / D / D / D" }} className="inline-block pr-8 sm-hidden">
                    <div className="mr-0 mb-px text-xs font-normal leading-4 text-left text-gray-400 border-b border-transparent border-solid truncate">
                      Available Liquidity
                    </div>
                    <div className="text-xs">{!!availableLiquidity ? availableLiquidity : 0}</div>
                  </div>
                  {/* <div style={{ gridArea: "E / E / E / E" }} className="inline-block pr-8">
                    <div className="mr-0 mb-px text-xs font-normal leading-4 text-left text-gray-400 border-b border-transparent border-solid truncate">
                      24h Volume(USDT)
                    </div>
                    <div className="text-xs">5,972,022,438.17</div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center sm-hidden">
            <div className="flex m-0 min-w-0 text-xs text-gray-400 cursor-pointer">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="16px">
                <g clipPath="url(#a)">
                  <path
                    fill="var(--text-gray-icon)"
                    d="M13 8v8c0 .7956-.3161 1.5587-.8787 2.1213C11.5587 18.6839 10.7956 19 10 19H7.83c-.23591.6675-.7002 1.2301-1.31081 1.5884-.61061.3582-1.32821.4891-2.02598.3694-.69776-.1197-1.33075-.4822-1.78708-1.0234-.45633-.5413-.70662-1.2264-.70662-1.9344s.25029-1.3931.70662-1.9344c.45633-.5412 1.08932-.9037 1.78708-1.0234.69777-.1197 1.41537.0112 2.02598.3694.61061.3583 1.0749.9209 1.31081 1.5884H10c.2652 0 .5196-.1054.7071-.2929S11 16.2652 11 16V8c0-.79565.3161-1.55871.8787-2.12132C12.4413 5.31607 13.2044 5 14 5h3V2l5 4-5 4V7h-3c-.2652 0-.5196.10536-.7071.29289C13.1054 7.48043 13 7.73478 13 8Z"
                  />
                </g>
                <defs>
                  <clipPath id="a">
                    <path fill="#fff" d="M0 0h24v24H0z" />
                  </clipPath>
                </defs>
              </svg>

              <a href="https://medium.com/@kuswap" target="_blank" rel="noreferrer">
                <span style={{ marginLeft: 4 }} className="ml-1">
                  Testnet Tutorial
                </span>
              </a>
            </div>
            {/* <div className="flex my-0 mr-0 ml-4 min-w-0 text-xs text-gray-400 cursor-pointer">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="16px">
                <g clipPath="url(#a)">
                  <path
                    fill="var(--text-gray-icon)"
                    d="M20 22H6.5c-.92826 0-1.8185-.3687-2.47487-1.0251C3.36875 20.3185 3 19.4283 3 18.5V5c0-.79565.31607-1.55871.87868-2.12132C4.44129 2.31607 5.20435 2 6 2h14c.2652 0 .5196.10536.7071.29289C20.8946 2.48043 21 2.73478 21 3v18c0 .2652-.1054.5196-.2929.7071S20.2652 22 20 22Zm-1-2v-3H6.5c-.39782 0-.77936.158-1.06066.4393C5.15804 17.7206 5 18.1022 5 18.5c0 .3978.15804.7794.43934 1.0607S6.10218 20 6.5 20H19Z"
                  />
                </g>
                <defs>
                  <clipPath id="a">
                    <path fill="#fff" d="M0 0h24v24H0z" />
                  </clipPath>
                </defs>
              </svg>
              <a href="https://docs.kuswap.finance" target="_blank" rel="noreferrer">
                <span style={{ marginLeft: 4 }} className="ml-1">
                  KuSwap Docs
                </span>
              </a>
            </div> */}
          </div>
        </div>
      </div>

      {/* <div className="exchange-chart-top App-box App-box-border">
        <div className="exchange-chart-top-inner">
          <div>
            <div className="exchange-chart-title">
              <ChartTokenSelector
                chainId={chainId}
                selectedToken={chartToken}
                swapOption={swapOption}
                infoTokens={infoTokens}
                onSelectToken={onSelectToken}
                className="chart-token-selector"
                trackAction={trackAction}
              />
            </div>
          </div>
          <div>
            <div className="exchange-chart-main-price">
              {chartToken.maxPrice && formatAmount(chartToken.maxPrice, USD_DECIMALS, 2)}
            </div>
            <div className="exchange-chart-info-label">
              ${chartToken.minPrice && formatAmount(chartToken.minPrice, USD_DECIMALS, 2)}
            </div>
          </div>
          <div>
            <div className={cx({ positive: deltaPercentage > 0, negative: deltaPercentage < 0 })}>
              {!deltaPercentageStr && "-"}
              {deltaPercentageStr && deltaPercentageStr}
            </div>
            <div className="exchange-chart-info-label">24h Change</div>
          </div>
          <div className="exchange-chart-additional-info">
            <div>
              {!high && "-"}
              {high && high.toFixed(2)}
            </div>
            <div className="exchange-chart-info-label">24h High</div>
          </div>
          <div className="exchange-chart-additional-info">
            <div>
              {!low && "-"}
              {low && low.toFixed(2)}
            </div>
            <div className="exchange-chart-info-label">24h Low</div>
          </div>
        </div>
      </div> */}
    </>
  );
};
